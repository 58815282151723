import React from 'react';
import { Container, TextLocalized, ContainerFlex, Button, Card } from '../components/styled/Styled';
import img_home from '../img/background-home.jpg';

import partner1 from '../img/partner1.png';
import partner2 from '../img/partner2.png';
import partner3 from '../img/partner3.png';
import partner4 from '../img/partner4.png';

import home2 from '../img/home_two.jpg';

import { useNavigate } from 'react-router-dom';

const HomeView = () => {
    let history = useNavigate();

    return (
        <Container minheight="calc(100vh - 80px)" color="color-white">
            <Container width="12" image={img_home}>
                <ContainerFlex width="12" pv="200" justify="center" fontWeight="bold" fontSize="37px" color="color-gray-a50" fontColor="color-white" textAlign="center">
                    <ContainerFlex width="12" justify="center" textAlign="center" children={<TextLocalized width="12" children="msgHomeTitleOne" />} />
                    <ContainerFlex width="8" fontWeight="400" fontSize="18px" justify="center" ph="32" mv="16" children={<TextLocalized children="msgHomeSubTitle" />} />
                </ContainerFlex>
            </Container>

            <ContainerFlex width="12" justify="center" pv="48">
                <ContainerFlex width="12" lg="8" align="stretch">
                    <Container width="12" lg="6" pv="32">
                        <Container width="12" fontSize="48px" fontWeight="bold" fontColor="color-black" children={<TextLocalized children="msgHomeSection2" />} />
                        <Container width="12" mt="16" children={<TextLocalized children="msgHomeSection2Text" />} />

                        <Button onClick={() => history("/login")} mt="64" ph="64" pv="16" color="color-black" hoverColor="color-black-dark" radius="30px" fontSize="17px" fontWeight="normal" fontColor="color-white" textAlign="center">
                            <TextLocalized children="msgHomeSection2Btn" />
                        </Button>

                        <Button onClick={() => history("/register")} mv="32" fontSize="16px" fontWeight="normal" fontColor="color-black" textAlign="center">
                            <TextLocalized children="actionAlready" />
                        </Button>
                    </Container>

                    <Container width="0" lg="6" px="32">
                        <Card flex width="12" height="100%" image={home2} radius="20px" />
                    </Container>
                </ContainerFlex>

                <ContainerFlex width="12" lg="8" mt="64" height="2px" color="color-black" />
            </ContainerFlex>

            <ContainerFlex width="12" pv="48" justify="center">
                <Container width="12" fontSize="28px" fontWeight="bold" fontColor="color-black" textAlign="center">
                    <TextLocalized children="msgHomeSection3" />
                </Container>

                <ContainerFlex width="12" lg="10" justify="center" align="center" mv="32">
                    <img alt="partner-1" width="220" style={{ padding: '32px' }} src={partner1} />
                    <img alt="partner-2" width="220" style={{ padding: '32px' }} src={partner2} />
                    <img alt="partner-3" width="220" style={{ padding: '32px' }} src={partner3} />
                    <img alt="partner-4" width="220" style={{ padding: '32px' }} src={partner4} />
                </ContainerFlex>

                <ContainerFlex width="12" justify="center" align="center" mb="48">
                    <Button onClick={() => window.open("https://furncsr.eu/contact/", "_blank")}
                        mx="16" ph="64" pv="16" color="color-primary" hoverColor="color-primary-dark"
                        radius="30px" fontSize="17px" fontWeight="normal" fontColor="color-white" textAlign="center">
                        <TextLocalized children="msgHomeSection3Btn" />
                    </Button>
                </ContainerFlex>
            </ContainerFlex>

        </Container>
    );
};

export default HomeView;