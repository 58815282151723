import React, { Fragment, useContext, useState } from 'react';
import BlockPracticeType from './BlockPracticeType';
import { getTextLang, LanguageContext } from './providers/LanguageProvider';
import { UserAction, UserContext } from './providers/UserProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from './styled/Styled';
import { ModalBusinessCase } from './util/ModalUtil';

const GoodPracticeTypeItem = ({ item }) => {
    const [showList, setShowList] = useState(false);
    const { dispatch } = useContext(UserContext);
    const { language } = useContext(LanguageContext);

    const showBusinessCases = () => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalBusinessCase items={item.business_cases} /> } })
    }

    return <Container width="12" px="4">
        <Card flex shadowOn width="12" align="stretch" color="color-white">
            <Button onClick={() => setShowList(!showList)} width="12" pv="20" ph="16" fontSize="18px" fontWeight="bold" children={`${getTextLang(item, 'name', language)}`} />
            {
                showList ?
                    <Fragment>
                        <Container px="16" width="12">
                            {
                                item.good_practices.length === 0 ? <TextLocalized children="msgBusinessCaseEmpty" /> :
                                    item.good_practices.map((practice) => <BlockPracticeType key={practice.id_good_practice} item={practice} />)
                            }
                        </Container>
                        {
                            item.business_cases.length > 0 ?
                                <ContainerFlex width="12" justify="flex-end">
                                    <Button onClick={showBusinessCases} shadowOn mb="8" mh="16" ph="16" pv="8" color="color-blue" hoverColor="color-blue-light"
                                        fontColor="color-white" fontWeight="bold" textAlign="center"
                                        children={<TextLocalized children={item.business_cases.length === 1 ? "actionBusinessCase" : "actionBusinessCases"} />} />
                                </ContainerFlex>
                                : null
                        }
                    </Fragment>
                    : null
            }
        </Card>
    </Container>
}

export default GoodPracticeTypeItem;