import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonFlat from '../components/ButtonFlat';
import { LanguageContext } from '../components/providers/LanguageProvider';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { Button, Container, ContainerFlex, CustomInput, SelectInput, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { verifyEmailFormat } from '../components/util/UserUtil';
import { companySizes, countries, productTypes, userDefault } from '../constants/model';

import login_img from '../img/login_img.jpg'
import logo from '../img/logo.png';

const RegisterView = () => {

    const [formData, setFormData] = useState({ ...userDefault });
    const { dictionary } = useContext(LanguageContext);
    const { dispatch } = useContext(UserContext);

    let history = useNavigate();

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendRequest = () => {
        if (formData.firstname !== "" && formData.lastname !== "" && formData.username !== "" && formData.email !== "" && formData.password !== "" && formData.repassword !== "" && formData.country !== "" && formData.types !== "" && formData.size !== "") {
            if (verifyEmailFormat(formData.email)) {
                if (formData.password == formData.repassword) {
                    //Send request
                    HttpConnection(HttpOperations.user,
                        result => {
                            history("./", { replace: true });
                            dispatch({ action: UserAction.login, data: result.item })
                        },
                        error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorUserIncorrect" } }),
                        HttpMethod.post, formData);
                } else {
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorPassSame" } })
                }
            } else {
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorEmailFormat" } })
            }
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    return (
        <ContainerFlex width="12" minheight="calc(100vh - 80px)" align="stretch">
            <ContainerFlex width="6" minheight="100%" image={login_img} />
            <ContainerFlex width="6" px="32" align="center">
                <img height="48px" src={logo} />
                <Container width="12" mv="8" fontWeight="bold" fontSize="32px" children={<TextLocalized children="msgGetStarted" />} />
                <Container width="12" mb="32" fontSize="16px" children={<TextLocalized children="msgGetStartedSubtitle" />} />

                <Container width="12" mb="8" fontWeight="bold" fontSize="22px" children={<TextLocalized children="msgUserInfo" />} />

                <ContainerFlex width="12">
                    <CustomInput onChange={onChange} inputmv="16" inputmr="8" titleph="16" title={"msgFirstname"} placeholder={dictionary["msgFirstname"]} type="text" name="firstname" value={formData.firstname} />
                    <CustomInput onChange={onChange} inputmv="16" titleph="16" title={"msgLastname"} placeholder={dictionary["msgLastname"]} type="text" name="lastname" value={formData.lastname} />
                </ContainerFlex>

                <CustomInput onChange={onChange} titleph="16" inputmr="8" title={"msgCompanyUser"} placeholder={dictionary["msgCompanyUser"]} type="text" name="username" value={formData.username} />
                <SelectInput onChange={onChange} options={countries} titleph="16" title={"msgCountry"} placeholder={dictionary["msgCountry"]} type="text" name="country" value={formData.country} />

                <ContainerFlex width="12" mt="16">
                    <SelectInput onChange={onChange} options={productTypes} titleph="16" inputmr="8" title={"msgProductType"} placeholder={dictionary["msgProductType"]} type="text" name="types" value={formData.types} />
                    <SelectInput onChange={onChange} options={companySizes} titleph="16" title={"msgCompanySize"} placeholder={dictionary["msgCompanySize"]} type="text" name="size" value={formData.size} />
                </ContainerFlex>

                <Container width="12" mt="32" mb="8" fontWeight="bold" fontSize="22px" children={<TextLocalized children="msgUserCredentials" />} />

                <CustomInput onChange={onChange} width="12" inputmt="16" titleph="16" title={"msgEmail"} placeholder={dictionary["msgEmail"]} type="email" name="email" value={formData.email} />

                <ContainerFlex width="12">
                    <CustomInput onChange={onChange} inputmv="16" inputmr="8" titleph="16" title={"msgPassword"} placeholder={dictionary["msgPassword"]} type="password" name="password" value={formData.password} />
                    <CustomInput onChange={onChange} inputmv="16" titleph="16" title={"msgConfirmPassword"} placeholder={dictionary["msgConfirmPassword"]} type="password" name="repassword" value={formData.repassword} />
                </ContainerFlex>

                <Button onClick={() => history("/login")} mt="32" ph="8" fontSize="12px" children={<TextLocalized children="msgAlreadyRegister" />} />

                <ButtonFlat mt="48" action={sendRequest} label="actionRegister" fontColor="color-white" />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default RegisterView;