import React, { useContext } from 'react';
import GoodPracticeTypeItem from './GoodPracticeTypeItem';
import { getTextLang, LanguageContext } from './providers/LanguageProvider';
import { Card, ContainerFlex } from './styled/Styled';

const DimensionCaseItem = ({ item }) => {
    const { language } = useContext(LanguageContext);
    const getColor = () => item.id_dimension === 1 ? "color-economic" : item.id_dimension === 2 ? "color-social" : "color-ecolo";

    return <ContainerFlex width="12">
        <Card align="center" mt="32" mb="16" ph="16" pv="8" fontSize="18px" fontWeight="300" color={getColor()}
            fontColor="color-white" children={getTextLang(item, 'name', language)} />

        {item.good_practices_type.map((group) => <GoodPracticeTypeItem key={group.id_good_practice_type} item={group} />)}
    </ContainerFlex>
}

export default DimensionCaseItem;