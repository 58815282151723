import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonFlat from '../components/ButtonFlat';
import { LanguageContext } from '../components/providers/LanguageProvider';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { Card, Container, ContainerFlex, CustomInput, SelectInput, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { updateStorageUser, verifyEmailFormat } from '../components/util/UserUtil';
import { companySizes, countries, passwordDefault, productTypes, userDefault } from '../constants/model';

const ProfileView = () => {

    const [formData, setFormData] = useState({ ...userDefault });
    const [passwords, setPasswords] = useState({ ...passwordDefault });

    const { dictionary } = useContext(LanguageContext);
    const { dispatch } = useContext(UserContext);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onChangePass = (e) => setPasswords({ ...passwords, [e.target.name]: e.target.value });

    useEffect(() => {
        HttpConnection(HttpOperations.user,
            response => {
                setFormData(response.item);
            }, error => console.log(error), HttpMethod.get)
    }, [])

    const sendRequest = () => {
        if (formData.firstname !== "" && formData.lastname !== "" && formData.username !== "" && formData.email !== "" && formData.country !== "" && formData.types !== "" && formData.size !== "") {
            if (verifyEmailFormat(formData.email)) {
                //Send request
                HttpConnection(HttpOperations.user,
                    result => {
                        setFormData(result.item);
                        updateStorageUser(result.item);
                        dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } });
                    },
                    error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorUserIncorrect" } }),
                    HttpMethod.put, formData);
            } else {
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorEmailFormat" } })
            }
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    const sendPassRequest = () => {
        if (passwords.password !== "" && passwords.repassword !== "") {
            if (passwords.password === passwords.repassword) {
                HttpConnection(HttpOperations.user,
                    result => {
                        setPasswords({...passwordDefault});
                        dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } });
                    },
                    error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorUserIncorrect" } }),
                    HttpMethod.patch, passwords);
            } else {
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorPassSame" } })
            }
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    return (
        <ContainerFlex width="12" justify="center" align="center" height="100vh">
            <Container width="12" lg="8">
                <Card width="12" shadowOn color="color-white" px="32" align="center">
                    <Container width="12" mv="8" fontWeight="bold" fontSize="22px" children={<TextLocalized children="msgUserInfo" />} />

                    <ContainerFlex width="12">
                        <CustomInput onChange={onChange} inputmv="16" inputmr="8" titleph="16" title={"msgFirstname"} placeholder={dictionary["msgFirstname"]} type="text" name="firstname" value={formData.firstname} />
                        <CustomInput onChange={onChange} inputmv="16" titleph="16" title={"msgLastname"} placeholder={dictionary["msgLastname"]} type="text" name="lastname" value={formData.lastname} />
                    </ContainerFlex>

                    <ContainerFlex width="12">
                        <CustomInput onChange={onChange} titleph="16" inputmr="8" title={"msgCompanyUser"} placeholder={dictionary["msgCompanyUser"]} type="text" name="username" value={formData.username} />
                        <SelectInput onChange={onChange} options={countries} titleph="16" title={"msgCountry"} placeholder={dictionary["msgCountry"]} type="text" name="country" value={formData.country ?? ""} />
                    </ContainerFlex>

                    <ContainerFlex width="12" mt="16">
                        <SelectInput onChange={onChange} options={productTypes} titleph="16" inputmr="8" title={"msgProductType"} placeholder={dictionary["msgProductType"]} type="text" name="types" value={formData.types ?? ""} />
                        <SelectInput onChange={onChange} options={companySizes} titleph="16" title={"msgCompanySize"} placeholder={dictionary["msgCompanySize"]} type="text" name="size" value={formData.size ?? ""} />
                    </ContainerFlex>

                    <CustomInput onChange={onChange} inputmv="16" titleph="16" title={"msgEmail"} placeholder={dictionary["msgEmail"]} type="email" name="email" value={formData.email} />

                    <ButtonFlat mt="24" action={sendRequest} label="actionSave" fontColor="color-white" />
                </Card>

                <Card width="12" mt="16" shadowOn color="color-white" px="32" align="center">
                    <Container width="12" mv="8" fontWeight="bold" fontSize="22px" children={<TextLocalized children="msgUserCredentials" />} />

                    <ContainerFlex width="12">
                        <CustomInput onChange={onChangePass} inputmv="16" inputmr="8" titleph="16" title={"msgPassword"} placeholder={dictionary["msgPassword"]} type="password" name="password" value={passwords.password} />
                        <CustomInput onChange={onChangePass} inputmv="16" inputmr="8" titleph="16" title={"msgConfirmPassword"} placeholder={dictionary["msgConfirmPassword"]} type="password" name="repassword" value={passwords.repassword} />
                    </ContainerFlex>

                    <ButtonFlat mt="24" action={sendPassRequest} label="actionSave" fontColor="color-white" />
                </Card>
            </Container>
        </ContainerFlex>
    );
};

export default ProfileView;