import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTextLang, LanguageContext } from './providers/LanguageProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from './styled/Styled';
import { getBlockImage } from './util/BlockUtil';


const BlockItem = ({ item }) => {
    let history = useNavigate();
    const { language } = useContext(LanguageContext);

    const getProgress = (index) => {
        return item.dimensions ? item.dimensions[index].progress.toFixed(0) : 0;
    }

    return <Container width="12" sm="6" lg="4" px="4">
        <Card flex direction="column" shadowOn width="12" minheight="100%" color="color-white">
            <ContainerFlex expand width="12">
                <Container width="12">
                    <Card flex justify="center" px="16" width="12" radiusbl="0" radiusbr="0" height="180px" color="color-purple">
                        <img height="100%" src={getBlockImage(item)} />
                    </Card>
                    <Container width="12" ph="16" mt="16" fontSize="18px" fontWeight="bold" children={getTextLang(item, 'name', language)}  />
                    <Container width="12" ph="16" fontSize="14px" pv="16" children={getTextLang(item, 'description_short', language)} />
                </Container>
            </ContainerFlex>
            <ContainerFlex width="12" align="center" pb="16">
                <Container width="12" ph="16" pv="8" fontWeight="bold" fontColor="color-black" children={<TextLocalized children="msgProgress" />} />
                <ContainerFlex width="12" justify="flex-start" pb="16">
                    <Card flex justify="center" align="center" mh="16" border="solid" borderColor="color-economic" borderWidth="2px" radius="50%"
                        fontColor="color-black" fontWeight="bold"
                        minheight="54px" minwidth="54px" children={`${getProgress(0)}%`} />

                    <Card flex justify="center" align="center" mh="16" border="solid" borderColor="color-social" borderWidth="2px" radius="50%"
                        fontColor="color-black" fontWeight="bold"
                        minheight="54px" minwidth="54px" children={`${getProgress(1)}%`} />

                    <Card flex justify="center" align="center" mh="16" border="solid" borderColor="color-ecolo" borderWidth="2px" radius="50%"
                        fontColor="color-black" fontWeight="bold"
                        minheight="54px" minwidth="54px" children={`${getProgress(2)}%`} />
                </ContainerFlex>

                <Button onClick={() => history("/section", { state: { item: item } })}
                    width="wrap" mh="8" pv="8" color="color-blue" hoverColor="color-blue-light"
                    fontColor="color-white" fontWeight="bold" textAlign="center"
                    children={<TextLocalized children={getProgress(0) === "0" && getProgress(1) === "0" && getProgress(2) === "0" ? "actionStart" : "actionContinue"} />} />
            </ContainerFlex>
        </Card>
    </Container>
};

export default BlockItem;