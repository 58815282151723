import React from 'react';
import { Container, ContainerFlex, TextLocalized, Button } from './styled/Styled';

import Icon from '@mdi/react';
import { mdiEmail, mdiLinkedin, mdiTwitter } from '@mdi/js';

import logo_ue from '../img/logo_ue.png'
import logo_white from '../img/logo_white.png'

export const Footer = () => {

    return <Container fontColor="color-white">

        <ContainerFlex pt="40" justify="center" align="center" color="color-black" fontColor="colorwhite" fontSize="12px">

            <Container textAlign="center" justify="center" ph="32" width="12" lg="3">
                <Button shadow={false} onClick={() => window.open("https://furncsr.eu", '_blank')}>
                    <img style={{ marginRight: "16px" }} width="200" alt="logo_furncsr" src={logo_white} />
                </Button>
            </Container>

            <ContainerFlex px="32" justify="center" width="12" lg="6">
                <Container mh="8" textAlign="center">
                    <TextLocalized children="msgFooterContact" />
                    <ContainerFlex justify="center" mv="16">
                        <Button onClick={() => window.open("https:/furncsr.eu/contact/", '_blank')} border="solid" borderWidth="2px" borderColor="color-white" px="8" radius="50%" maxheight="68px" mh="16">
                            <Icon path={mdiEmail} size={2} />
                        </Button>
                    </ContainerFlex>
                </Container>

                <Container mh="8" textAlign="center">
                    <TextLocalized children="msgFooterLinkedIn" />
                    <ContainerFlex justify="center" mv="16">
                        <Button onClick={() => window.open("https://www.linkedin.com/company/ambitcluster/", '_blank')} border="solid" borderWidth="2px" borderColor="color-white" px="8" radius="50%" maxheight="68px" mh="16">
                            <Icon path={mdiLinkedin} size={2} />
                        </Button>
                    </ContainerFlex>
                </Container>

                <Container mh="8" textAlign="center">
                    <TextLocalized children="msgFooterTwitter" />
                    <ContainerFlex justify="center" mv="16">
                        <Button onClick={() => window.open("https://twitter.com/ambitcluster", '_blank')} border="solid" borderWidth="2px" borderColor="color-white" px="8" radius="50%" maxheight="68px" mh="16">
                            <Icon path={mdiTwitter} size={2} />
                        </Button>
                    </ContainerFlex>
                </Container>
            </ContainerFlex>

            <ContainerFlex align="center" justify="center" width="12" lg="3" pv="10" ph="32" color="colorblack">
                <Container width="6" lg="12" px="8" children={<img width="100%" alt="img" src={logo_ue} />} />
                <Container width="6" lg="12" px="8" align="center" textColor="colorwhite">
                    <TextLocalized children="msgFooterEu" />
                    <Container width="12" pv="8" children={<TextLocalized children="msgFooterGr" />} />
                </Container>
            </ContainerFlex>

            <Container minheight="1px" width="12" color="color-white" />

            <ContainerFlex ph="32" pv="16" width="12" lg="12" textAlign="center" align="center">
                <Container width="auto" fontWeight="bold" children={<TextLocalized children="msgCopyright" />} />

                <Button onClick={() => window.open("https://ambitcluster.org/en/legal-notice", '_blank')} shadow={false} mh="16" mv="8" children={<TextLocalized children="msgFooterLegal" />} />
                <Button onClick={() => window.open("https://ambitcluster.org/en/privacy-policy", '_blank')} shadow={false} mh="16" mv="8" children={<TextLocalized children="msgFooterPrivacy" />} />
                <Button onClick={() => window.open("https://ambitcluster.org/en/cookie-policy", '_blank')} shadow={false} mh="16" mv="8" children={<TextLocalized children="msgFooterCookies" />} />
            </ContainerFlex>
        </ContainerFlex>


    </Container>
}
