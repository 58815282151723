import React from 'react';
import InfoLevel from '../components/InfoLevel';
import { Card, Container, ContainerFlex, TextLocalized } from '../components/styled/Styled';
import { levelInfos } from '../constants/model';

import canvas from '../img/canvas_level.png';

const AboutView = () => {
    return (
        <ContainerFlex width="12" justify="center" px="32" >
            <Container width="12" lg="8">
                <Card width="12" shadowOn flex mv="16" align="center" color="color-white" fontColor="color-black">
                    <Card width="12" px="16" color="color-blue-drawer" radiusbl="0" radiusbr="0" fontColor="color-white" fontSize="18px" fontWeight="300" children={<TextLocalized children="msgAboutSec1Title" />} />
                    <Container width="12" px="16" fontSize="14px" children={<TextLocalized children="msgAboutSec1Text1" />} />
                    <img alt='levels' src={canvas} width="100%" />
                    <Container width="12" px="16" fontSize="14px" children={<TextLocalized children="msgAboutSec1Text2" />} />
                </Card>

                {
                    levelInfos.map(item => <InfoLevel key={item.id_level} item={item} />)
                }
            </Container>
        </ContainerFlex>
    );
};

export default AboutView;