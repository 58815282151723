const ro = {
    actionAlready: "Ai deja un cont? Conectează-te",
    actionExit: "Deconectează-te",
    actionLogin: "CONECTARE",
    actionRegister: "ÎNREGISTRARE",
    actionSave: "SALVARE",
    actionStart: "ÎNCEPERE",
    actionContinue: "CONTINUARE",
    actionReset: "RESETARE EVALUARE",
    actionDoDiagnost: "AFIȘARE DIAGNOSTIC",
    actionYes: "Da",
    actionNo: "Nu",
    actionNext: "URMĂTORUL",
    actionBusinessCase: "AFIȘARE CAZ DE AFACERI",
    actionBusinessCases: "AFIȘARE CAZURI DE AFACERI",
    actionOpenUrl: "DESCHIDERE URL",
    actionFilter: "FILTRRE",
    
    /** HOME */
    msgHomeTitleOne: "Măsoară și mărește impactul pozitiv al firmei tale de mobilă",
    msgHomeSubTitle: "Instrumentul online FurnCSR evaluează progresul actual al RSC-ului tău pe baza impactului economic, organizațional, societal și asupra mediului.",
    
    msgHomeSection2: "Cerințele pentru RSC se extind.</br>Ești pregătit?",
    msgHomeSection2Text: "<ul><li>Răspunde la întrebările legate de activitățile tale cotidiene de afaceri conform standardelor principale de impact.</li><li>Obține scorul de autoevaluare a nivelului de maturitate.</li><li>Identifică bune practici care să-ți conducă firma la următorul nivel.</li><li>Învață din exemple practice reale din sectorul mobilei pentru a-ți crește în permanență impactul pozitiv.</li></ul>",
    msgHomeSection3: "Parteneri",
    msgHomeSection2Btn: "Începe evaluarea",
    msgHomeSection3Btn: "CONTACT",
    
    /** HEADER */
    msgHeader1: "Bune practici",
    msgHeader4: "Conectare",
    
    /** DRAWER */
    msgCompany: "COMPANIE",
    msgDrawer1: "Evaluare FurnCSR",
    msgDrawer2: "Raport de performanță",
    msgDrawer3: "Raport de îmbunătățire",
    msgDrawer4: "Colecție de bune practici",
    msgDrawer5: "Despre nivelurile FurnCSR",
    msgDrawer6: "Setări",
    msgDrawer7: "Deconectare",
    msgDrawer8: "Evaluează-ți experiența",
    
    /** LOGIN */
    msgWelcome: "Bun venit",
    msgWelcomeSubtitle: "Conectează-te pentru a continua",
    
    /** REGISTER */
    msgGetStarted: "Să începem",
    msgGetStartedSubtitle: "Acest instrument de gestionare a impactului este gratuit și confidențial.",
    
    msgFirstname: "Prenume",
    msgLastname: "Nume de familie",
    msgEmail: "E-mail",
    msgPassword: "Parolă",
    msgConfirmPassword: "Confirmare parolă",
    msgCompanyUser: "Companie sau nume de utilizator",
    
    msgNotRegister: "Încă nu ești înregistrat? <b>Creează un cont aici</b>",
    msgAlreadyRegister: "Ești deja înregistrat? <b>Conectează-te aici</b>",
    
    /** HOME TOOL */
    msgClickImage: "Dă clic pentru a desfășura FurnCSR Business CANVAS.",
    
    msgArea: "Domeniu",
    msgProgress: "ÎNTREBĂRI VĂZUTE",
    
    msgAssessmentAreas: "Domenii de impact FurnCSR",
    msgAssessmentDesc: "Toate întrebările instrumentului FurnCSR sunt organizate conform blocurilor comerciale principale ale unei companii de mobilă responsabilă social. Acesta este FurnCSR Business CANVAS. Producția și operațiunile interne se află în partea stângă a canvasului, iar oferta și experiențele externe sunt descrise în partea dreaptă. Folosim o abordare pe trei straturi (economic/organizațional, societale și de mediu) pentru a evalua activitățile de afaceri orientate către CSR în fiecare bloc. ",
    
    msgAssessment: "Evaluare FurnCSR",
    msgNotApply: "Bifează răspunsurile care se aplică dezvoltării actuale a companiei tale; dacă nu se aplică niciun răspuns, nu bifa nicio opțiune.",
    msgMarkImportant: "Marcare ca important",
    msgComment: "Comentariu",
    msgCommentHolder: "Poți scrie aici orice informații aferente sau propriile tale comentarii.",
    
    /** PERFORMANCE REPORT */
    msgPerformance: "Raport de performanță",
    msgPerformanceSubtitle: "Verifică scorul de autoevaluare a nivelului de maturitate pentru fiecare bloc de afaceri și unul general.",
    msgCompanyLevel: "Nivelul companiei tale",
    msgBlockArea: "DOMENIU BLOC",
    
    /** IMPROVEMENT REPORT */
    msgImprovement: "Raport de îmbunătățire",
    msgImprovementMainSubtitle: "Află ce poți face pentru o îmbunătățire permanentă. Identifică mai multe bune practici și cazuri de afaceri care să conducă firma ta la nivelul următor.</br></br>Poți începe să citești și să te inspiri din conținutul nivelurilor inferioare, iar apoi poți citi și încerca să le pui în practică, în mod progresiv, pe cele de la nivelurile superioare. Reține că practicile de nivel 1 și 2 trebuie să respecte legislația și reglementările aplicabile, îndeplinirea cerințelor acestora fiind o obligație legală și, prin urmare, foarte importantă.",
    msgImprovementSubtitle: "Află ce poți face pentru o îmbunătățire permanentă. Identifică mai multe bune practici și cazuri de afaceri care să conducă firma ta la nivelul următor.",
    msgLevels: "Niveluri",
    msgActionsToImplement: "Acțiuni care trebuie implementate",
    msgBaselineScore: "Scorul meu de bază",
    msgBusinessCaseEmpty: "Felicitări! Ai implementat deja cele mai importante bune practici conținute în această categorie.",
    
    msgCat1: "ECONOMICO-ORGANIZAȚIONAL",
    msgCat2: "SOCIETAL",
    msgCat3: "DE MEDIU",
    
    /** GOOD PRACTICES COLLECTION */
    msgGoodPracticesSubtitle: "Navighează și explorează întreaga colecție de bune practici și cazuri de afaceri ale proiectului FurnCSR.",
    msgBusinessBlock: "Bloc comercial",
    msgLevel: "Nivel",
    msgDimension: "Dimensiune",
    msgStep: "Step",
    
    /** SETTINGS */
    msgUserInfo: "Date utilizator",
    msgUserCredentials: "Acreditări utilizatori",
    
    /** MODAL */
    msgAreSure: "Ești sigur?",
    
    msgSuccess: "Datele au fost actualizate cu succes",
    msgDeleteSuccess: "Datele au fost șterse cu succes",
    
    /** ERROR */
    errorOblData: "Completează toate câmpurile obligatorii",
    errorEmailFormat: "Eroare de format al e-mailului",
    errorUserIncorrect: "E-mail/parolă incorect",
    errorSend: "Eroare de comunicare, încearcă din nou mai târziu",
    errorLength: "S-a depășit numărul de caractere",
    errorPassSame: "Parolele nu sunt identice",
    
    /** FOOTER */
    msgFooterContact: "CONTACT",
    msgFooterLinkedIn: "LINKEDIN",
    msgFooterTwitter: "TWITTER",
    
    msgFooterGr: "Acest proiect a fost finanțat prin intermediul cererii de oferte a Comisiei Europene: Sprijin pentru dialogul social VP/2020/001",
    msgCopyright: "Copyright&copy;2023 AMBIT. Toate drepturile rezervate",
    
    msgFooterLegal: "Aviz juridic",
    msgFooterPrivacy: "Confidențialitate",
    msgFooterCookies: "Politica de cookie",
    
    /** PROGRESS VALUES */
    msgProgress0: "Domeniile care pot fi îmbunătățite",
    msgProgress1: "Respectarea reglementărilor",
    msgProgress2: "Îmbunătățirea legislației",
    msgProgress3: "Îndeplinirea aşteptărilor",
    msgProgress4: "Angajamentul",
    
    /** COMPANY SIZES */
    msgCompanySize: "Mărimea companiei",
    msgCompanySize1: "Mai puțin de 10 angajați",
    msgCompanySize2: "10-30 angajați",
    msgCompanySize3: "30-50 angajați",
    msgCompanySize4: "Mai mult de 50 angajați",
    
    /** PRODUCT TYPES */
    msgProductType: "Tipul produselor pe care le fabricați",
    msgProductType1: "Tapițerie/capitonare",
    msgProductType2: "Saltele",
    msgProductType3: "Stofe pentru tapițerie, canapele, somiere",
    msgProductType4: "Subcontractant (cadre, prelucrare, lăcuire etc.)",
    msgProductType5: "Servicii (logistică specializată etc.)",
    msgProductType6: "Mobilier din plăci (melaminat, furnir, lăcuit etc.)",
    msgProductType7: "Mobilier din lemn",
    msgProductType8: "Scaune",
    msgProductType9: "Mobilier de birou",
    msgProductType10: "Mobilier pentru spații și uz publice",
    msgProductType11: "Uși, ferestre și produse de tâmplărie",
    msgProductType12: "Facilități personalizate (magazine, biblioteci, restaurante, afaceri profesionale etc.)",
    msgProductType13: "Iluminat",
    msgProductType14: "Acoperiri și podele",
    msgProductType15: "Textile",
    msgProductType16: "Decorațiuni",

    /** ABOUT */
    msgAboutSec1Title: "Introducere în modelul FurnCSR orientativ de progres",
    msgAboutSec1Text1:"Îndrumarul pentru progres, pe care îl prezentăm în FurnCSR, ne permite să evidenţiem practicile CSR ale organizaţiei, în timp ce absenţa celor relevante din unele casete ne va permite să detectăm eventualele goluri de abordat.</br></br>Conceput din elementele fundamentale ale CSR, Îndrumarul pentru progres indică, global şi într-o manieră structurată, poziţia în care organizaţia este consolidată şi calea pe care trebuie să o urmeze privind gestionarea CSR.</br></br>Modelul se bazează pe cinci niveluri care, duplicate în două coloane, oferă 10 grade, de la domeniile de îmbunătăţire la cel de angajare maxim. Prezentăm modelul de bază într-un mod simplificat, cu scopul de a oferi şi alte propuneri privind bunele practici, diferite de cele de mai sus (mai exact capitolul 4), în sensul că vor permite progresul în dezvoltarea integrată a unui model CSR</br></br>În figurile următoare sunt indicate diversele niveluri şi grade de maturitate privind CSR.</br></br>Mergând în sus de la prima coloană (caseta 0), observăm diversele grade între (ne)respectarea legislaţiei şi nivelul maxim al angajării (caseta 9). Mergând în sus în coloana din dreapta, care conţine proactivitatea crescândă şi fundamentele metodologiei unice a CSR, prezenţa factorilor interesaţi este integrată treptat şi indicat progresul în logica cetăţeniei corporative. CSR cere repararea, asigurarea, înţelegerea, responsabilizarea şi crearea valorii partajate dincolo de ceea ce corespunde unei gestionări logice mai internalizate; nu este suficient să faci lucrurile cu cea mai bună intenţie posibil, ci trebuie să generezi impacturi, cu autenticitate şi încrederea factorilor interesaţi.",
    msgAboutSec1Text2: "Aceasta este un parcurs progresiv către excelenţă şi situarea în poziţii de mijloc poate constitui deja o valoare grozavă pentru firma de mobilă. Este important să nu părăsim subiectele din zonele inferioare şi să avem o viziune privind modul de a merge înainte, prin integrarea nu doar a bunelor practici prezentate în capitolul precedent, ci şi a celor indicate mai jos, situate la nivelurile superioare ale acestui model. În continuare, îndrumarul pentru progres poate fi aplicat tuturor celor trei dimensiuni ale managementului CSR, conform proprietăţilor specifice acestora şi menţinând întotdeauna aceeaşi cale, ceea ce face posibilă înţelegerea evoluţiei gradului de maturitate al CSR în cadrul companiei de mobilă.",

    msgAboutLevel1Title: "Arii de îmbunătăţire (paşii 0 şi 1)",
    msgAboutLevel1Text: "Acest nivel ariilor domeniilor de îmbunătăţire şi presupune că există nerespectări serioase sau diverse, legate atât de aspecte legale, cât şi de angajamente sau că firma se află încă în faza de corectare şi reparare",

    msgAboutLevel2Title: "Respectarea reglementărilor (paşii 2 şi 3)",
    msgAboutLevel2Text: "Acest nivel corespunde respectării reglementărilor şi înseamnă că îndeplineşte şi asigură respectarea obligaţiilor, indiferent dacă acestea sunt legale sau datorită propriilor angajamente voluntare. Pentru o organizaţie angajată serios în privinţa CSR, respectarea legislaţiei nu este legată doar de textul legii, ci şi de intenţia pe care organul de reglementare o urmăreşte în numele societăţii, evitarea încălcării legii. La nivelul respectării reglementărilor, sunt specificate practicile legate de respectarea principalelor cerinţe legale, întrucât, în absenţa acestora, nu se poate începe abordarea managementului CSR.",

    msgAboutLevel3Title: "Îmbunătăţirea reglementărilor (paşii 4 şi 5)",
    msgAboutLevel3Text: "Acest nivel corespunde îmbunătăţirii reglementărilor, ceea ce înseamnă că merge dincolo de obligaţiile legale, în ceea ce priveşte implementarea celor mai bune practici. Pentru o organizaţie angajată serios în privinţa CSR, au loc conversaţii cu factorii interesaţi, pentru a înţelege contextul sustenabilităţii, în sine",

    msgAboutLevel4Title: "Îndeplinirea aşteptărilor (paşii 6 şi 7)",
    msgAboutLevel4Text: "Acest nivel corespunde satisfacerii aşteptărilor, ceea ce înseamnă că aşteptările factorilor interesaţi sunt abordate prin intermediul unui management CSR sistematizat (planificare, acţiuni, integrarea în procese şi proceduri, evaluare, îmbunătăţire continuă etc.). Pentru o organizaţie angajată serios în privinţa CSR, se asigură o responsabilitate transparentă faţă de factorii interesaţi.",

    msgAboutLevel5Title: "Angajamentul (paşii 8 şi 9)",
    msgAboutLevel5Text: "Acest nivel corespunde angajamentului şi înseamnă că este efectuată o gestionare a angajamentelor, cu conştientizare şi leadership, explicită şi permanentă, cuprinzătoare şi corelată cu planificarea strategică şi un scop superior, cu orientare spre crearea valorii partajate împreună cu factorii interesaţi",

    /* COUNTRIES */
    msgCountry: "Țară",
    msgAfghanistan: "Afganistan",
    msgAlbania: "Albania",
    msgAlgeria: "Algeria",
    msgAmericanSamoa: "Samoa Americană",
    msgAndorra: "Andorra",
    msgAngola: "Angola",
    msgAnguilla: "Anguilla",
    msgAntarctica: "Antarctica",
    msgAntiguaandBarbuda: "Antigua si Barbuda",
    msgArgentina: "Argentina",
    msgArmenia: "Armenia",
    msgAruba: "Aruba",
    msgAustralia: "Australia",
    msgAustria: "Austria",
    msgAzerbaijan: "Azerbaidjan",
    msgBahamas: "Bahamas",
    msgBahrain: "Bahrain",
    msgBangladesh: "Bangladesh",
    msgBarbados: "Barbados",
    msgBelarus: "Bielorusia",
    msgBelgium: "Belgia",
    msgBelize: "Belize",
    msgBenin: "Benin",
    msgBermuda: "Bermude",
    msgBhutan: "Bhutan",
    msgBolivia: "Bolivia",
    msgBosniaHerzegovina: "Bosnia si Hertegovina",
    msgBotswana: "Botswana",
    msgBouvetIsland: "Insula Bouvet",
    msgBrazil: "Brazilia",
    msgBritishIndian: "Teritoriul Britanic al Oceanului Indian",
    msgBrunei: "Brunei",
    msgBulgaria: "Bulgaria",
    msgBurkinaFaso: "Burkina Faso",
    msgBurundi: "Burundi",
    msgCambodia: "Cambodgia",
    msgCameroon: "Camerun",
    msgCanada: "Canada",
    msgCapeVerde: "capul Verde",
    msgCaymanIslands: "Insulele Cayman",
    msgCentralAfrican: "Republica Centrafricană",
    msgChad: "Ciad",
    msgChile: "Chile",
    msgChina: "China",
    msgChristmasIsland: "Insula Craciunului",
    msgCocosIslands: "Insulele Cocos (Keeling).",
    msgColombia: "Columbia",
    msgComoros: "Comore",
    msgCongo: "Congo",
    msgRepublicCongo: "Republica Democratică Congo",
    msgCookIslands: "Insulele Cook",
    msgCostaRica: "Costa Rica",
    msgIvoryCoast: "coasta de Fildes",
    msgCroatia: "Croaţia",
    msgCuba: "Cuba",
    msgCyprus: "Cipru",
    msgCzechRepublic: "Republica Cehă",
    msgDenmark: "Danemarca",
    msgDjibouti: "Djibouti",
    msgDominica: "Dominica",
    msgDominicanRepublic: "Republica Dominicană",
    msgEastTimor: "Timorul de Est",
    msgEcuador: "Ecuador",
    msgEgypt: "Egipt",
    msgEngland: "Anglia",
    msgElSalvador: "El Salvador",
    msgEquatorialGuinea: "Guineea Ecuatorială",
    msgEritrea: "Eritreea",
    msgEstonia: "Estonia",
    msgEthiopia: "Etiopia",
    msgFalklandIslands: "Insulele Falkland",
    msgFaroeIslands: "Insulele Feroe",
    msgFijiIslands: "Insulele Fiji",
    msgFinland: "Finlanda",
    msgFrance: "Franţa",
    msgFrenchGuiana: "Guyana Franceză",
    msgFrenchPolynesia: "Polinezia Franceză",
    msgFrenchSouthern: "Teritoriile din sudul Frantei",
    msgGabon: "Gabon",
    msgGambia: "Gambia",
    msgGeorgia: "Georgia",
    msgGermany: "Germania",
    msgGhana: "Ghana",
    msgGibraltar: "Gibraltar",
    msgGreece: "Grecia",
    msgGreenland: "Groenlanda",
    msgGrenada: "Grenada",
    msgGuadeloupe: "Guadelupa",
    msgGuam: "Guam",
    msgGuatemala: "Guatemala",
    msgGuernsey: "Guernsey",
    msgGuinea: "Guineea",
    msgGuineaBissau: "Guineea-Bissau",
    msgGuyana: "Guyana",
    msgHaiti: "Haiti",
    msgHeardMcDonald: "Insula Heard și Insulele McDonald",
    msgHolySee: "Sfântul Scaun (statul orașului Vatican)",
    msgHonduras: "Honduras",
    msgHongKong: "Hong Kong",
    msgHungary: "Ungaria",
    msgIceland: "Islanda",
    msgIndia: "India",
    msgIndonesia: "Indonezia",
    msgIran: "Iranul",
    msgIraq: "Irak",
    msgIreland: "Irlanda",
    msgIsrael: "Israel",
    msgIsleMan: "insula Barbatului",
    msgItaly: "Italia",
    msgJamaica: "Jamaica",
    msgJapan: "Japonia",
    msgJersey: "Jersey",
    msgJordan: "Iordania",
    msgKazakhstan: "Kazahstan",
    msgKenya: "Kenya",
    msgKiribati: "Kiribati",
    msgKuwait: "Kuweit",
    msgKyrgyzstan: "Kârgâzstan",
    msgLaos: "Laos",
    msgLatvia: "Letonia",
    msgLebanon: "Liban",
    msgLesotho: "Lesotho",
    msgLiberia: "Liberia",
    msgLibyanJamahiriya: "Jamahiriya Arabă Libiană",
    msgLiechtenstein: "Liechtenstein",
    msgLithuania: "Lituania",
    msgLuxembourg: "Luxemburg",
    msgMacao: "Macao",
    msgNorthMacedonia: "Macedonia de Nord",
    msgMadagascar: "Madagascar",
    msgMalawi: "Malawi",
    msgMalaysia: "Malaezia",
    msgMaldives: "Maldive",
    msgMali: "Mali",
    msgMalta: "Malta",
    msgMarshallIslands: "Insulele Marshall",
    msgMartinique: "Martinica",
    msgMauritania: "Mauritania",
    msgMauritius: "Mauritius",
    msgMayotte: "Mayotte",
    msgMexico: "Mexic",
    msgMicronesia: "Micronezia, Statele Federate ale",
    msgMoldova: "Moldova",
    msgMonaco: "Monaco",
    msgMongolia: "Mongolia",
    msgMontserrat: "Montserrat",
    msgMontenegro: "Muntenegru",
    msgMorocco: "Maroc",
    msgMozambique: "Mozambic",
    msgMyanmar: "Myanmar",
    msgNamibia: "Namibia",
    msgNauru: "Nauru",
    msgNepal: "Nepal",
    msgNetherlands: "Olanda",
    msgNetherlandsAntilles: "Antilele Olandeze",
    msgNewCaledonia: "Noua Caledonie",
    msgNewZealand: "Noua Zeelandă",
    msgNicaragua: "Nicaragua",
    msgNiger: "Niger",
    msgNigeria: "Nigeria",
    msgNiue: "Niue",
    msgNorfolkIsland: "Insula Norfolk",
    msgNorthKorea: "Coreea de Nord",
    msgNorthernIreland: "Irlanda de Nord",
    msgNorthernMariana: "Insulele Mariane de Nord",
    msgNorway: "Norvegia",
    msgOman: "Oman",
    msgPakistan: "Pakistan",
    msgPalau: "Palau",
    msgPalestine: "Palestina",
    msgPanama: "Panama",
    msgPapua: "Papua Noua Guinee",
    msgParaguay: "Paraguay",
    msgPeru: "Peru",
    msgPhilippines: "Filipine",
    msgPitcairn: "Pitcairn",
    msgPoland: "Polonia",
    msgPortugal: "Portugalia",
    msgPuertoRico: "Puerto Rico",
    msgQatar: "Qatar",
    msgReunion: "Reuniune",
    msgRomania: "România",
    msgRussianFederation: "Federația Rusă",
    msgRwanda: "Rwanda",
    msgSaintHelena: "Sfânta Elena",
    msgSaintKitts: "Saint Kitts și Nevis",
    msgSaintLucia: "Sfânta Lucia",
    msgSaintPierre: "Saint Pierre și Miquelon",
    msgSaintVincent: "Saint Vincent și Grenadine",
    msgSamoa: "Samoa",
    msgSanMarino: "San Marino",
    msgSaoTome: "Sao Tome și Principe",
    msgSaudiArabia: "Arabia Saudită",
    msgScotland: "Scoţia",
    msgSenegal: "Senegal",
    msgSerbia: "Serbia",
    msgSeychelles: "Seychelles",
    msgSierraLeone: "Sierra Leone",
    msgSingapore: "Singapore",
    msgSlovakia: "Slovacia",
    msgSlovenia: "Slovenia",
    msgSolomonIslands: "Insulele Solomon",
    msgSomalia: "Somalia",
    msgSouthAfrica: "Africa de Sud",
    msgSouthSandwich: "Georgia de Sud și Insulele Sandwich de Sud",
    msgSouthKorea: "Coreea de Sud",
    msgSouthSudan: "Sudul Sudanului",
    msgSpain: "Spania",
    msgSriLanka: "SriLanka",
    msgSudan: "Sudan",
    msgSuriname: "Surinam",
    msgSvalbardJan: "Svalbard și Jan Mayen",
    msgSwaziland: "Swaziland",
    msgSweden: "Suedia",
    msgSwitzerland: "Elveţia",
    msgSyria: "Siria",
    msgTajikistan: "Tadjikistan",
    msgTanzania: "Tanzania",
    msgThailand: "Tailanda",
    msgTimorLeste: "Timorul de Est",
    msgTogo: "A merge",
    msgTokelau: "Tokelau",
    msgTonga: "Tonga",
    msgTrinidadTobago: "Trinidad și Tobago",
    msgTunisia: "Tunisia",
    msgTurkey: "Curcan",
    msgTurkmenistan: "Turkmenistan",
    msgTurksCaicos: "Insulele Turks și Caicos",
    msgTuvalu: "Tuvalu",
    msgUganda: "Uganda",
    msgUkraine: "Ucraina",
    msgUnitedArabEmirates: "Emiratele Arabe Unite",
    msgUnitedKingdom: "Regatul Unit",
    msgUnitedStates: "Statele Unite",
    msgUnitedStatesMinor: "Insulele minore periferice ale Statelor Unite",
    msgUruguay: "Uruguay",
    msgUzbekistan: "Uzbekistan",
    msgVanuatu: "Vanuatu",
    msgVenezuela: "Venezuela",
    msgVietnam: "Vietnam",
    msgVirginIslandsBritish: "Insulele Virgine, britanice",
    msgVirginIslandsUS: "Insulele Virgine, SUA",
    msgWales: "Țara Galilor",
    msgWallisFutuna: "Wallis și Futuna",
    msgWesternSahara: "sahara de Vest",
    msgYemen: "Yemen",
    msgZambia: "Zambia",
    msgZimbabwe: "Zimbabwe",
}

export default ro;