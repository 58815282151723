import React, { useContext, useState } from 'react';
import { redirect, useNavigate } from 'react-router-dom';
import ButtonFlat from '../components/ButtonFlat';
import { LanguageContext } from '../components/providers/LanguageProvider';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { Button, Card, Container, ContainerFlex, CustomInput, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { verifyEmailFormat } from '../components/util/UserUtil';

import login_img from '../img/login_img.jpg'
import logo from '../img/logo.png';

const LoginView = () => {
    const [formData, setFormData] = useState({ email: "", password: "" })
    const { dictionary } = useContext(LanguageContext)
    const { dispatch } = useContext(UserContext);

    let history = useNavigate();

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendRequest = () => {
        if (formData.email !== "" && formData.password !== "") {
            if (verifyEmailFormat(formData.email)) {
                //Send request
                HttpConnection(HttpOperations.login,
                    result => {
                        dispatch({ action: UserAction.login, data: result.item })
                        redirect("./")
                    },
                    error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorUserIncorrect" } }),
                    HttpMethod.post, formData);
            } else {
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorEmailFormat" } })
            }
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    return (
        <ContainerFlex width="12" px="32" minheight="calc(100vh - 80px)" image={login_img} justify="center" align="center">
            <Card minwidth="500px" ph="32" pv="64" shadowOn color="color-white">
                <img height="48px" src={logo} />
                <Container mb="8" fontWeight="bold" fontSize="28px" children={<TextLocalized children="msgWelcome" />} />
                <Container mb="32" fontSize="16px" children={<TextLocalized children="msgWelcomeSubtitle" />} />

                <CustomInput onChange={onChange} width="12" inputmv="16" titleph="16" title={"msgEmail"} placeholder={dictionary["msgEmail"]} type="email" name="email" value={formData.email} />
                <CustomInput onChange={onChange} width="12" inputmv="8" titleph="16" title={"msgPassword"} placeholder={dictionary["msgPassword"]} type="password" name="password" value={formData.password} />

                <Button onClick={() => history("/register")} mt="32" ph="8" fontSize="12px" children={<TextLocalized children="msgNotRegister" />} />

                <ButtonFlat mt="48" action={sendRequest} label="actionLogin" fontColor="color-white" />
            </Card>
        </ContainerFlex>
    );
};

export default LoginView;