import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTextLang, LanguageContext } from './providers/LanguageProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from './styled/Styled';
import { getBlockImage, getProgressData } from './util/BlockUtil';

const BlockPerformance = ({ item }) => {
    const [progress, setProgress] = useState({ text: "msgProgress0", color: "color-red" });
    const {language} = useContext(LanguageContext);

    let history = useNavigate();

    useEffect(() => {
        let score = (item.score * 100).toFixed(0);
        setProgress(getProgressData(score))
    }, [item.score])

    return <Container width="12" px="4">
        <Button flex shadowOn width="12" align="stretch" color="color-white" onClick={() => history("/performance/section", { state: { item: item } })}>
            <Card flex justify="center" px="16" width="150px" radiusbr="0" radiustr="0" minheight="100%" maxheight="141px" color="color-purple">
                <img alt={`block-${item.id_block}`} height="100%" width="100%" src={getBlockImage(item)} />
            </Card>
            <ContainerFlex width="wrap" justify="center" direction="column" pv="20" ph="16">
                <Container mb="8" fontColor="color-gray-dark" children={<TextLocalized children="msgBlockArea" />} />
                <Container mb="8" fontSize="18px" fontWeight="bold" children={getTextLang(item, 'name', language)} />
                <Card pv="4" ph="8" border="solid" fontSize="12px" fontColor="color-white" color={progress.color} borderColor={progress.color} borderWidth="2px" children={<TextLocalized children={progress.text} />} />
            </ContainerFlex>
            <ContainerFlex align="center">
                <Card flex justify="center" align="center" mh="16" border="solid" borderColor="color-black" borderWidth="3px" radius="50%"
                    fontColor="color-black" fontSize="14px" fontWeight="bold"
                    height="54px" minwidth="54px" children={`${(item.score * 100).toFixed(0)}%`} />
            </ContainerFlex>
        </Button>
    </Container>
};

export default BlockPerformance;