import { mdiOfficeBuilding } from '@mdi/js';
import Icon from '@mdi/react';
import React, { Fragment, useContext, useState } from 'react';
import { getTextLang, LanguageContext } from '../providers/LanguageProvider';
import { UserAction, UserContext } from '../providers/UserProvider';
import { Container, ContainerFlex, Button, Card, TextLocalized } from '../styled/Styled';

export const BusinessCaseItem = ({ item }) => {
    const { language } = useContext(LanguageContext);
    const [showList, setShowList] = useState(false);

    const showInfo = (e) => {
        e.stopPropagation();
        setShowList(!showList);
    }

    const openUrl = (e) => {
        e.stopPropagation();
        window.open(item.url, "_blank");
    }

    return <Container width="12" px="4">
        <Card flex shadowOn width="12" align="stretch" color="color-white">
            <Button onClick={showInfo} width="12" pv="20" ph="16" fontSize="18px" fontWeight="bold" children={`ID - ${item.id_business_case}`} />
            {
                showList ?
                    <Fragment>
                        <Container width="12" height="1px" color="color-gray" />
                        <ContainerFlex width="12" justify="center" pv="20" ph="16">
                            <Container mb="16" fontSize="15px" fontWeight="bold" children={`${getTextLang(item, 'description', language)}`} />
                            <ContainerFlex width="12" align="align-stretch" justify="space-between">
                                <ContainerFlex>
                                    <Card pv="4" ph="8" border="solid" fontSize="12px" fontColor="color-white" color="color-black" borderColor="color-black" borderWidth="2px" children={`${item.company}`} />
                                    <Card mh="8" pv="4" ph="8" border="solid" fontSize="12px" fontColor="color-white" color="color-gray-home" borderColor="color-gray-home" borderWidth="2px" children={`${getTextLang(item, 'country', language)}`} />
                                </ContainerFlex>

                                {
                                    item.url ?
                                        <Button onClick={openUrl} flex pv="4" ph="8" align="center" border="solid" fontSize="12px" fontColor="color-white" color="color-blue" hoverColor="color-blue-light" borderWidth="2px" children={<TextLocalized children={"actionOpenUrl"} />} />
                                        : null
                                }
                            </ContainerFlex>
                        </ContainerFlex>
                    </Fragment>
                    : null
            }
        </Card>
    </Container>
}


export const ModalBusinessCase = ({ items }) => {
    return <ContainerFlex width="6">
        {
            items.map((business, index) => <BusinessCaseItem key={index} item={business} />)
        }
    </ContainerFlex>
}

export const ModalViewDefault = ({ item }) => <Card flex shadowOn width="10" color="color-white">

    <Card flex pv="16" justify="center" align="center" fontColor="color-white" width="12" color="color-primary" radiusbr="0px" radiusbl="0px">
        <Icon path={mdiOfficeBuilding} size={2} />
    </Card>

    <ContainerFlex width="wrap" pv="16" direction="column" justify="space-between">
        <Container width="12">
            <Container width="12" ph="16">
                <Container fontWeight="bold" fontSize="32px" children={item.organization} />
                <Container fontSize="14px" fontColor="color-black" children={item.create_at} />
            </Container>


            <ContainerFlex width="12" ph="16" justify="flex-end">
                <Button mv="8" ph="16" pv="8" color="color-black" hoverColor="color-primary-dark" radius="30px" fontSize="17px" fontWeight="normal" fontColor="color-white" textAlign="center">
                    <TextLocalized children="actionClose" />
                </Button>
            </ContainerFlex>
        </Container>
    </ContainerFlex>
</Card>

const ModalView = ({ children }) => {
    const { state, dispatch } = useContext(UserContext);
    const _hiddeModal = () => dispatch({ action: UserAction.showModal, data: { ...state.notification, show: false } });

    return <ContainerFlex onClick={_hiddeModal} justify="center" align="center" position="fixed" top="0" elevation="100"
        width="12" height="100%" maxheight="100%" overflowY="scroll" color="color-gray-a50"
        children={children} />;
};

export default ModalView;