import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Card, Container, ContainerFlex, SelectItemInput, TextLocalized } from '../components/styled/Styled';

import { getTextLang, LanguageContext } from '../components/providers/LanguageProvider';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import DimensionCaseItem from '../components/DimensionCaseItem';

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const BussinessCasesList = () => {
    const [formData, setFormData] = useState({ block: "", level: "", step: "", dimension: "" });
    const formRef = useRef(formData);

    const [blocks, setBlocks] = useState([]);
    const [levels, setLevels] = useState([]);
    const [steps, setSteps] = useState([]);
    const [dimensions, setDimensions] = useState([])

    const [items, setItems] = useState([]);
    const { language, dictionary } = useContext(LanguageContext);

    const query = useQuery();

    useEffect(() => {
        const level = query.get('level') ?? "";
        const block = query.get('block') ?? "";
        const step = query.get('step') ?? "";

        //Get blocks
        HttpConnection(HttpOperations.block,
            response => {
                if (block !== "") {
                    response.item.forEach(item => {
                        if (item.id_block == block) {
                            formRef.current = { ...formRef.current, block: getTextLang(item, 'name', language) };
                            console.log(formRef.current)
                            setFormData({ ...formRef.current });
                        }
                    })
                }
                setBlocks(response.item);
            }, error => console.log(error), HttpMethod.get)

        //Get levels
        HttpConnection(HttpOperations.level,
            response => {
                if (level !== "") {
                    response.item.forEach(item => {
                        if (item.id_level == level) {
                            formRef.current = { ...formRef.current, level: getTextLang(item, 'name', language) };
                            setFormData({ ...formRef.current });
                        }
                    })
                }
                setLevels(response.item);
            }, error => console.log(error), HttpMethod.get)

        //Get steps
        HttpConnection(HttpOperations.step,
            response => {
                if (step !== "") {
                    response.item.forEach(item => {
                        if (item.id_step == step) {
                            formRef.current = { ...formRef.current, step: getTextLang(item, 'name', language) };
                            setFormData({ ...formRef.current });
                        }
                    })
                }
                setSteps(response.item);
            }, error => console.log(error), HttpMethod.get)

        //Get dimensions
        HttpConnection(HttpOperations.dimension,
            response => {
                setDimensions(response.item)
            }, error => console.log(error), HttpMethod.get);

        //Get good practices
        HttpConnection(`${HttpOperations.dimension}/goodpractices?level=${level}&block=${block}&step=${step}`,
            response => {
                setItems(response.item)
            }, error => console.log(error), HttpMethod.get);
    }, [query])

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const submitFilter = () => {
        let level = "";
        let block = "";
        let dimension = "";
        let step = "";


        if (formData.level !== "") {
            levels.forEach(item => {
                if (getTextLang(item, 'name', language) === formData.level)
                    level = item.id_level;
            })
        }

        if (formData.block !== "") {
            blocks.forEach(item => {
                if (getTextLang(item, 'name', language) === formData.block)
                    block = item.id_block;
            })
        }

        if (formData.step !== "") {
            steps.forEach(item => {
                if (getTextLang(item, 'name', language) === formData.step)
                    step = item.id_step;
            })
        }

        if (formData.dimension !== "") {
            dimensions.forEach(item => {
                if (getTextLang(item, 'name', language) === formData.dimension)
                    dimension = item.id_dimension;
            })
        }

        HttpConnection(`${HttpOperations.dimension}/goodpractices?level=${level}&block=${block}&dimension=${dimension}&step=${step}`,
            response => {
                setItems(response.item)
            }, error => console.log(error), HttpMethod.get);
    }

    return (
        <ContainerFlex width="12" justify="center" px="32" color="color-gray" minheight="100vh">
            <Container width="12" lg="8">
                <Card width="12" shadowOn flex mv="16" align="center" color="color-blue-drawer" fontColor="color-white">
                    <Container width="12" ph="16" mt="16" fontSize="18px" fontWeight="300" children={<TextLocalized children="msgHeader1" />} />
                    <Container width="12" mh="16" mt="8" mb="16" fontSize="12px" children={<TextLocalized children="msgGoodPracticesSubtitle" />} />

                    <Card flex ph="16" color="color-white" width="12" align="stretch" radiustl="0" radiustr="0">
                        <SelectItemInput onChange={onChange} placeholder={dictionary["msgBusinessBlock"]} title={"msgBusinessBlock"} options={blocks.map(item => getTextLang(item, 'name', language))} inputmv="16" inputmr="8" titleph="16" name="block" value={formData.block} />
                        <SelectItemInput onChange={onChange} placeholder={dictionary["msgLevel"]} title={"msgLevel"} options={levels.map(item => getTextLang(item, 'name', language))} inputmv="16" inputmr="8" titleph="16" name="level" value={formData.level} />
                        <SelectItemInput onChange={onChange} placeholder={dictionary["msgStep"]} title={"msgStep"} options={steps.map(item => getTextLang(item, 'name', language))} inputmv="16" inputmr="8" titleph="16" name="step" value={formData.step} />
                        <SelectItemInput onChange={onChange} placeholder={dictionary["msgDimension"]} title={"msgDimension"} options={dimensions.map(item => getTextLang(item, 'name', language))} inputmv="16" inputmr="8" titleph="16" name="dimension" value={formData.dimension} />
                        <Button onClick={submitFilter} flex align="center" mv="16" ph="32" pv="8" color="color-blue" hoverColor="color-blue-light"
                            fontColor="color-white" fontWeight="bold" textAlign="center"
                            children={<TextLocalized children="actionFilter" />} />
                    </Card>
                </Card>

                <ContainerFlex width="12" mt="32" align="stretch" children={items.map((item) => <DimensionCaseItem key={item.id_dimension} item={item} />)} />
            </Container>
        </ContainerFlex>
    );
};

export default BussinessCasesList;