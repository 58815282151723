import React, { useEffect, useState } from 'react';
import { Card, Container, ContainerFlex, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import BlockLevel from '../components/BlockLevel';
import BlockLevelOne from '../components/BlockLevelOne';

const ImprovementView = () => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        HttpConnection(`${HttpOperations.level}/score`,
            response => {
                setItems(response.item)
            }, error => console.log(error), HttpMethod.get);
    }, [])

    return (
        <ContainerFlex width="12" justify="center" px="32" >
            <Container width="12" lg="8">
                <Card width="12" shadowOn flex mv="16" px="16" align="center" color="color-blue-drawer" fontColor="color-white">
                    <Container width="12" fontSize="18px" fontWeight="300" radiusbl="0px" radiusbr="0px" children={<TextLocalized children="msgImprovement" />} />
                    <Container mt="8" fontSize="12px" children={<TextLocalized children="msgImprovementMainSubtitle" />} />
                </Card>

                <Container width="12" mt="32" fontSize="18px" fontWeight="300" fontColor="color-black" children={<TextLocalized children="msgLevels" />} />
                <Card width="12" height="5px" mv="16" color="color-blue" radius="2px" />
                <ContainerFlex width="12" align="stretch" children={items.map((item) => item.id_level === 1 ? <BlockLevelOne key={item.id_level} item={item} /> : <BlockLevel key={item.id_level} item={item} />)} />

            </Container>
        </ContainerFlex>
    );
};

export default ImprovementView;