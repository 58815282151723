import sec_one from '../../img/section/sec_one.svg';
import sec_two from '../../img/section/sec_two.svg';
import sec_three from '../../img/section/sec_three.svg';
import sec_four from '../../img/section/sec_four.svg';
import sec_five from '../../img/section/sec_five.svg';
import sec_six from '../../img/section/sec_six.svg';
import sec_seven from '../../img/section/sec_seven.svg';
import sec_eight from '../../img/section/sec_eight.svg';
import sec_nine from '../../img/section/sec_nine.svg';
import sec_ten from '../../img/section/sec_ten.svg';
import sec_eleven from '../../img/section/sec_eleven.svg';

import level_one from '../../img/level/level_one.png';
import level_two from '../../img/level/level_two.png';
import level_three from '../../img/level/level_three.png';
import level_four from '../../img/level/level_four.png';
import level_five from '../../img/level/level_five.png';


export const getProgressData = (value) => {
    if (value > 75 ) {
        return {text: "msgProgress4", color: "color-green-dark"};
    } else if (value > 50) {
        return {text: "msgProgress3", color: "color-green"};
    } else if (value > 25) {
        return {text: "msgProgress2", color: "color-green-light"};
    } else if (value > 0) {
        return {text: "msgProgress1", color: "color-orange"};
    } else {
        return {text: "msgProgress0", color: "color-red"};
    }
}


export const getBlockImage = (item) => {
    switch (item.id_block) {
        case 2:
            return sec_two;
        case 3:
            return sec_three;
        case 4:
            return sec_four;
        case 5:
            return sec_five;
        case 6:
            return sec_six;
        case 7:
            return sec_seven;
        case 8:
            return sec_eight;
        case 9:
            return sec_nine;
        case 10:
            return sec_ten;
        case 11:
            return sec_eleven;
        default:
            return sec_one;
    }
}

export const getLevelImage = (item) => {
    switch (item.id_level) {
        case 2:
            return level_two;
        case 3:
            return level_three;
        case 4:
            return level_four;
        case 5:
            return level_five;
        default:
            return level_one;
    }
}