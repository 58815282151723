const cs = {
    actionAlready: "Máte již účet? Přihlásit se",
    actionExit: "Odhlášení",
    actionLogin: "PŘIHLÁSIT SE",
    actionRegister: "ZAPSAT SE",
    actionSave: "ULOŽIT",
    actionStart: "ZAČÍT",
    actionContinue: "POKRAČOVAT",
    actionReset: "RESETOVAT VYHODNOCENÍ",
    actionDoDiagnost: "ZOBRAZIT DIAGNOSTIKU",
    actionYes: "Ano",
    actionNo: "Ne",
    actionNext: "DALŠÍ",
    actionBusinessCase: "ZOBRAZIT OBCHODNÍ PŘÍPAD",
    actionBusinessCases: "ZOBRAZIT OBCHODNÍ PŘÍPADY",
    actionOpenUrl: "OTEVŘÍT URL",
    actionFilter: "FILTR",
    
    /** HOME */
    msgHomeTitleOne: "Změřte a zvyšte pozitivní dopad svého nábytkářského podniku",
    msgHomeSubTitle: "Online nástroj FurnCSR vyhodnotí váš současný pokrok v oblasti CSR na základě ekonomicko-organizačních, společenských a environmentálních dopadů",
    
    msgHomeSection2: "Požadavky na CSR stále rostou.</br>Jste připraveni?",
    msgHomeSection2Text: "<ul><li>Zodpovězte otázky na vaše každodenní podnikové činnosti podle standardů týkajících se hlavních dopadů.</li><li>Získejte skóre ze samohodnocení úrovně zralosti.</li><li>Identifikujte osvědčené postupy a posuňte svůj podnik na vyšší úroveň.</li><li>Učte se ze skutečných praktických příkladů z nábytkářského průmyslu, abyste mohli neustále zvyšovat svůj pozitivní dopad.</li></ul>",
    msgHomeSection3: "Partneři",
    msgHomeSection2Btn: "Zahajte své hodnocení",
    msgHomeSection3Btn: "KONTAKT",
    
    /** HEADER */
    msgHeader1: "Osvědčené postupy",
    msgHeader4: "Přihlášení",
    
    /** DRAWER */
    msgCompany: "SPOLEČNOST",
    msgDrawer1: "Vyhodnocení FurnCSR",
    msgDrawer2: "Zpráva o výkonnosti",
    msgDrawer3: "Zpráva o zlepšování",
    msgDrawer4: "Sada osvědčených postupů",
    msgDrawer5: "O úrovních FurnCSR",
    msgDrawer6: "Nastavení",
    msgDrawer7: "Odhlášení",
    msgDrawer8: "Ohodnoťte svou zkušenost",
    
    /** LOGIN */
    msgWelcome: "Vítejte",
    msgWelcomeSubtitle: "Přihlaste se prosím, abyste mohli pokračovat",
    
    /** REGISTER */
    msgGetStarted: "Začněte",
    msgGetStartedSubtitle: "Tento nástroj ke správě dopadů je bezplatný a důvěrný",
    
    msgFirstname: "Jméno",
    msgLastname: "Příjmení",
    msgEmail: "E-mail",
    msgPassword: "Heslo",
    msgConfirmPassword: "Potvrdit heslo",
    msgCompanyUser: "Společnost nebo uživatelské jméno",
    
    msgNotRegister: "Ještě nejste zaregistrováni? <b>Vytvořte si svůj účet zde</b>",
    msgAlreadyRegister: "Už jste zaregistrováni? <b>Přihlaste se zde</b>",
    
    /** HOME TOOL */
    msgClickImage: "K rozšíření obchodního modelu CANVAS FurnCSR klikněte.",
    
    msgArea: "OBLAST",
    msgProgress: "PŘEČTENÉ DOTAZY",
    
    msgAssessmentAreas: "Oblasti dopadů FurnCSR",
    msgAssessmentDesc: "Všechny otázky v nástroji FurnCSR jsou utříděny podle hlavních podnikových bloků společensky odpovědného nábytkářského podniku. Toto je obchodní model CANVAS FurnCSR. Výroba a interní operace jsou v modelu zobrazeny vlevo a nabídka a externí zkušenosti vpravo. K ohodnocení podnikových aktivit orientovaných na CSR v jednotlivých blocích používáme koncepci tří vrstev (ekonomicko-organizační, společenské a environmentální). ",
    
    msgAssessment: "Vyhodnocení FurnCSR",
    msgNotApply: "Označte odpovědi, které platí o současném vývoji ve vašem podniku. Pokud se žádná nehodí, neoznačujte žádnou možnost.",
    msgMarkImportant: "Označte jako důležité",
    msgComment: "Poznámky",
    msgCommentHolder: "Sem můžete napsat jakékoli související informace nebo vlastní komentář.",
    
    /** PERFORMANCE REPORT */
    msgPerformance: "Zpráva o výkonnosti",
    msgPerformanceSubtitle: "Podívejte se na skóre ze samohodnocení úrovně zralosti, pro každý podnikový blok zvlášť a celkové výsledky.",
    msgCompanyLevel: "Úroveň vašeho podniku",
    msgBlockArea: "OBLAST BLOKŮ",
    
    /** IMPROVEMENT REPORT */
    msgImprovement: "Zpráva o zlepšování",
    msgImprovementMainSubtitle: "Zjistěte, jak se můžete neustále zlepšovat. Identifikujte další osvědčené postupy a obchodní případy a posuňte svůj podnik na vyšší úroveň.</br></br>Začněte číst ty na spodních úrovních a inspirujte se jimi, poté se podívejte na ty na vyšších úrovních a pokuste se je postupně zavádět. Nezapomeňte, že postupy na úrovni 1 a 2 vyžadují plnění legislativy a právních předpisů. Jejich dodržování je tedy zákonná povinnost, a proto je velmi důležité tyto požadavky plnit.",
    msgImprovementSubtitle: "Zjistěte, jak se můžete neustále zlepšovat. Identifikujte další osvědčené postupy a obchodní případy a posuňte svůj podnik na vyšší úroveň.",
    msgLevels: "Úrovně",
    msgActionsToImplement: "Opatření, které je nutno zavést:",
    msgBaselineScore: "Moje základní skóre",
    msgBusinessCaseEmpty: "Blahopřejeme! Hlavní osvědčené postupy z této kategorie jste už zavedli.",
    
    msgCat1: "EKONOMICKÝ A ORGANIZAČNÍ",
    msgCat2: "SPOLEČENSKÝ",
    msgCat3: "ENVIRONMENTÁLNÍ",
    
    /** GOOD PRACTICES COLLECTION */
    msgGoodPracticesSubtitle: "Projděte a prostudujte si kompletní sadu osvědčených postupů a obchodních případů projektu FurnCSR.",
    msgBusinessBlock: "Podnikový blok",
    msgLevel: "Úroveň",
    msgDimension: "Rozměr",
    msgStep: "Step",
    
    /** SETTINGS */
    msgUserInfo: "Údaje uživatele",
    msgUserCredentials: "Přihlašovací údaje uživatele",
    
    /** MODAL */
    msgAreSure: "Jste si jisti?",
    
    msgSuccess: "Aktualizace dat proběhla úspěšně",
    msgDeleteSuccess: "Odstranění dat proběhlo úspěšně",
    
    /** ERROR */
    errorOblData: "Vyplňte všechna povinná pole",
    errorEmailFormat: "Chyba formátu e-mailu",
    errorUserIncorrect: "Nesprávný e-mail/heslo",
    errorSend: "Chyba v komunikaci, vyčkejte prosím a zkuste to znovu později",
    errorLength: "Byl přesažen povolený počet znaků",
    errorPassSame: "Hesla si neodpovídají",
    
    /** FOOTER */
    msgFooterContact: "KONTAKT",
    msgFooterLinkedIn: "LINKEDIN",
    msgFooterTwitter: "TWITTER",
    
    msgFooterGr: "Tento projekt byl financován Evropskou komisí v rámci výzvy: Podpora společenského dialogu VP/2020/001",
    msgCopyright: "Copyright&copy;2023 AMBIT. Všechna práva vyhrazena.",
    
    msgFooterLegal: "Právní upozornění",
    msgFooterPrivacy: "Ochrana soukromí",
    msgFooterCookies: "Zásady používání souborů cookie",
    
    /** PROGRESS VALUES */
    msgProgress0: "Oblasti ke zlepšení",
    msgProgress1: "Dodržování předpisů",
    msgProgress2: "Zlepšení v dodržování předpisů",
    msgProgress3: "Plnění očekávání",
    msgProgress4: "Angažovanost",
    
    /** COMPANY SIZES */
    msgCompanySize: "Velikost společnosti",
    msgCompanySize1: "Méně než 10 zaměstnanců",
    msgCompanySize2: "10–30 zaměstnanců",
    msgCompanySize3: "30–50 zaměstnanců",
    msgCompanySize4: "Více než 50 zaměstnanců",
    
    /** PRODUCT TYPES */
    msgProductType: "Typ výrobků, které vyrábíte",
    msgProductType1: "Čalounění",
    msgProductType2: "Matrace",
    msgProductType3: "Základový nábytek pro čalounění, pohovky, základové rámy postelí",
    msgProductType4: "Subdodavatel (rámy, obrábění, lakování atd.)",
    msgProductType5: "Služby (specializovaná logistika atd.)",
    msgProductType6: "Deskový nábytek (melamin, dýha, lak atd.)",
    msgProductType7: "Dřevěný nábytek",
    msgProductType8: "Židle",
    msgProductType9: "Kancelářský nábytek",
    msgProductType10: "Nábytek do veřejných prostor a k veřejnému využití",
    msgProductType11: "Dveře, okna a tesařské výrobky",
    msgProductType12: "Úprava zařízení na míru (obchody, knihovny, restaurace, profesionální firmy atd.)",
    msgProductType13: "Osvětlení",
    msgProductType14: "Obklady a podlahy",
    msgProductType15: "Textil",
    msgProductType16: "Dekorace",

    /** ABOUT */
    msgAboutSec1Title: "Úvod do modelu Návod k postupu FurnCSR",
    msgAboutSec1Text1:"Díky Návodu k postupu (Progress Guideline), který ve FurnCSR představujeme, můžeme přesně označit praktiky CSR v podniku a zjistit existující nedostatky pomocí prázdných políček u důležitých postupů.</br></br>Tento Návod k postupu, vypracovaný na metodologických základech CSR, globálně a strukturovaně ukazuje pozici, v níž je společnost zkonsolidovaná, a cestu, kterou musí v řízení CSR ještě podniknout.</br></br>Model se zakládá na pěti úrovních zdvojených ve dvou sloupcích, s deseti stupni, od oblastí zlepšení k nejvyšší úrovni angažovanosti. Základní model zobrazujeme ve zjednodušené formě s cílem poskytnout jiné návrhy osvědčených postupů, lišící se od předchozích (tj. Kapitola 4) v tom smyslu, že umožní pokroky v integrovaném rozvoji modelu CSR.</br></br>Na následujících obrázcích jsou znázorněny jednotlivé úrovně a stupně vyspělosti podniku v oblasti CSR.</br></br>Dole v prvním sloupci (pole 0) vidíme různé stupně od (ne)dodržování právních předpisů až po nejvyšší úroveň angažovanosti (pole 9). Dole v pravém sloupci, zobrazujícím větší proaktivitu a základy jedinečné metodologie CSR, je vidět postupné začleňování zainteresovaných skupin a pokroky v logice sociálně odpovědného podnikání. CSR požaduje napravovat, zajišťovat, rozumět, mít odpovědnost a vytvářet sdílenou hodnotu více, než přísluší internímu logickému řízení. Nestačí jen dělat věc s nejlepším úmyslem, musíte vytvářet vliv, autenticky a s důvěrou zainteresovaných stran.",
    msgAboutSec1Text2: "Jde o cestu směrem k dokonalosti, a nacházíte-li se uprostřed, může to v nábytkářském průmyslu znamenat velkou hodnotu. Je důležité nenechávat témata ve spodních oblastech a mít vizi, jak postupovat kupředu nejen začleňováním osvědčených postupů uvedených v předchozí kapitole, ale i těch níže, nacházejících se ve vyšších úrovních tohoto modelu. Dále může být návod k postupu aplikován na každou ze tří rozměrů řízení CSR podle jejich specifických vlastností. Je však vždy nutno zachovat stejnou cestu, to totiž umožňuje pochopit vývoj ohledně stupně vyspělosti CSR v nábytkářském podniku.",

    msgAboutLevel1Title: "Oblasti ke zlepšení (krok 0 a 1)",
    msgAboutLevel1Text: "Tato úroveň odpovídá oblastem ke zlepšení a znamená, že dochází k závažnému nebo různému neplnění předpisů, a to jak právních, tak co se týče souvisejících závazků, popřípadě je jejich plnění stále ve fázi nápravy.",

    msgAboutLevel2Title: "Dodržování předpisů (krok 2 a 3)",
    msgAboutLevel2Text: "Tato úroveň odpovídá dodržování předpisů a znamená, že podnik plní a zajišťuje dodržování svých povinností, ať už zákonných, nebo svých dobrovolných závazků. Pro organizaci angažující se v CSR se plnění zákonných předpisů nevztahuje pouze na doslovný text, ale i na snahu, kterou regulační úřad vyžaduje od společnosti k zabránění porušování zákona. Na úrovni dodržování nařízení jsou specifikovány postupy k dodržování hlavních právních požadavků ze strany průmyslu, protože bez toho by nebylo možné začít ke společenské odpovědnosti přistupovat.",

    msgAboutLevel3Title: "Lepší dodržování předpisů (krok 4 a 5)",
    msgAboutLevel3Text: "Tato úroveň odpovídá lepšímu dodržování regulačních předpisů a znamená, že podnik zavedením osvědčených postupů dělá něco navíc, než jen plní své zákonné povinnosti. V organizaci zapojené do CSR probíhají konverzace se zainteresovanými stranami, k porozumění samotného kontextu udržitelnosti.",

    msgAboutLevel4Title: "Plnění očekávání (krok 6 a 7)",
    msgAboutLevel4Text: "Tato úroveň odpovídá spokojenosti ohledně očekávání a znamená, že očekávání zainteresovaných stran je plněno prostřednictvím systematizovaného řízení CSR (plánování, opatření, začleňování do procesů a postupů, hodnocení, neustálé zlepšování atd.). Společnost angažovaná v CSR uplatňuje vůči zainteresovaným stranám transparentní odpovědnost.",

    msgAboutLevel5Title: "Angažovanost (krok 8 a 9)",
    msgAboutLevel5Text: "Tato úroveň odpovídá závazkům a znamená, že se podnik řídí angažovaně, s povědomím a vedením, které je explicitní, trvalé, komplexní, spojené se strategickým plánováním a vyšším cílem a orientované na tvorbu sdílené hodnoty se stakeholdery.",

    /* COUNTRIES */
    msgCountry: "Země",
    msgAfghanistan: "Afghánistán",
    msgAlbania: "Albánie",
    msgAlgeria: "Alžírsko",
    msgAmericanSamoa: "Americká Samoa",
    msgAndorra: "Andorra",
    msgAngola: "Angola",
    msgAnguilla: "Anguilla",
    msgAntarctica: "Antarktida",
    msgAntiguaandBarbuda: "Antigua a Barbuda",
    msgArgentina: "Argentina",
    msgArmenia: "Arménie",
    msgAruba: "Aruba",
    msgAustralia: "Austrálie",
    msgAustria: "Rakousko",
    msgAzerbaijan: "Ázerbajdžán",
    msgBahamas: "Bahamy",
    msgBahrain: "Bahrajn",
    msgBangladesh: "Bangladéš",
    msgBarbados: "Barbados",
    msgBelarus: "Bělorusko",
    msgBelgium: "Belgie",
    msgBelize: "Belize",
    msgBenin: "Benin",
    msgBermuda: "Bermudy",
    msgBhutan: "Bhútán",
    msgBolivia: "Bolívie",
    msgBosniaHerzegovina: "Bosna a Hercegovina",
    msgBotswana: "Botswana",
    msgBouvetIsland: "Bouvetův ostrov",
    msgBrazil: "Brazílie",
    msgBritishIndian: "Britské indickooceánské území",
    msgBrunei: "Brunej",
    msgBulgaria: "Bulharsko",
    msgBurkinaFaso: "Burkina Faso",
    msgBurundi: "Burundi",
    msgCambodia: "Kambodža",
    msgCameroon: "Kamerun",
    msgCanada: "Kanada",
    msgCapeVerde: "Kapverdy",
    msgCaymanIslands: "Kajmanské ostrovy",
    msgCentralAfrican: "Středoafrická republika",
    msgChad: "Čad",
    msgChile: "Chile",
    msgChina: "Čína",
    msgChristmasIsland: "Vánoční ostrov",
    msgCocosIslands: "Kokosové (Keelingovy) ostrovy",
    msgColombia: "Kolumbie",
    msgComoros: "Komory",
    msgCongo: "Kongo",
    msgRepublicCongo: "Demokratická republika Kongo",
    msgCookIslands: "Cookovy ostrovy",
    msgCostaRica: "Kostarika",
    msgIvoryCoast: "Pobřeží slonoviny",
    msgCroatia: "Chorvatsko",
    msgCuba: "Kuba",
    msgCyprus: "Kypr",
    msgCzechRepublic: "Česká republika",
    msgDenmark: "Dánsko",
    msgDjibouti: "Džibutsko",
    msgDominica: "Dominika",
    msgDominicanRepublic: "Dominikánská republika",
    msgEastTimor: "Východní Timor",
    msgEcuador: "Ekvádor",
    msgEgypt: "Egypt",
    msgEngland: "Anglie",
    msgElSalvador: "El Salvador",
    msgEquatorialGuinea: "Rovníková Guinea",
    msgEritrea: "Eritrea",
    msgEstonia: "Estonsko",
    msgEthiopia: "Etiopie",
    msgFalklandIslands: "Falklandy",
    msgFaroeIslands: "Faerské ostrovy",
    msgFijiIslands: "ostrovy Fidži",
    msgFinland: "Finsko",
    msgFrance: "Francie",
    msgFrenchGuiana: "Francouzská Guyana",
    msgFrenchPolynesia: "Francouzská Polynésie",
    msgFrenchSouthern: "Francouzská jižní území",
    msgGabon: "Gabon",
    msgGambia: "Gambie",
    msgGeorgia: "Gruzie",
    msgGermany: "Německo",
    msgGhana: "Ghana",
    msgGibraltar: "Gibraltar",
    msgGreece: "Řecko",
    msgGreenland: "Grónsko",
    msgGrenada: "Grenada",
    msgGuadeloupe: "Guadeloupe",
    msgGuam: "Guam",
    msgGuatemala: "Guatemala",
    msgGuernsey: "Guernsey",
    msgGuinea: "Guinea",
    msgGuineaBissau: "Guinea-Bissau",
    msgGuyana: "Guyana",
    msgHaiti: "Haiti",
    msgHeardMcDonald: "Heardův ostrov a McDonaldovy ostrovy",
    msgHolySee: "Svatý stolec (Vatikánský městský stát)",
    msgHonduras: "Honduras",
    msgHongKong: "Hongkong",
    msgHungary: "Maďarsko",
    msgIceland: "Island",
    msgIndia: "Indie",
    msgIndonesia: "Indonésie",
    msgIran: "Írán",
    msgIraq: "Irák",
    msgIreland: "Irsko",
    msgIsrael: "Izrael",
    msgIsleMan: "Ostrov Man",
    msgItaly: "Itálie",
    msgJamaica: "Jamaica",
    msgJapan: "Japonsko",
    msgJersey: "Trikot",
    msgJordan: "Jordán",
    msgKazakhstan: "Kazachstán",
    msgKenya: "Keňa",
    msgKiribati: "Kiribati",
    msgKuwait: "Kuvajt",
    msgKyrgyzstan: "Kyrgyzstán",
    msgLaos: "Laos",
    msgLatvia: "Lotyšsko",
    msgLebanon: "Libanon",
    msgLesotho: "Lesotho",
    msgLiberia: "Libérie",
    msgLibyanJamahiriya: "Libyjská arabská džamahiríja",
    msgLiechtenstein: "Lichtenštejnsko",
    msgLithuania: "Litva",
    msgLuxembourg: "Lucembursko",
    msgMacao: "Macao",
    msgNorthMacedonia: "Severní Makedonie",
    msgMadagascar: "Madagaskar",
    msgMalawi: "Malawi",
    msgMalaysia: "Malajsie",
    msgMaldives: "Maledivy",
    msgMali: "Mali",
    msgMalta: "Malta",
    msgMarshallIslands: "Marshallovy ostrovy",
    msgMartinique: "Martinik",
    msgMauritania: "Mauritánie",
    msgMauritius: "Mauricius",
    msgMayotte: "Mayotte",
    msgMexico: "Mexiko",
    msgMicronesia: "Mikronésie, Federativní státy",
    msgMoldova: "Moldavsko",
    msgMonaco: "Monako",
    msgMongolia: "Mongolsko",
    msgMontserrat: "Montserrat",
    msgMontenegro: "Černá Hora",
    msgMorocco: "Maroko",
    msgMozambique: "Mosambik",
    msgMyanmar: "Myanmar",
    msgNamibia: "Namibie",
    msgNauru: "Nauru",
    msgNepal: "Nepál",
    msgNetherlands: "Holandsko",
    msgNetherlandsAntilles: "Nizozemské Antily",
    msgNewCaledonia: "Nová Kaledonie",
    msgNewZealand: "Nový Zéland",
    msgNicaragua: "Nikaragua",
    msgNiger: "Niger",
    msgNigeria: "Nigérie",
    msgNiue: "Niue",
    msgNorfolkIsland: "Ostrov Norfolk",
    msgNorthKorea: "Severní Korea",
    msgNorthernIreland: "Severní Irsko",
    msgNorthernMariana: "Severní Mariany",
    msgNorway: "Norsko",
    msgOman: "Omán",
    msgPakistan: "Pákistán",
    msgPalau: "Palau",
    msgPalestine: "Palestina",
    msgPanama: "Panama",
    msgPapua: "Papua-Nová Guinea",
    msgParaguay: "Paraguay",
    msgPeru: "Peru",
    msgPhilippines: "Filipíny",
    msgPitcairn: "Pitcairn",
    msgPoland: "Polsko",
    msgPortugal: "Portugalsko",
    msgPuertoRico: "Portoriko",
    msgQatar: "Katar",
    msgReunion: "Shledání",
    msgRomania: "Rumunsko",
    msgRussianFederation: "Ruská Federace",
    msgRwanda: "Rwanda",
    msgSaintHelena: "Svatá Helena",
    msgSaintKitts: "Svatý Kryštof a Nevis",
    msgSaintLucia: "Svatá Lucie",
    msgSaintPierre: "Saint Pierre a Miquelon",
    msgSaintVincent: "Svatý Vincent a Grenadiny",
    msgSamoa: "Samoa",
    msgSanMarino: "San Marino",
    msgSaoTome: "Svatý Tomáš a Princův ostrov",
    msgSaudiArabia: "Saudská arábie",
    msgScotland: "Skotsko",
    msgSenegal: "Senegal",
    msgSerbia: "Srbsko",
    msgSeychelles: "Seychely",
    msgSierraLeone: "Sierra Leone",
    msgSingapore: "Singapur",
    msgSlovakia: "Slovensko",
    msgSlovenia: "Slovinsko",
    msgSolomonIslands: "Solomonovy ostrovy",
    msgSomalia: "Somálsko",
    msgSouthAfrica: "Jižní Afrika",
    msgSouthSandwich: "Jižní Georgie a Jižní Sandwichovy ostrovy",
    msgSouthKorea: "Jižní Korea",
    msgSouthSudan: "jižní Súdán",
    msgSpain: "Španělsko",
    msgSriLanka: "Srí Lanka",
    msgSudan: "Súdán",
    msgSuriname: "Surinam",
    msgSvalbardJan: "Svalbard a Jan Mayen",
    msgSwaziland: "Svazijsko",
    msgSweden: "Švédsko",
    msgSwitzerland: "Švýcarsko",
    msgSyria: "Sýrie",
    msgTajikistan: "Tádžikistán",
    msgTanzania: "Tanzanie",
    msgThailand: "Thajsko",
    msgTimorLeste: "Východní Timor",
    msgTogo: "Jít",
    msgTokelau: "Tokelau",
    msgTonga: "Tonga",
    msgTrinidadTobago: "Trinidad a Tobago",
    msgTunisia: "Tunisko",
    msgTurkey: "krocan",
    msgTurkmenistan: "Turkmenistán",
    msgTurksCaicos: "ostrovy Turks a Cacois",
    msgTuvalu: "Tuvalu",
    msgUganda: "Uganda",
    msgUkraine: "Ukrajina",
    msgUnitedArabEmirates: "Spojené arabské emiráty",
    msgUnitedKingdom: "Spojené království",
    msgUnitedStates: "Spojené státy",
    msgUnitedStatesMinor: "Menší odlehlé ostrovy Spojených států amerických",
    msgUruguay: "Uruguay",
    msgUzbekistan: "Uzbekistán",
    msgVanuatu: "Vanuatu",
    msgVenezuela: "Venezuela",
    msgVietnam: "Vietnam",
    msgVirginIslandsBritish: "Britské Panenské ostrovy",
    msgVirginIslandsUS: "Panenské ostrovy, USA",
    msgWales: "Wales",
    msgWallisFutuna: "Wallis a Futuna",
    msgWesternSahara: "západní Sahara",
    msgYemen: "Jemen",
    msgZambia: "Zambie",
    msgZimbabwe: "Zimbabwe",
}

export default cs;