const fr = {
    actionAlready: "Vous avez déjà un compte ? Se connecter",
    actionExit: "Se déconnecter",
    actionLogin: "SE CONNECTER",
    actionRegister: "S'INSCRIRE",
    actionSave: "SAUVEGARDER",
    actionStart: "COMMENCER",
    actionContinue: "CONTINUER",
    actionReset: "RÉINITIALISER L'ÉVALUATION",
    actionDoDiagnost: "AFFICHER LE DIAGNOSTIC",
    actionYes: "Oui",
    actionNo: "Non",
    actionNext: "SUIVANT",
    actionBusinessCase: "AFFICHER L’ANALYSE DE RENTABILISATION",
    actionBusinessCases: "AFFICHER LES ANALYSES DE RENTABILISATION",
    actionOpenUrl: "OUVRIR LE LIEN",
    actionFilter: "FILTRER",
    
    /** HOME */
    msgHomeTitleOne: "Mesurez et augmentez l'impact positif de votre entreprise de meubles",
    msgHomeSubTitle: "L'outil en ligne FurnCSR évalue vos progrès actuels en matière de RSE en se basant sur les impacts économiques et organisationnels, sociétaux et environnementaux",
    
    msgHomeSection2: "Les exigences en matière de RSE se multiplient.</br>Êtes-vous prêt ?",
    msgHomeSection2Text: "<ul> <li>Répondez aux questions sur vos activités commerciales quotidiennes selon les principales normes d'impact.</li> <li>Obtenez le score d'auto-évaluation relatif à votre niveau de maturité.</li> <li>Identifiez les bonnes pratiques qui permettront à votre entreprise de franchir le niveau supérieur.</li><li>Apprenez de vrais exemples pratiques du marché de meubles pour augmenter continuellement votre impact positif.</li></ul>",
    msgHomeSection3: "Partenaires",
    msgHomeSection2Btn: "Commencez votre évaluation",
    msgHomeSection3Btn: "CONTACT",
    
    /** HEADER */
    msgHeader1: "Bonnes pratiques",
    msgHeader4: "Connexion",
    
    /** DRAWER */
    msgCompany: "ENTREPRISE",
    msgDrawer1: "Évaluation FurnCSR",
    msgDrawer2: "Rapport de rendement",
    msgDrawer3: "Rapport d'amélioration",
    msgDrawer4: "Recueil de bonnes pratiques",
    msgDrawer5: "À propos des niveaux FurnCSR",
    msgDrawer6: "Paramètres",
    msgDrawer7: "Se déconnecter",
    msgDrawer8: "Évaluez votre expérience",
    
    /** LOGIN */
    msgWelcome: "Accueillir",
    msgWelcomeSubtitle: "Veuillez vous connecter pour continuer",
    
    /** REGISTER */
    msgGetStarted: "Commencer",
    msgGetStartedSubtitle: "Cet outil de gestion des impacts est gratuit et confidentiel",
    
    msgFirstname: "Prénom",
    msgLastname: "Nom",
    msgEmail: "E-mail",
    msgPassword: "Mot de passe",
    msgConfirmPassword: "Confirmez le mot de passe",
    msgCompanyUser: "Entreprise ou nom d'utilisateur",
    
    msgNotRegister: "Pas encore inscrit ? <b>Créez un compte ici</b>",
    msgAlreadyRegister: "Déjà inscrit ? <b>Connectez-vous ici</b>",
    
    /** HOME TOOL */
    msgClickImage: "Cliquez pour développer la matrice d'affaires du FurnCSR.",
    
    msgArea: "ZONE",
    msgProgress: "QUESTIONS VUES",
    
    msgAssessmentAreas: "Domaines d'impact du FurnCSR",
    msgAssessmentDesc: "Toutes les questions soumises dans l'outil FurnCSR sont organisées selon les principaux blocs d'activité d'une entreprise de meubles socialement responsable. Il s'agit de la matrice d'affaires du FurnCSR. La fabrication et les opérations internes sont sur le côté gauche de la matrice, tandis que l'offre et les expériences externes sont représentées sur le côté droit. Nous utilisons une approche à trois niveaux (économique/organisationnelle, sociétale et environnementale) pour évaluer les activités commerciales axées sur la RSE dans chaque bloc. ",
    
    msgAssessment: "Évaluation FurnCSR",
    msgNotApply: "Cochez les réponses qui s'appliquent au développement actuel de votre entreprise. Si aucune réponse ne s'applique, ne cochez aucune option.",
    msgMarkImportant: "Marquer comme important",
    msgComment: "Commentaires.",
    msgCommentHolder: "Vous pouvez écrire ici toutes les informations connexes ou vos propres commentaires.",
    
    /** PERFORMANCE REPORT */
    msgPerformance: "Rapport de rendement",
    msgPerformanceSubtitle: "Consultez votre score d'auto-évaluation de niveau de maturité, pour chaque bloc d'activité et un bloc global.",
    msgCompanyLevel: "Niveau de votre entreprise",
    msgBlockArea: "ZONE DE BLOC",
    
    /** IMPROVEMENT REPORT */
    msgImprovement: "Rapport d'amélioration",
    msgImprovementMainSubtitle: "Découvrez ce que vous pouvez faire pour vous améliorer en permanence. Identifiez davantage de bonnes pratiques et d'analyses de rentabilisation pour faire passer votre entreprise au niveau supérieur.</br></br>Vous pouvez commencer à lire et à vous inspirer de celles qui sont contenues dans les niveaux inférieurs, puis rechercher et essayer de mettre en œuvre celles qui se trouvent aux niveaux supérieurs de manière progressive. Rappelons que les pratiques de niveaux 1 et 2 doivent se conformer aux exigences législatives et réglementaires, leur satisfaction relève donc d’une obligation légale et donc très importante à satisfaire.",
    msgImprovementSubtitle: "Découvrez ce que vous pouvez faire pour vous améliorer en permanence. Identifiez plus de bonnes pratiques et d’analyses de rentabilisation pour faire passer votre entreprise au niveau supérieur.",
    msgLevels: "Niveaux",
    msgActionsToImplement: "Actions à mettre en œuvre :",
    msgBaselineScore: "Mon score de base",
    msgBusinessCaseEmpty: "Félicitations ! Vous avez déjà mis en place les principales bonnes pratiques incluses dans cette catégorie",
    
    msgCat1: "ÉCONOMIQUE ET ORGANISATIONNELLE",
    msgCat2: "SOCIÉTALE",
    msgCat3: "ENVIRONNEMENTALE",
    
    /** GOOD PRACTICES COLLECTION */
    msgGoodPracticesSubtitle: "Parcourez et explorez la collection complète de bonnes pratiques et d’analyses de rentabilisation du projet FurnCSR",
    msgBusinessBlock: "Bloc d'activités",
    msgLevel: "Niveau",
    msgDimension: "Dimension",
    msgStep: "Step",
    
    /** SETTINGS */
    msgUserInfo: "Informations de l'utilisateur",
    msgUserCredentials: "Informations d'identification de l'utilisateur",
    
    /** MODAL */
    msgAreSure: "Êtes-vous sûr(e) ?",
    
    msgSuccess: "Mise à jour des données réussie",
    msgDeleteSuccess: "Suppression des données réussie",
    
    /** ERROR */
    errorOblData: "Remplissez tous les champs obligatoires",
    errorEmailFormat: "Erreur de format d'e-mail",
    errorUserIncorrect: "Email/mot de passe incorrect",
    errorSend: "Erreur de communication, veuillez patienter et réessayer plus tard",
    errorLength: "Le nombre de caractères a été dépassé",
    errorPassSame: "Les mots de passe ne sont pas identiques",
    
    /** FOOTER */
    msgFooterContact: "CONTACT",
    msgFooterLinkedIn: "LINKEDIN",
    msgFooterTwitter: "TWITTER",
    
    msgFooterGr: "Ce projet a été financé par l’appel d’offres de la Commission européenne : Soutien au dialogue social VP/2020/001",
    msgCopyright: "Copyright&copy;2023 AMBIT. Tous droits réservés",
    
    msgFooterLegal: "Mention légale",
    msgFooterPrivacy: "Confidentialité",
    msgFooterCookies: "Politique relative aux cookies",
    
    /** PROGRESS VALUES */
    msgProgress0: "Domaines à améliorer",
    msgProgress1: "Conformité aux dispositions réglementaires",
    msgProgress2: "Amélioration des dispositions réglementaires",
    msgProgress3: "Réponse aux attentes",
    msgProgress4: "Engagement",
    
    /** COMPANY SIZES */
    msgCompanySize: "Taille de l'entreprise",
    msgCompanySize1: "Moins de 10 employés",
    msgCompanySize2: "10 à 30 employés",
    msgCompanySize3: "30 à 50 employés",
    msgCompanySize4: "Plus de 50 employés",
    
    /** PRODUCT TYPES */
    msgProductType: "Type de produits que vous fabriquez",
    msgProductType1: "Tapisserie",
    msgProductType2: "Matelas",
    msgProductType3: "Sommiers, canapés, sommiers",
    msgProductType4: "Sous-traitant (cadres, usinage, vernissage, etc.)",
    msgProductType5: "Services (logistique spécialisée, etc.)",
    msgProductType6: "Meubles en planches (mélamine, placage, laque, etc.)",
    msgProductType7: "Meubles en bois",
    msgProductType8: "Chaises",
    msgProductType9: "Mobilier de bureau",
    msgProductType10: "Mobilier pour espaces et usages publics",
    msgProductType11: "Portes, fenêtres et produits de menuiserie",
    msgProductType12: "Des aménagements sur mesure (boutiques, bibliothèques, restaurants, commerces professionnels, etc.)",
    msgProductType13: "Foudre",
    msgProductType14: "Revêtements et sols",
    msgProductType15: "Textile",
    msgProductType16: "Décorations",

    /** ABOUT */
    msgAboutSec1Title: "Introduction au modèle de ligne directrice de progression FurnCSR",
    msgAboutSec1Text1:"La ligne directrice de progression que nous présentons dans FurnCSR nous permet d’identifier clairement les pratiques de RSE de l’entreprise, tandis que l’absence de pratiques pertinentes dans certaines cases nous permettra de détecter tout écart à remplir.</br></br>Conçue sur la base des fondements méthodologiques de la RSE, la ligne directrice de progression montre globalement et de manière structurée la position à partir de laquelle une entreprise se consolide et la voie qu’elle doit suivre dans le cadre de la gestion de la RSE.</br></br>Le modèle est basé sur cinq niveaux qui, dupliqués dans deux colonnes, offrent 10 degrés, depuis les domaines à améliorer jusqu’au niveau maximum d’engagement. Nous montrons le modèle de base de manière simplifiée dans le but de fournir d’autres propositions de bonnes pratiques différentes des précédentes (c.-à-d. du Chapitre 4) dans le sens qu’elles permettront de progresser dans le développement intégré d’un modèle de RSE.</br></br>Les figures suivantes montrent les différents niveaux et degrés de maturité concernant la RSE.</br></br>En remontant la première colonne (case 0), on observe les différents degrés entre la (non-)conformité légale et le niveau maximum d’engagement (case 9), En remontant la colonne à droite, qui montre une plus grande proactivité et les fondements de la méthodologie unique de RSE, la présence de acteurs concernés est graduellement intégrée et la progression dans la logique de citoyenneté de l’entreprise est illustrée. La RSE demande de réparer, garantir, comprendre, se responsabiliser et créer de la valeur partagée au-delà de ce qui correspond à une gestion de logique plus interne ; il n’est pas suffisant de faire les choses avec la meilleure intention possible, il faut générer des impacts, avec authenticité et avec la confiance des acteurs concernés.",
    msgAboutSec1Text2: "C’est une voie de progression vers l’excellence et se trouver dans les positions du milieu peut déjà être très précieux pour l’entreprise d’ameublement. Il sera important de ne pas laisser de sujets dans les zones inférieures et de se créer une vision de la manière dont la progression va se faire en intégrant non seulement les bonnes pratiques indiquées dans le chapitre précédent, mais aussi celles indiquées ci-dessous, situées aux niveaux plus élevés de ce modèle. Ensuite, la ligne directrice de progression peut être appliquée à chacune des trois dimensions de la gestion de la RSE en fonction de leurs aspects spécifiques propres, conservant la même voie à tout moment, ce qui permet de mieux comprendre l’évolution du degré de maturité de la RSE dans l’entreprise d’ameublement.",

    msgAboutLevel1Title: "Domaines à améliorer (Étapes 0 et 1)",
    msgAboutLevel1Text: "Ce niveau correspond aux domaines à améliorer et implique qu’il existe une non-conformité grave ou plusieurs d’entre elles, liées à la fois au domaine légal et à celui des engagements ou qui sont toujours en phase de correction et de réparation.",

    msgAboutLevel2Title: "Conformité aux dispositions réglementaires (Étapes 2 et 3)",
    msgAboutLevel2Text: "Ce niveau correspond à la conformité aux dispositions réglementaires et signifie que l’entreprise respecte et garantit la conformité aux obligations, qu’elles soient légales ou issues des engagements volontaires mêmes. Pour une entreprise engagée envers la RSE, le respect des lois n’est pas seulement lié au texte littéral mais aussi à l’intention recherchée par le régulateur au nom de la société, celle d’éviter de violer la loi. Au niveau de la conformité aux dispositions réglementaires, les pratiques liées à la conformité aux exigences légales principales pour l’industrie sont spécifiées, car sans elles, on ne pourrait même pas commencer à parler de gestion de la RSE.",

    msgAboutLevel3Title: "Amélioration des dispositions réglementaires (Étapes 4 et 5)",
    msgAboutLevel3Text: "Ce niveau correspond à l’amélioration des dispositions réglementaires et signifie que l’entreprise va au-delà des obligations légales en termes d’implémentation des meilleures pratiques. Pour une entreprise engagée envers la RSE, de conversations avec acteurs concernés ont lieu pour comprendre le contexte de durabilité en soi.",

    msgAboutLevel4Title: "Réponse aux attentes (Étapes 6 et 7)",
    msgAboutLevel4Text: "Ce niveau correspond à la satisfaction des attentes et signifie que les attentes des acteurs concernés sont gérées par le biais d’une gestion de la RSE systématisée (planification, actions, intégration dans les processus et procédures, évaluation, amélioration continue...). Une organisation engagée envers la RSE a l’obligation de rendre compte aux acteurs concernés.",

    msgAboutLevel5Title: "Engagement (Étapes 8 et 9)",
    msgAboutLevel5Text: "Ce niveau correspond à un engagement et signifie la réalisation d’une gestion engagée, avec prise de conscience et leadership, explicite et permanente, compréhensive, liée à la panification stratégique et à une finalité plus élevée, avec une orientation vers la création de valeur partagée avec les acteurs concernés.",

    /* COUNTRIES */
    msgCountry: "Pays",
    msgAfghanistan: "Afghanistan",
    msgAlbania: "Albanie",
    msgAlgeria: "Algérie",
    msgAmericanSamoa: "Samoa américaines",
    msgAndorra: "Andorre",
    msgAngola: "Angola",
    msgAnguilla: "Anguilla",
    msgAntarctica: "Antarctique",
    msgAntiguaandBarbuda: "Antigua-et-Barbuda",
    msgArgentina: "Argentine",
    msgArmenia: "Arménie",
    msgAruba: "Aruba",
    msgAustralia: "Australie",
    msgAustria: "L'Autriche",
    msgAzerbaijan: "Azerbaïdjan",
    msgBahamas: "Bahamas",
    msgBahrain: "Bahreïn",
    msgBangladesh: "Bengladesh",
    msgBarbados: "Barbade",
    msgBelarus: "Biélorussie",
    msgBelgium: "Belgique",
    msgBelize: "Bélize",
    msgBenin: "Bénin",
    msgBermuda: "Bermudes",
    msgBhutan: "Bhoutan",
    msgBolivia: "Bolivie",
    msgBosniaHerzegovina: "Bosnie Herzégovine",
    msgBotswana: "Bostwana",
    msgBouvetIsland: "Île Bouvet",
    msgBrazil: "Brésil",
    msgBritishIndian: "Territoire britannique de l'océan Indien",
    msgBrunei: "Brunéi",
    msgBulgaria: "Bulgarie",
    msgBurkinaFaso: "Burkina Faso",
    msgBurundi: "Burundi",
    msgCambodia: "Cambodge",
    msgCameroon: "Cameroun",
    msgCanada: "Canada",
    msgCapeVerde: "Cap-Vert",
    msgCaymanIslands: "Îles Caïmans",
    msgCentralAfrican: "République centrafricaine",
    msgChad: "Tchad",
    msgChile: "Chili",
    msgChina: "Chine",
    msgChristmasIsland: "L'île de noël",
    msgCocosIslands: "Îles Cocos (Keeling)",
    msgColombia: "Colombie",
    msgComoros: "Comores",
    msgCongo: "Congo",
    msgRepublicCongo: "La République Démocratique du Congo",
    msgCookIslands: "les Îles Cook",
    msgCostaRica: "Costa Rica",
    msgIvoryCoast: "Côte d'Ivoire",
    msgCroatia: "Croatie",
    msgCuba: "Cuba",
    msgCyprus: "Chypre",
    msgCzechRepublic: "République tchèque",
    msgDenmark: "Danemark",
    msgDjibouti: "Djibouti",
    msgDominica: "Dominique",
    msgDominicanRepublic: "République dominicaine",
    msgEastTimor: "Timor oriental",
    msgEcuador: "Equateur",
    msgEgypt: "Egypte",
    msgEngland: "Angleterre",
    msgElSalvador: "Le Salvador",
    msgEquatorialGuinea: "Guinée Équatoriale",
    msgEritrea: "Érythrée",
    msgEstonia: "Estonie",
    msgEthiopia: "Ethiopie",
    msgFalklandIslands: "les îles Falkland",
    msgFaroeIslands: "Îles Féroé",
    msgFijiIslands: "Îles Fidji",
    msgFinland: "Finlande",
    msgFrance: "France",
    msgFrenchGuiana: "Guyane Française",
    msgFrenchPolynesia: "Polynésie française",
    msgFrenchSouthern: "Territoires du Sud français",
    msgGabon: "Gabon",
    msgGambia: "Gambie",
    msgGeorgia: "Géorgie",
    msgGermany: "Allemagne",
    msgGhana: "Ghana",
    msgGibraltar: "Gibraltar",
    msgGreece: "Grèce",
    msgGreenland: "Groenland",
    msgGrenada: "Grenade",
    msgGuadeloupe: "Guadeloupe",
    msgGuam: "Guam",
    msgGuatemala: "Guatemala",
    msgGuernsey: "Guernesey",
    msgGuinea: "Guinée",
    msgGuineaBissau: "Guinée-Bissau",
    msgGuyana: "Guyane",
    msgHaiti: "Haïti",
    msgHeardMcDonald: "Île Heard et îles McDonald",
    msgHolySee: "Saint-Siège (État de la Cité du Vatican)",
    msgHonduras: "Honduras",
    msgHongKong: "Hong Kong",
    msgHungary: "Hongrie",
    msgIceland: "Islande",
    msgIndia: "Inde",
    msgIndonesia: "Indonésie",
    msgIran: "L'Iran",
    msgIraq: "Irak",
    msgIreland: "Irlande",
    msgIsrael: "Israël",
    msgIsleMan: "île de Man",
    msgItaly: "Italie",
    msgJamaica: "Jamaïque",
    msgJapan: "Japon",
    msgJersey: "Jersey",
    msgJordan: "Jordan",
    msgKazakhstan: "Kazakhstan",
    msgKenya: "Kenya",
    msgKiribati: "Kiribati",
    msgKuwait: "Koweit",
    msgKyrgyzstan: "Kirghizistan",
    msgLaos: "Laos",
    msgLatvia: "Lettonie",
    msgLebanon: "Liban",
    msgLesotho: "Lesotho",
    msgLiberia: "Libéria",
    msgLibyanJamahiriya: "Jamahiriya arabe libyenne",
    msgLiechtenstein: "Liechtenstein",
    msgLithuania: "Lituanie",
    msgLuxembourg: "Luxembourg",
    msgMacao: "Macao",
    msgNorthMacedonia: "Macédoine du Nord",
    msgMadagascar: "Madagascar",
    msgMalawi: "Malawi",
    msgMalaysia: "Malaisie",
    msgMaldives: "Maldives",
    msgMali: "Mali",
    msgMalta: "Malte",
    msgMarshallIslands: "Iles Marshall",
    msgMartinique: "Martinique",
    msgMauritania: "Mauritanie",
    msgMauritius: "Maurice",
    msgMayotte: "Mayotte",
    msgMexico: "Mexique",
    msgMicronesia: "Micronésie, États fédérés de",
    msgMoldova: "Moldavie",
    msgMonaco: "Monaco",
    msgMongolia: "Mongolie",
    msgMontserrat: "Montserrat",
    msgMontenegro: "Monténégro",
    msgMorocco: "Maroc",
    msgMozambique: "Mozambique",
    msgMyanmar: "Birmanie",
    msgNamibia: "Namibie",
    msgNauru: "Nauru",
    msgNepal: "Népal",
    msgNetherlands: "Pays-Bas",
    msgNetherlandsAntilles: "Antilles néerlandaises",
    msgNewCaledonia: "Nouvelle Calédonie",
    msgNewZealand: "Nouvelle-Zélande",
    msgNicaragua: "Nicaragua",
    msgNiger: "Niger",
    msgNigeria: "Nigeria",
    msgNiue: "Niué",
    msgNorfolkIsland: "l'ile de Norfolk",
    msgNorthKorea: "Corée du Nord",
    msgNorthernIreland: "Irlande du Nord",
    msgNorthernMariana: "Îles Mariannes du Nord",
    msgNorway: "Norvège",
    msgOman: "Oman",
    msgPakistan: "Pakistan",
    msgPalau: "Palaos",
    msgPalestine: "Palestine",
    msgPanama: "Panama",
    msgPapua: "Papouasie Nouvelle Guinée",
    msgParaguay: "Paraguay",
    msgPeru: "Pérou",
    msgPhilippines: "Philippines",
    msgPitcairn: "Pitcairn",
    msgPoland: "Pologne",
    msgPortugal: "le Portugal",
    msgPuertoRico: "Porto Rico",
    msgQatar: "Qatar",
    msgReunion: "Réunion",
    msgRomania: "Roumanie",
    msgRussianFederation: "Fédération Russe",
    msgRwanda: "Rwanda",
    msgSaintHelena: "Sainte-Hélène",
    msgSaintKitts: "Saint-Christophe-et-Niévès",
    msgSaintLucia: "Sainte-Lucie",
    msgSaintPierre: "Saint-Pierre-et-Miquelon",
    msgSaintVincent: "Saint-Vincent-et-les-Grenadines",
    msgSamoa: "Samoa",
    msgSanMarino: "Saint Marin",
    msgSaoTome: "Sao Tomé et Principe",
    msgSaudiArabia: "Arabie Saoudite",
    msgScotland: "Écosse",
    msgSenegal: "Sénégal",
    msgSerbia: "Serbie",
    msgSeychelles: "les Seychelles",
    msgSierraLeone: "Sierra Leone",
    msgSingapore: "Singapour",
    msgSlovakia: "Slovaquie",
    msgSlovenia: "Slovénie",
    msgSolomonIslands: "Les îles Salomon",
    msgSomalia: "Somalie",
    msgSouthAfrica: "Afrique du Sud",
    msgSouthSandwich: "Géorgie du Sud et îles Sandwich du Sud",
    msgSouthKorea: "Corée du Sud",
    msgSouthSudan: "Soudan du sud",
    msgSpain: "Espagne",
    msgSriLanka: "Sri Lanka",
    msgSudan: "Soudan",
    msgSuriname: "Suriname",
    msgSvalbardJan: "Svalbard et Jan Mayen",
    msgSwaziland: "Swaziland",
    msgSweden: "Suède",
    msgSwitzerland: "Suisse",
    msgSyria: "Syrie",
    msgTajikistan: "Tadjikistan",
    msgTanzania: "Tanzanie",
    msgThailand: "Thaïlande",
    msgTimorLeste: "Timor oriental",
    msgTogo: "Aller",
    msgTokelau: "Tokélaou",
    msgTonga: "Tonga",
    msgTrinidadTobago: "Trinité-et-Tobago",
    msgTunisia: "Tunisie",
    msgTurkey: "Turquie",
    msgTurkmenistan: "Turkménistan",
    msgTurksCaicos: "îles Turques-et-Caïques",
    msgTuvalu: "Tuvalu",
    msgUganda: "Ouganda",
    msgUkraine: "Ukraine",
    msgUnitedArabEmirates: "Emirats Arabes Unis",
    msgUnitedKingdom: "Royaume-Uni",
    msgUnitedStates: "États-Unis",
    msgUnitedStatesMinor: "Îles mineures éloignées des États-Unis",
    msgUruguay: "Uruguay",
    msgUzbekistan: "Ouzbékistan",
    msgVanuatu: "Vanuatu",
    msgVenezuela: "Venezuela",
    msgVietnam: "Viêt Nam",
    msgVirginIslandsBritish: "Îles Vierges britanniques",
    msgVirginIslandsUS: "Îles Vierges, États-Unis",
    msgWales: "Pays de Galles",
    msgWallisFutuna: "Wallis et Futuna",
    msgWesternSahara: "Sahara occidental",
    msgYemen: "Yémen",
    msgZambia: "Zambie",
    msgZimbabwe: "Zimbabwe",
}

export default fr;