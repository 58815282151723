import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../components/styled/Styled';

import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import DimensionCaseItem from '../components/DimensionCaseItem';
import { getTextLang, LanguageContext } from '../components/providers/LanguageProvider';

const LevelDetail = () => {
    const [item, setItem] = useState();
    const { language } = useContext(LanguageContext);
    const location = useLocation();
    const history = useNavigate();

    useEffect(() => {
        HttpConnection(`${HttpOperations.level}/${location.state.item.id_level}`,
            response => {
                setItem(response.item);
            }, error => {
                console.log(error);
            }, HttpMethod.get);
    }, [location.state.item.id_level])

    return (
        <ContainerFlex width="12" justify="center" px="32" >
            <Container width="12" lg="8">
                <Card width="12" shadowOn flex mv="16" px="16" align="center" color="color-blue-drawer" fontColor="color-white">
                    <Container width="12" fontSize="18px" fontWeight="300" radiusbl="0px" radiusbr="0px" children={<TextLocalized children="msgImprovement" />} />
                    <Container mt="8" fontSize="12px" children={<TextLocalized children="msgImprovementSubtitle" />} />
                </Card>
                <ContainerFlex width="12" mt="32" fontWeight="bold">
                    <Button onClick={() => history(-1)} children={<TextLocalized children="msgDrawer3" />} />
                    <Container mh="16" children=">" />
                    {
                        item ? <Container children={`${item.id_level} - ${getTextLang(item, 'name', language)}`} /> : null
                    }
                </ContainerFlex>
                <ContainerFlex width="12" align="stretch" children={(item?.dimensions ?? []).map((item) => <DimensionCaseItem key={item.id_dimension} item={item} />)} />
            </Container>
        </ContainerFlex>
    );
};

export default LevelDetail;