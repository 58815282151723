import React, { useContext } from 'react';
import { LanguageContext } from './providers/LanguageProvider';
import { Card, Container, ContainerFlex } from './styled/Styled';
import { getLevelImage } from './util/BlockUtil';

const InfoLevel = ({ item }) => {
    const { dictionary } = useContext(LanguageContext);

    return <Container width="12" px="4" mb="8">
        <Card flex shadowOn width="12" align="stretch" color="color-white">
            <Card flex width="12" px="16" align="center" color={"color-gray-card"} radiusbl="0px" radiusbr="0px">
                <img alt={`level-${item.id_level}`} width="50px" src={getLevelImage(item)} />
                <Container width="wrap" ph="16" fontSize="18px" fontWeight="bold" fontColor="color-black" children={dictionary[item.name]} />
            </Card>
            <Container width="12" height="1px" color="color-gray" />
            <ContainerFlex width="12" pv="20" ph="16" fontSize="14px" children={dictionary[item.text]} />
        </Card>
    </Container>
};

export default InfoLevel;