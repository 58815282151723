const es = {
    actionAlready: "¿Ya tienes una cuenta? Iniciar sesión",
    actionExit: "Cerrar sesión",
    actionLogin: "INICIAR SESIÓN",
    actionRegister: "REGÍSTRATE",
    actionSave: "GUARDAR",
    actionStart: "COMENZAR",
    actionContinue: "CONTINUAR",
    actionReset: "RESTABLECER EVALUACIÓN",
    actionDoDiagnost: "MOSTRAR DIAGNÓSTICO",
    actionYes: "Sí",
    actionNo: "No",
    actionNext: "SIGUIENTE",
    actionBusinessCase: "MOSTRAR ESTUDIO DE VIABILIDAD",
    actionBusinessCases: "MOSTRAR ESTUDIOS DE VIABILIDAD",
    actionOpenUrl: "ABRIR URL",
    actionFilter: "FILTRAR",
    
    /** HOME */
    msgHomeTitleOne: "Evalúa y aumenta el impacto positivo de tu empresa de muebles",
    msgHomeSubTitle: "La herramienta online FurnCSR evalúa tu progreso actual de RSC en función de los impactos económicos y organizativos, sociales y medioambientales",
    
    msgHomeSection2: "Cada vez son más los requisitos de la RSC.</br>¿Estás preparado?",
    msgHomeSection2Text: "<ul><li>Responde a las preguntas sobre tus actividades corporativas diarias conforme a los principales estándares de impacto.</li><li>Tras la autoevalucaión, obtendrás una puntuación del nivel de desarrollo.</li><li>Identifica buenas prácticas para llevar a tu empresa al siguiente nivel.</li><li>Descubre ejemplos prácticos reales del sector del mueble para saber cómo aumentar el impacto positivo de tu empresa de forma continua.</li></ul>",
    msgHomeSection3: "Socios",
    msgHomeSection2Btn: "Comienza la evaluación",
    msgHomeSection3Btn: "CONTACTO",
    
    /** HEADER */
    msgHeader1: "Buenas prácticas",
    msgHeader4: "Inicio de sesión",
    
    /** DRAWER */
    msgCompany: "EMPRESA",
    msgDrawer1: "Evaluación FurnCSR ",
    msgDrawer2: "Informe de rendimiento",
    msgDrawer3: "Informe de mejora",
    msgDrawer4: "Recopilación de buenas prácticas",
    msgDrawer5: "Acerca de los niveles FurnCSR",
    msgDrawer6: "Ajustes",
    msgDrawer7: "Cerrar sesión",
    msgDrawer8: "Evalúa tu experiencia",
    
    /** LOGIN */
    msgWelcome: "Bienvenido/a",
    msgWelcomeSubtitle: "Inicia sesión para continuar",
    
    /** REGISTER */
    msgGetStarted: "Cómo empezar",
    msgGetStartedSubtitle: "Esta herramienta de gestión del impacto es gratuita y confidencial",
    
    msgFirstname: "Nombre",
    msgLastname: "Apellidos",
    msgEmail: "Correo electrónico",
    msgPassword: "Contraseña",
    msgConfirmPassword: "Confirmar contraseña",
    msgCompanyUser: "Empresa o nombre de usuario",
    
    msgNotRegister: "¿Aún no te has registrado? <b>Regístrate aquí</b>",
    msgAlreadyRegister: "¿Ya estás registrado? <b>Accede aquí</b>",
    
    /** HOME TOOL */
    msgClickImage: "Haz clic para ampliar FurnCSR Business CANVAS.",
    
    msgArea: "ÁREA",
    msgProgress: "PREGUNTAS",
    
    msgAssessmentAreas: "Áreas de impacto de FurnCSR",
    msgAssessmentDesc: "Todas las preguntas de FurnCSR están organizadas conforme a los principales bloques de negocio de una empresa del sector del mueble socialmente responsable. Así es FurnCSR Business CANVAS. La producción y las operaciones internas se encuentran en la parte izquierda de canvas, mientras que la oferta y las experiencias externas se representan en la parte de la derecha. En cada bloque utilizamos un triple enfoque (económico/organizativo, social y medioambiental) para evaluar las actividades empresariales orientadas a la RSC. ",
    
    msgAssessment: "Evaluación FurnCSR",
    msgNotApply: "Marca las respuestas que coincidan con el desarrollo actual de tu empresa. En el caso de que no coincida ninguna, no marques ninguna opción.",
    msgMarkImportant: "Marcar como importante",
    msgComment: "Comentario",
    msgCommentHolder: "Escribe aquí cualquier información pertinente o los comentarios que consideres.",
    
    /** PERFORMANCE REPORT */
    msgPerformance: "Informe de rendimiento",
    msgPerformanceSubtitle: "Comprueba la puntuación de tu autoevaluación del nivel de desarrollo por cada bloque de negocio y globalmente.",
    msgCompanyLevel: "Nivel de tu empresa",
    msgBlockArea: "ÁREA DEL BLOQUE",
    
    /** IMPROVEMENT REPORT */
    msgImprovement: "Informe de mejora",
    msgImprovementMainSubtitle: "Descubre qué puedes hacer para mejorar de forma continua. Encuentra otras buenas prácticas y casos de negocio para llevar a tu empresa al siguiente nivel.</br></br>Puedes empezar a leer y a coger ideas de las buenas prácticas que aparecen en los niveles inferiores e ir implementándolas progresivamente con las de los siguientes niveles. Recuerda que las prácticas de los niveles 1 y 2 deben atenerse a lo establecido en la legislación y en la normativa, por lo que su cumplimiento supone una obligación legal y, por tanto, es muy importante que se lleve a cabo.",
    msgImprovementSubtitle: "Descubre qué puedes hacer para mejorar de forma continua. Encuentra otras buenas prácticas y casos de negocio para llevar a tu empresa al siguiente nivel.",
    msgLevels: "Niveles",
    msgActionsToImplement: "Acciones a implementar:",
    msgBaselineScore: "Mi puntuación de referencia",
    msgBusinessCaseEmpty: "¡Enhorabuena! Ya has implementado las buenas prácticas principales de esta categoría",
    
    msgCat1: "IMPACTO ECONÓMICO Y ORGANIZATIVO",
    msgCat2: "SOCIAL",
    msgCat3: "MEDIOAMBIENTAL",
    
    /** GOOD PRACTICES COLLECTION */
    msgGoodPracticesSubtitle: "Explora y consulta la recopilación completa de buenas prácticas y casos de negocio del Proyecto FurnCSR ",
    msgBusinessBlock: "Bloque empresarial",
    msgLevel: "Nivel",
    msgDimension: "Dimensión",
    msgStep: "Step",
    
    /** SETTINGS */
    msgUserInfo: "Información de usuario",
    msgUserCredentials: "Credenciales de usuario",
    
    /** MODAL */
    msgAreSure: "¿Estás seguro?",
    
    msgSuccess: "Datos actualizados correctamente",
    msgDeleteSuccess: "Datos eliminados correctamente",
    
    /** ERROR */
    errorOblData: "Completa todos los campos obligatorios",
    errorEmailFormat: "Formato de correo electrónico incorrecto",
    errorUserIncorrect: "Correo electrónico o contraseña incorrectos",
    errorSend: "Error de comunicación, espere y vuelva a intentarlo más tarde",
    errorLength: "Se ha superado el número de caracteres",
    errorPassSame: "Las contraseñas no coinciden",
    
    /** FOOTER */
    msgFooterContact: "CONTACTO",
    msgFooterLinkedIn: "LINKEDIN",
    msgFooterTwitter: "TWITTER",
    
    msgFooterGr: "Este proyecto ha sido financiado por la convocatoria de la Comisión Europea: Apoyo al diálogo social VP/2020/001",
    msgCopyright: "Copyright&copy;2023 AMBIT. Todos los derechos reservados",
    
    msgFooterLegal: "Aviso legal",
    msgFooterPrivacy: "Privacidad",
    msgFooterCookies: "Política de cookies",
    
    /** PROGRESS VALUES */
    msgProgress0: "Áreas de mejora",
    msgProgress1: "Cumplimiento normativo",
    msgProgress2: "Mejora normativa",
    msgProgress3: "Cumplimiento de expectativas",
    msgProgress4: "Compromiso",
    
    /** COMPANY SIZES */
    msgCompanySize: "Tamaño de la empresa",
    msgCompanySize1: "Menos de 10 empleados",
    msgCompanySize2: "10-30 empleados",
    msgCompanySize3: "30-50 empleados",
    msgCompanySize4: "Más de 50 empleados",
    
    /** PRODUCT TYPES */
    msgProductType: "Tipo de productos que fabrica",
    msgProductType1: "Tapicería",
    msgProductType2: "Colchones",
    msgProductType3: "Bases de tapicería, canapés, somieres",
    msgProductType4: "Subcontratista (bastidores, mecanizado, barnizado, etc.)",
    msgProductType5: "Servicios (logística especializada, etc.)",
    msgProductType6: "Aglomerados (melamina, chapeado, lacados, etc.)",
    msgProductType7: "Muebles de madera",
    msgProductType8: "Sillas",
    msgProductType9: "Mobiliario de oficina",
    msgProductType10: "Mobiliario para espacios y usos públicos",
    msgProductType11: "Puertas, ventanas y productos de carpintería",
    msgProductType12: "Instalaciones a medida (tiendas, bibliotecas, restaurantes, comercios, etc.)",
    msgProductType13: "Iluminación",
    msgProductType14: "Revestimientos y suelos",
    msgProductType15: "Textil",
    msgProductType16: "Decoraciones",

    /** ABOUT */
    msgAboutSec1Title: "Introducción al modelo de la guía de progreso de FurnCSR",
    msgAboutSec1Text1: "La Guía de progreso que presentamos en FurnCSR nos permite precisar las prácticas de RSC de la organización, mientras que la ausencia de prácticas pertinentes en algunos recuadros nos permitirá detectar las lagunas que hay que cubrir</br></br>Concebida desde los fundamentos metodológicos de la RSC, la Guía de progreso muestra, de forma global y estructurada, la posición en la que se consolida una organización y el camino que ésta debe seguir en la gestión de la RSC.</br></br>El modelo se basa en cinco niveles que, duplicados en dos columnas, ofrecen 10 categorías, desde las áreas de mejora hasta el nivel máximo de compromiso. Mostramos el modelo básico de forma simplificada con el objetivo de brindar otras propuestas de buenas prácticas diferentes a las anteriores (es decir, el Capítulo 4) en el sentido de que permitan avanzar en el desarrollo integrado de un modelo de RSC.</br></br>En las siguientes figuras se muestran los diferentes niveles y categorías de madurez en cuanto a la RSC.</br></br>Subiendo por la primera columna (recuadro 0) observamos las diferentes categorías situadas entre el incumplimiento de normativas y el nivel máximo de compromiso (recuadro 9). Subiendo por la columna de la derecha, que muestra una mayor proactividad y los fundamentos de la metodología única de la RSC, se va integrando la presencia de las partes interesadas y se muestran los avances en la lógica de la ciudadanía corporativa. La RSC llama a reparar, asegurar, comprender, ser responsables y crear valor compartido más allá de lo que corresponde a una gestión de lógica más interna. No basta con hacer las cosas con la mejor intención posible, sino que hay que generar impactos de manera genuina y con la confianza de las partes interesadas.",
    msgAboutSec1Text2: "Este es un camino de progreso hacia la excelencia y llegar a posiciones intermedias puede ser un hito de gran valor para la empresa del mueble. Será importante no dejarse los temas de las áreas inferiores y tener una visión de cómo avanzar integrando no solo las buenas prácticasque se han mostrado en el capítulo anterior, sino también las que se muestran a continuación, situadas en los niveles superiores de este modelo. Posteriormente, la guía de progreso puede aplicarse a cada una de las tres dimensiones de gestión de la RSC en función de sus propias características específicas, manteniendo en todo momento el mismo camino, que es lo que permite comprender la evolución en el grado de madurez de la RSC en la empresa del mueble",

    msgAboutLevel1Title: "Áreas de mejora (pasos 0 y 1)",
    msgAboutLevel1Text: "Este nivel corresponde a las áreas de mejora e implica que existen incumplimientos graves o diversos, tanto legales como relacionados con los compromisos, o que aún se está en fase de corrección y reparación",

    msgAboutLevel2Title: "Cumplimiento de la normativa (pasos 2 y 3)",
    msgAboutLevel2Text: "Este nivel corresponde al cumplimiento normativo, y significa que se cumple y garantiza el cumplimiento de las obligaciones, ya sean legales o de los propios compromisos voluntarios. Para una organización comprometida con la RSC, el cumplimiento de la ley no solo tiene que ver con el texto literal, sino también con la intención que las entidades reguladoras persiguen en nombre de la sociedad, al evitar el incumplimiento de la ley. A nivel de cumplimiento normativo, se especifican las prácticas relacionadas con el cumplimiento de los principales requisitos legales del sector, ya que sin ello no se podría empezar a abordar la gestión de la RSC",

    msgAboutLevel3Title: "Mejora normative (pasos 4 y 5)",
    msgAboutLevel3Text: "Este nivel corresponde a la mejora normativa y significa que va más allá de las obligaciones legales con la aplicación de buenas prácticas. Para una organización comprometida con la RSC, se conversa con las partes interesadas para comprender el propio contexto de sostenibilidad.",

    msgAboutLevel4Title: "Cumplimiento de las expectativas (paso 6 y 7)",
    msgAboutLevel4Text: "Este nivel corresponde a la satisfacción de las expectativas y significa que las expectativas de las partes interesadas se abordan a través de una gestión sistematizada de la RSC (planificación, acciones, integración en procesos y procedimientos, evaluación, mejora continua...). Para una organización comprometida con la RSC, la rendición de cuentas ante las partes interesadas es transparente.",

    msgAboutLevel5Title: "Compromiso (paso 8 y 9)",
    msgAboutLevel5Text: "Este nivel corresponde al compromiso y significa que se lleva a cabo una gestión comprometida, con conciencia y liderazgo, explícita y permanente, integral, vinculada a la planificación estratégica y a un propósito superior, y orientada a la creación de valor compartido con las partes interesadas",
    
    /* COUNTRIES */
    msgCountry: "País",
    msgAfghanistan: "Afganistán",
    msgAlbania: "Albania",
    msgAlgeria: "Argelia",
    msgAmericanSamoa: "Samoa Americana",
    msgAndorra: "Andorra",
    msgAngola: "angola",
    msgAnguilla: "anguila",
    msgAntarctica: "Antártida",
    msgAntiguaandBarbuda: "Antigua y Barbuda",
    msgArgentina: "Argentina",
    msgArmenia: "Armenia",
    msgAruba: "Aruba",
    msgAustralia: "Australia",
    msgAustria: "Austria",
    msgAzerbaijan: "Azerbaiyán",
    msgBahamas: "bahamas",
    msgBahrain: "Baréin",
    msgBangladesh: "bangladesh",
    msgBarbados: "barbados",
    msgBelarus: "Bielorrusia",
    msgBelgium: "Bélgica",
    msgBelize: "Belice",
    msgBenin: "Benín",
    msgBermuda: "islas Bermudas",
    msgBhutan: "Bután",
    msgBolivia: "Bolivia",
    msgBosniaHerzegovina: "Bosnia y Herzegovina",
    msgBotswana: "Botsuana",
    msgBouvetIsland: "Isla Bouvet",
    msgBrazil: "Brasil",
    msgBritishIndian: "Territorio Británico del Océano Índico",
    msgBrunei: "Brunéi",
    msgBulgaria: "Bulgaria",
    msgBurkinaFaso: "Burkina Faso",
    msgBurundi: "Burundi",
    msgCambodia: "Camboya",
    msgCameroon: "Camerún",
    msgCanada: "Canadá",
    msgCapeVerde: "Cabo Verde",
    msgCaymanIslands: "Islas Caimán",
    msgCentralAfrican: "República Centroafricana",
    msgChad: "Chad",
    msgChile: "Chile",
    msgChina: "Porcelana",
    msgChristmasIsland: "Isla de Navidad",
    msgCocosIslands: "Islas Cocos (Keeling)",
    msgColombia: "Colombia",
    msgComoros: "Comoras",
    msgCongo: "Congo",
    msgRepublicCongo: "La República Democrática del Congo",
    msgCookIslands: "Islas Cook",
    msgCostaRica: "Costa Rica",
    msgIvoryCoast: "Costa de Marfil",
    msgCroatia: "Croacia",
    msgCuba: "Cuba",
    msgCyprus: "Chipre",
    msgCzechRepublic: "República Checa",
    msgDenmark: "Dinamarca",
    msgDjibouti: "Yibuti",
    msgDominica: "Dominica",
    msgDominicanRepublic: "República Dominicana",
    msgEastTimor: "Timor Oriental",
    msgEcuador: "Ecuador",
    msgEgypt: "Egipto",
    msgEngland: "Inglaterra",
    msgElSalvador: "El Salvador",
    msgEquatorialGuinea: "Guinea Ecuatorial",
    msgEritrea: "Eritrea",
    msgEstonia: "Estonia",
    msgEthiopia: "Etiopía",
    msgFalklandIslands: "Islas Malvinas",
    msgFaroeIslands: "Islas Faroe",
    msgFijiIslands: "Islas Fiyi",
    msgFinland: "Finlandia",
    msgFrance: "Francia",
    msgFrenchGuiana: "Guayana Francesa",
    msgFrenchPolynesia: "Polinesia francés",
    msgFrenchSouthern: "Territorios Franceses del Sur",
    msgGabon: "Gabón",
    msgGambia: "Gambia",
    msgGeorgia: "Georgia",
    msgGermany: "Alemania",
    msgGhana: "Ghana",
    msgGibraltar: "Gibraltar",
    msgGreece: "Grecia",
    msgGreenland: "Groenlandia",
    msgGrenada: "Granada",
    msgGuadeloupe: "Guadalupe",
    msgGuam: "Guam",
    msgGuatemala: "Guatemala",
    msgGuernsey: "Guernesey",
    msgGuinea: "Guinea",
    msgGuineaBissau: "Guinea-Bisáu",
    msgGuyana: "Guayana",
    msgHaiti: "Haití",
    msgHeardMcDonald: "Islas Heard y McDonald",
    msgHolySee: "Santa Sede (Estado de la Ciudad del Vaticano)",
    msgHonduras: "Honduras",
    msgHongKong: "Hong Kong",
    msgHungary: "Hungría",
    msgIceland: "Islandia",
    msgIndia: "India",
    msgIndonesia: "Indonesia",
    msgIran: "Irán",
    msgIraq: "Irak",
    msgIreland: "Irlanda",
    msgIsrael: "Israel",
    msgIsleMan: "Isla del hombre",
    msgItaly: "Italia",
    msgJamaica: "Jamaica",
    msgJapan: "Japón",
    msgJersey: "Jersey",
    msgJordan: "Jordán",
    msgKazakhstan: "Kazajstán",
    msgKenya: "Kenia",
    msgKiribati: "Kiribati",
    msgKuwait: "Kuwait",
    msgKyrgyzstan: "Kirguistán",
    msgLaos: "Laos",
    msgLatvia: "letonia",
    msgLebanon: "Líbano",
    msgLesotho: "Lesoto",
    msgLiberia: "Liberia",
    msgLibyanJamahiriya: "Jamahiriya Árabe Libia",
    msgLiechtenstein: "Liechtenstein",
    msgLithuania: "Lituania",
    msgLuxembourg: "luxemburgo",
    msgMacao: "Macao",
    msgNorthMacedonia: "macedonia del norte",
    msgMadagascar: "Madagascar",
    msgMalawi: "Malaui",
    msgMalaysia: "Malasia",
    msgMaldives: "Maldivas",
    msgMali: "Malí",
    msgMalta: "Malta",
    msgMarshallIslands: "Islas Marshall",
    msgMartinique: "Martinica",
    msgMauritania: "Mauritania",
    msgMauritius: "Mauricio",
    msgMayotte: "Mayotte",
    msgMexico: "México",
    msgMicronesia: "Micronesia, Estados Federados de",
    msgMoldova: "Moldavia",
    msgMonaco: "Mónaco",
    msgMongolia: "Mongolia",
    msgMontserrat: "Montserrat",
    msgMontenegro: "montenegro",
    msgMorocco: "Marruecos",
    msgMozambique: "Mozambique",
    msgMyanmar: "Birmania",
    msgNamibia: "Namibia",
    msgNauru: "Nauru",
    msgNepal: "Nepal",
    msgNetherlands: "Países Bajos",
    msgNetherlandsAntilles: "Antillas Holandesas",
    msgNewCaledonia: "Nueva Caledonia",
    msgNewZealand: "Nueva Zelanda",
    msgNicaragua: "Nicaragua",
    msgNiger: "Níger",
    msgNigeria: "Nigeria",
    msgNiue: "Niue",
    msgNorfolkIsland: "Isla Norfolk",
    msgNorthKorea: "Corea del Norte",
    msgNorthernIreland: "Irlanda del Norte",
    msgNorthernMariana: "Islas Marianas del Norte",
    msgNorway: "Noruega",
    msgOman: "Omán",
    msgPakistan: "Pakistán",
    msgPalau: "palaos",
    msgPalestine: "Palestina",
    msgPanama: "Panamá",
    msgPapua: "Papúa Nueva Guinea",
    msgParaguay: "Paraguay",
    msgPeru: "Perú",
    msgPhilippines: "Filipinas",
    msgPitcairn: "pitcairn",
    msgPoland: "Polonia",
    msgPortugal: "Portugal",
    msgPuertoRico: "Puerto Rico",
    msgQatar: "Katar",
    msgReunion: "Reunión",
    msgRomania: "Rumania",
    msgRussianFederation: "Federación Rusa",
    msgRwanda: "Ruanda",
    msgSaintHelena: "Santa Elena",
    msgSaintKitts: "San Cristóbal y Nieves",
    msgSaintLucia: "Santa Lucía",
    msgSaintPierre: "San Pedro y Miquelón",
    msgSaintVincent: "San Vicente y las Granadinas",
    msgSamoa: "samoa",
    msgSanMarino: "San Marino",
    msgSaoTome: "Santo Tomé y Príncipe",
    msgSaudiArabia: "Arabia Saudita",
    msgScotland: "Escocia",
    msgSenegal: "Senegal",
    msgSerbia: "Serbia",
    msgSeychelles: "Seychelles",
    msgSierraLeone: "Sierra Leona",
    msgSingapore: "Singapur",
    msgSlovakia: "Eslovaquia",
    msgSlovenia: "Eslovenia",
    msgSolomonIslands: "Islas Salomón",
    msgSomalia: "Somalia",
    msgSouthAfrica: "Sudáfrica",
    msgSouthSandwich: "Georgia del sur y las islas Sandwich del sur",
    msgSouthKorea: "Corea del Sur",
    msgSouthSudan: "Sudán del Sur",
    msgSpain: "España",
    msgSriLanka: "Sri Lanka",
    msgSudan: "Sudán",
    msgSuriname: "Surinam",
    msgSvalbardJan: "Svalbard y Jan Mayen",
    msgSwaziland: "Suazilandia",
    msgSweden: "Suecia",
    msgSwitzerland: "Suiza",
    msgSyria: "Siria",
    msgTajikistan: "Tayikistán",
    msgTanzania: "Tanzania",
    msgThailand: "Tailandia",
    msgTimorLeste: "Timor Oriental",
    msgTogo: "Ir",
    msgTokelau: "Tokelau",
    msgTonga: "tonga",
    msgTrinidadTobago: "Trinidad y Tobago",
    msgTunisia: "Túnez",
    msgTurkey: "Pavo",
    msgTurkmenistan: "turkmenistán",
    msgTurksCaicos: "Islas Turcas y Caicos",
    msgTuvalu: "Tuvalu",
    msgUganda: "Uganda",
    msgUkraine: "Ucrania",
    msgUnitedArabEmirates: "Emiratos Árabes Unidos",
    msgUnitedKingdom: "Reino Unido",
    msgUnitedStates: "Estados Unidos",
    msgUnitedStatesMinor: "Islas ultramarinas menores de los Estados Unidos",
    msgUruguay: "Uruguay",
    msgUzbekistan: "Uzbekistán",
    msgVanuatu: "Vanuatu",
    msgVenezuela: "Venezuela",
    msgVietnam: "Vietnam",
    msgVirginIslandsBritish: "Islas Vírgenes Británicas",
    msgVirginIslandsUS: "Islas Vírgenes, EE. UU.",
    msgWales: "Gales",
    msgWallisFutuna: "Wallis y Futuna",
    msgWesternSahara: "Sahara Occidental",
    msgYemen: "Yemen",
    msgZambia: "Zambia",
    msgZimbabwe: "Zimbabue",
}

export default es;