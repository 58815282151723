import React, { useReducer } from 'react';

const CategoryIndexContext = React.createContext();
const CategoryIndexConsumer = CategoryIndexContext.Consumer;

const initState = {
    index: null,
    categories: []
}

const CategoryIndexAction = {
    next: "NEXT",
    setIndex: "setIndex",
    setCategories: "setCategories"
}

const reduce = (state, callback) => {
    switch (callback.action) {
        case CategoryIndexAction.next:
            let currentIndex = state.categories.findIndex(item => callback.data == item.id_group);
            let itemId = state.categories.length > currentIndex + 1 ? state.categories[currentIndex + 1].id_group : null
            return { ...state, index: itemId };
        case CategoryIndexAction.setIndex:
            return { ...state, index: state.index == callback.data ? null : callback.data, };
        case CategoryIndexAction.setCategories:
            let allGroups = [];
            callback.data.forEach(dimension => dimension.groups.forEach(group => allGroups.push(group)));
            return { ...state, categories: allGroups }
        default:
            return state
    }
}

const CategoryIndexProvider = (props) => {
    const [state, dispatch] = useReducer(reduce, initState);
    let value = { state, dispatch };
    return <CategoryIndexContext.Provider value={value} children={props.children} />;
}

export { CategoryIndexContext, CategoryIndexProvider, CategoryIndexConsumer, CategoryIndexAction }