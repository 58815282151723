import { mdiChevronDown, mdiChevronLeft, mdiChevronRight, mdiChevronUp, mdiChartTree, mdiStar, mdiStarOutline } from '@mdi/js';
import Icon from '@mdi/react';
import React, { Fragment, useEffect, useState } from 'react';
import { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { getTextLang, LanguageContext } from '../components/providers/LanguageProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized, Tooltip } from '../components/styled/Styled';
import { CategoryIndexAction, CategoryIndexContext } from '../components/providers/CategoryIndexProvider';
import { HttpConnection, HttpOperations, HttpMethod } from '../components/util/HttpConnection';
import { getBlockImage } from '../components/util/BlockUtil';
import { UserAction, UserContext } from '../components/providers/UserProvider';

const EmptyAnswer = ({ item }) => {
    const [answers, setAnswers] = useState([])

    useEffect(() => {
        setAnswers([...item.answers]);
    }, [item.answers])
    return <Container width="12" ph="8" pv="16" fontColor="color-black">
        {
            answers.map(answer => <ContainerFlex key={answer.id} width="12" align="center">
                <Container width="wrap" ph="8" children={<TextLocalized children={answer.title} />} />
            </ContainerFlex>)
        }
    </Container>
}

const SingleAnswer = ({ item }) => {
    const [answers, setAnswers] = useState([])
    const { dictionary } = useContext(LanguageContext);

    useEffect(() => {
        setAnswers([...item.answers]);
    }, [item.answers])

    const onChange = (e) => {
        let answersAux = answers.map(answer => {
            answer.selected = parseInt(e.target.value) === answer.id
            return answer;
        })

        let quizAux = { ...item };
        quizAux.answers = answersAux;

        //updateAnswer(quizAux);
        setAnswers(answersAux)
    }

    return <Container width="12" ph="8" pv="16" fontColor="color-black">
        {
            answers.map(answer => <ContainerFlex key={answer.id} width="12" mv="16" align="center" multiline={false}>
                <input type="radio"
                    checked={answer.selected}
                    onChange={onChange}
                    id={answer.id}
                    value={answer.id} name={item.id} />
                <Container ml="8" children={<label htmlFor={answer.id}>{dictionary[answer.title]}</label>} />
            </ContainerFlex>)
        }
    </Container>
}

const MultipleAnswer = ({ item, updateAnswers }) => {
    const [answers, setAnswers] = useState([])
    const { language } = useContext(LanguageContext);

    useEffect(() => {
        let answerAux = [...item.answers];
        answerAux = answerAux.map(answer => {
            answer.selected = answer.user_answer != null || answer.selected
            return answer;
        })
        setAnswers(answerAux);
    }, [item.answers])

    const onChange = (e) => {
        let answersAux = answers.map(answer => {
            if (parseInt(e.target.value) === answer.id_answer) {
                answer.selected = !answer.selected;
                if (answer.selected === false)
                    answer.user_answer = null;
            }
            return answer;
        })

        //Send to server
        HttpConnection(`${HttpOperations.useranswer}/${e.target.value}`,
            response => {
                console.log("Success");
            }, error => console.log(error), HttpMethod.post);

        //Update locally
        let quizAux = { ...item };
        quizAux.answers = answersAux;
        updateAnswers(quizAux);
    }

    return <Container width="12" ph="8" pv="16" fontColor="color-black">
        {
            answers.map(answer => <ContainerFlex key={answer.id_answer} width="12" mv="8" align="center" multiline={false}>
                <input type="checkbox"
                    checked={answer.selected ?? false}
                    onChange={onChange}
                    id={answer.id_answer}
                    value={answer.id_answer} name={item.id_answer} />
                <Container ml="8" children={<label htmlFor={answer.id_answer}>{getTextLang(answer, 'description', language)}</label>} />
            </ContainerFlex>)
        }
    </Container>
}

const QuizItem = ({ item, updateQuiz }) => {
    const { language } = useContext(LanguageContext);

    return <Card width="12" shadowOn mv="16" color={"color-white"}>
        <Card px="16" radiusbl="0px" radiusbr="0px" color="color-gray-card" width="12" fontSize="16px" fontWeight="300" fontColor="color-black"
            children={getTextLang(item, 'description', language)} />
        <Container width="12" height="1px" color="color-gray" />
        {
            item.id_question_type === 1 ? <MultipleAnswer item={item} updateAnswers={updateQuiz} /> : item.id_question_type === 2 ? <SingleAnswer item={item} /> : <EmptyAnswer item={item} />
        }
    </Card>
}

const CategoryGroup = ({ item }) => {
    const [group, setGroup] = useState({ ...item });

    const languageText = useContext(LanguageContext);
    const { state, dispatch } = useContext(CategoryIndexContext);
    const userContext = useContext(UserContext);

    useEffect(() => {
        if (state.index === item.id_group && group.info === null) {
            let auxLevel = { ...group };
            auxLevel.info = {
                comment: "",
                important: false,
                open: true,
            }
            //Send to server
            HttpConnection(`${HttpOperations.groupinfo}/${group.id_group}`,
                response => {
                    console.log("Success")
                    setGroup({ ...group, info: response.item });
                },
                error => console.log(error), HttpMethod.post, auxLevel.info);
            //Update inmediatly
            setGroup(auxLevel);
        }
    }, [state.index])

    const changeLevel = (e) => {
        e.stopPropagation();

        let auxLevel = { ...group };

        if (auxLevel.info === null) {
            auxLevel.info = {
                comment: "",
                important: false
            }
        }

        auxLevel.info.important = !auxLevel.info.important;

        //Send to server
        HttpConnection(`${HttpOperations.groupinfo}/${group.id_group}`,
            response => {
                setGroup({ ...group, info: response.item });
            }, error => console.log(error), HttpMethod.post, auxLevel.info);

        //Update inmediatly
        setGroup(auxLevel);
    }

    const changeInfo = (e) => {
        let auxLevel = { ...group };

        if (auxLevel.info === null) {
            auxLevel.info = {
                comment: "",
                important: false,
            }
        }
        console.log(e.target.value)
        auxLevel.info.comment = e.target.value;
        //updateGroup(auxInfo);
        setGroup(auxLevel);
    }

    const saveComment = (e) => {
        e.stopPropagation();
        //Send to server
        HttpConnection(`${HttpOperations.groupinfo}/${group.id_group}`,
            response => {
                setGroup({ ...group, info: response.item });
                userContext.dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } });
            }, error => console.log(error), HttpMethod.post, group.info);
    }

    const actionNext = () => {
        dispatch({ action: CategoryIndexAction.next, data: item.id_group })
    }

    const actionToggle = () => {
        dispatch({ action: CategoryIndexAction.setIndex, data: item.id_group })
    }

    const updateQuiz = (quiz) => {
        let auxGroup = { ...group };
        let indeQuiz = auxGroup.questions.findIndex(q => q.id_question === quiz.id_question);
        auxGroup.questions[indeQuiz] = quiz;
        setGroup(auxGroup);
    }

    return <Card shadowOn width="12" mt="16" color="color-white" overflow="visible">
        <Button overflow="visible" onClick={actionToggle} flex width="12" px="16" align="center" color={state.index === item.id_group ? "color-gray-card" : ""} radiusbl="0px" radiusbr="0px">
            <Container width="wrap" fontSize="16px" fontWeight="300" fontColor="color-black" children={getTextLang(group, 'name', languageText.language)} />
            {
                <Button onClick={changeLevel} mh="16" ph="8"
                    fontColor="color-black">
                    <Tooltip text={languageText.dictionary["msgMarkImportant"]}>
                        <Icon path={group.info && group.info.important == true ? mdiStar : mdiStarOutline} size={1} />
                    </Tooltip>
                </Button>
            }
            <Container maxheight="24px" children={<Icon size={1} path={state.index === item.id_group ? mdiChevronUp : mdiChevronDown} />} />
        </Button>

        {
            state.index === item.id_group ? <Fragment>
                <Container width="12" height="1px" color="color-gray" />
                <ContainerFlex width="12" pv="16" ph="32" mb="32">
                    {item.questions.map((question) => <QuizItem key={question.id_question} item={question} updateQuiz={updateQuiz} />)}

                    <Card shadowOn width="12" mt="16" color={"color-white"}>
                        <Card px="16" radiusbl="0px" radiusbr="0px" color="color-gray-card" width="12" fontSize="16px" fontWeight="300" fontColor="color-black" children={<TextLocalized children="msgComment" />} />
                        <Container width="12" height="1px" color="color-gray" />
                        <ContainerFlex width="12" align="center">
                            <Container width="wrap" px="16" children={<input onChange={changeInfo} value={group.info && group.info.comment ? group.info.comment : ""} placeholder={languageText.dictionary["msgCommentHolder"]} type="text" />} />
                            <Button mh="8" onClick={saveComment} pv="8" ph="16" fontColor="color-white" color="color-blue" hoverColor="color-blue-light" children={<TextLocalized children="actionSave" />} />
                        </ContainerFlex>
                    </Card>
                    <ContainerFlex width="12" mt="32" justify="flex-end" fontColor="color-white">
                        <Button onClick={actionNext} pv="8" ph="16" color="color-blue" hoverColor="color-blue-light" children={<TextLocalized children="actionNext" />} />
                    </ContainerFlex>
                </ContainerFlex>

            </Fragment> : null
        }
    </Card>
}

const CategoryItem = ({ item }) => {
    const { language } = useContext(LanguageContext);
    const [color] = useState(item.id_dimension === 1 ? "color-economic" : item.id_dimension === 2 ? "color-social" : "color-ecolo");

    return <ContainerFlex width="12">
        <Card align="center" mt="32" mb="16" ph="16" pv="8" fontSize="18px" fontWeight="300"
            color={color}
            fontColor="color-white" children={getTextLang(item, 'name', language)} />
        {item.groups.map((group) => <CategoryGroup key={group.id_group} item={group} color={color} />)}
    </ContainerFlex>
}

const AssessmentSectionView = () => {
    const [blocks, setBlocks] = useState([]);

    const [item, setItem] = useState();
    const [index, setIndex] = useState();

    const { dispatch } = useContext(CategoryIndexContext);
    const { language } = useContext(LanguageContext);

    let history = useNavigate();
    let location = useLocation();

    useEffect(() => {
        HttpConnection(HttpOperations.block, response => {
            //Set blocks
            setBlocks(response.item);
            setIndex(response.item.findIndex(block => block.id_block === location.state.item.id_block));
        }, error => console.log(error), HttpMethod.get);
    }, [location.state.item.id_block])

    useEffect(() => {
        if (index != null) {
            HttpConnection(`${HttpOperations.block}/${blocks[index].id_block}`,
                response => {
                    setItem(response.item);
                    //Set categories
                    dispatch({ action: CategoryIndexAction.setCategories, data: response.item.dimensions });
                }, error => console.log(error), HttpMethod.get);
        }
    }, [index, blocks, dispatch])

    const nextBlock = () => {
        setIndex(index + 1);
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const prevBlock = () => {
        setIndex(index - 1);
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return item ? (
        <ContainerFlex width="12" justify="center">
            <ContainerFlex width="12" lg="8" xl="8" justify="center" >
                <Card flex shadowOn color="color-white" width="12" mt="32" align="stretch">
                    <Card flex px="16" color="color-purple" justify="center" align="center" width="250px" radiusbr="0" radiustr="0" minheight="100%" >
                        <img alt={`block-${item.id_block}`} height="200px" width="100%" src={getBlockImage(item)} />
                    </Card>

                    <Container width="wrap" ph="8">
                        <Container width="12" ph="8" pv="8" fontColor="color-gray-dark" children={<TextLocalized children="msgBlockArea" />} />
                        <Container width="12" ph="8" fontWeight="bold" children={getTextLang(item, 'name', language)} />
                        <Container width="12" ph="8" pv="16" children={getTextLang(item, 'description_long', language)} />
                    </Container>
                </Card>

                <Card width="12" shadowOn flex mt="32" px="16" justify="center" color="color-blue-drawer" fontColor="color-white">
                    <Container fontSize="13px" children={<TextLocalized children="msgNotApply" />} />
                </Card>

                {item.dimensions.map((dimension) => <CategoryItem key={dimension.id_dimension} item={dimension} />)}

                <ContainerFlex width="12" justify="space-around">
                    {
                        index > 0 ?
                            <ContainerFlex fontColor="color-black" align="center" pv="32">
                                <Button onClick={() => prevBlock()} flex justify="center" pr="16" align="center" fontWeight="bold" color="color-blue" hoverColor="color-blue-light" fontColor="color-white">
                                    <Icon path={mdiChevronLeft} size={2} />
                                    <label children={blocks[index - 1].name} />
                                </Button>
                            </ContainerFlex>
                            : null
                    }
                    {
                        index < blocks.length - 1 ?
                            <ContainerFlex fontColor="color-black" align="center" pv="32">
                                <Button onClick={() => nextBlock()} flex justify="center" pl="16" align="center" fontWeight="bold" color="color-blue" hoverColor="color-blue-light" fontColor="color-white">
                                    <label children={blocks[index + 1].name} />
                                    <Icon path={mdiChevronRight} size={2} />
                                </Button>
                            </ContainerFlex>
                            :
                            <ContainerFlex fontColor="color-black" align="center" pv="32">
                                <Button onClick={() => history("/performance")} flex justify="center" pl="16" align="center" fontWeight="bold" color="color-blue" hoverColor="color-blue-light" fontColor="color-white">
                                    <label children={<TextLocalized children={"actionDoDiagnost"} />} />
                                    <ContainerFlex px="12" children={<Icon path={mdiChartTree} size={1} />} />
                                </Button>
                            </ContainerFlex>
                    }
                </ContainerFlex>
            </ContainerFlex>

        </ContainerFlex>
    ) : null;
};

export default AssessmentSectionView;