import React, { useEffect, useRef, useState } from 'react';
import { Card, Container, ContainerFlex, TextLocalized } from '../components/styled/Styled';

import BlockPerformance from '../components/BlockPerformance';
import { getStorageUser } from '../components/util/UserUtil';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { getProgressData } from '../components/util/BlockUtil';

const PerformanceView = () => {
    const [items, setItems] = useState([]);
    const [progressWidth, setProgressWidth] = useState(0);
    const progressContainerRef = useRef();

    const [progress, setProgress] = useState({ text: "msgProgress0", color: "color-red", score: 0, cat1: 0, cat2: 0, cat3: 0 });

    useEffect(() => {
        HttpConnection(`${HttpOperations.block}/score`,
            result => {
                //Get score
                let value = ((result.item.reduce((carry, value) => {
                    return carry + value.score;
                }, 0) / result.item.length) * 100).toFixed(0);

                //Get dimension progress
                let cat1 = 0, cat2 = 0, cat3 = 0;
                result.item.forEach(block => {
                    cat1 = cat1 + block.dimensions[0].progress;
                    cat2 = cat2 + block.dimensions[1].progress;
                    cat3 = cat3 + block.dimensions[2].progress;
                })

                let auxProgress = getProgressData(value);
                auxProgress.score = value;
                auxProgress.cat1 = (cat1 / result.item.length).toFixed(0);
                auxProgress.cat2 = (cat2 / result.item.length).toFixed(0);
                auxProgress.cat3 = (cat3 / result.item.length).toFixed(0);

                setProgress(auxProgress);
                setItems(result.item);
            }, error => console.log(error), HttpMethod.get);
    }, [])

    useEffect(() => {
        if (progressContainerRef.current) {
            setProgressWidth(progressContainerRef.current.offsetWidth * progress.score / 100);
        }
    }, [progress]);

    return items.length > 0 ? (
        <ContainerFlex width="12" justify="center" px="32" >
            <Container width="12" lg="8">
                <Card width="12" shadowOn flex mv="16" align="center" color="color-white">

                    <Card width="12" px="16" color="color-blue-drawer" fontColor="color-white" radiusbl="0px" radiusbr="0px">
                        <Container fontSize="18px" fontWeight="300" children={<TextLocalized children="msgPerformance" />} />
                        <Container mt="8" fontSize="12px" children={<TextLocalized children="msgPerformanceSubtitle" />} />
                    </Card>

                    <Container width="wrap" pv="8" ph="16">
                        <Container width="12" fontColor="color-black" fontSize="48px" fontWeight="300" children={getStorageUser().username} />
                    </Container>

                    {
                        /*
                    <Container fontSize="12px">
                        <Container children={<TextLocalized children="msgCat1" />} />
                        <Container fontWeight="bold" children={`${progress.cat1}%`} />
                        <Container mt="4" children={<TextLocalized children="msgCat2" />} />
                        <Container fontWeight="bold" children={`${progress.cat2}%`} />
                        <Container mt="4" children={<TextLocalized children="msgCat3" />} />
                        <Container fontWeight="bold" children={`${progress.cat3}%`} />
                    </Container>
                        */
                    }


                    <ContainerFlex px="32" justify="center" align="center" fontColor="color-blue">
                        <Card flex justify="center" align="center" mh="16"
                            border="solid" borderColor="color-black" borderWidth="7px" radius="50%"
                            fontSize="40px" fontColor="color-black" fontWeight="bold"
                            height="150px" width="150px" children={`${progress.score}%`} />
                    </ContainerFlex>

                    <Card width="12" height="1px" color="color-gray" radius="2px" />
                    <ContainerFlex width="12" px="16" align="center">
                        <Container fontWeight="300" children={<TextLocalized children="msgCompanyLevel" />} />
                        <div className='col' style={{ padding: "0 8px" }} ref={progressContainerRef}>
                            <ContainerFlex position="relative" width="wrap">
                                <Card height="20px" color="color-red" border="solid" borderColor="color-red" borderWidth="2px" mh="1" width="1px" radius="4px" radiustr="0" radiusbr="0" />
                                <Card height="20px" color="color-orange" border="solid" borderColor="color-orange" borderWidth="2px" mh="1" width="wrap" radius="0" />
                                <Card height="20px" color="color-green-light" border="solid" borderColor="color-green-light" borderWidth="2px" mh="1" width="wrap" radius="0" />
                                <Card height="20px" color="color-green" border="solid" borderColor="color-green" borderWidth="2px" mh="1" width="wrap" radius="0" />
                                <Card height="20px" color="color-green-dark" borderColor="color-green-dark" borderWidth="2px" mh="1" width="wrap" radius="4px" radiustl="0" radiusbl="0" />

                                <Card position="absolute" top="37.5%" left="1px" width={`${progressWidth}px`} height="5px" radius="2px" color="color-black" />
                            </ContainerFlex>
                        </div>

                        <Card px="8" border="solid" fontColor="color-white"
                            color={progress.color} borderColor={progress.color} borderWidth="2px" fontSize="12px"
                            children={<TextLocalized children={progress.text} />} />
                    </ContainerFlex>
                </Card>

                <ContainerFlex width="12" align="stretch" children={items.map((item, index) => <BlockPerformance key={index} item={item} />)} />
            </Container>
        </ContainerFlex>
    ) : null;
};

export default PerformanceView;