import React, { useContext } from 'react';
import { getTextLang, LanguageContext } from './providers/LanguageProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from './styled/Styled';

const BlockPracticeType = ({ item }) => {
    const { language, dictionary } = useContext(LanguageContext);

    const getColor = () => item.id_dimension === 1 ? "color-economic" : item.id_dimension === 2 ? "color-social" : "color-ecolo";
    const getName = () => item.id_dimension === 1 ? "msgCat1" : item.id_dimension === 2 ? "msgCat2" : "msgCat3";

    return <Container width="12" px="4" mb="8">
        <Card flex shadowOn width="12" align="stretch" color="color-white">
            <Button flex width="12" px="16" align="center" color={"color-gray-card"} radiusbl="0px" radiusbr="0px">
                <Container width="wrap" fontSize="16px" fontWeight="300" fontColor="color-black" children={`${dictionary['msgHeader1']} - ${item.id_good_practice}`} />
            </Button>
            <Container width="12" height="1px" color="color-gray" />
            <ContainerFlex width="wrap" justify="center" direction="column" pv="20" ph="16">
                <Container mb="16" fontSize="15px" fontWeight="bold" children={`${getTextLang(item, 'description', language)}`} />
                <ContainerFlex>
                    <Card pv="4" ph="8" border="solid" fontSize="12px" fontColor="color-white" color="color-black" borderColor="color-black" borderWidth="2px" children={`${getTextLang(item.block, 'name', language)}`} />
                    <Card mh="8" pv="4" ph="8" border="solid" fontSize="12px" fontColor="color-white" color="color-gray-home" borderColor="color-gray-home" borderWidth="2px" children={`${getTextLang(item.level, 'name', language)}`} />
                    <Card pv="4" ph="8" border="solid" fontSize="12px" fontColor="color-white" color={getColor()} borderColor={getColor()} borderWidth="2px" children={<TextLocalized children={getName()} />} />
                </ContainerFlex>
            </ContainerFlex>
        </Card>
    </Container>
};

export default BlockPracticeType;