const sv = {
    actionAlready: "Har du redan ett konto? Logga in",
    actionExit: "Logga ut",
    actionLogin: "LOGGA IN",
    actionRegister: "LOGGA UT",
    actionSave: "SPARA",
    actionStart: "START",
    actionContinue: "FORTSÄTT",
    actionReset: "ÅTERSTÄLL BEDÖMNING",
    actionDoDiagnost: "VISA DIAGNOS",
    actionYes: "Ja",
    actionNo: "Nej",
    actionNext: "NÄSTA",
    actionBusinessCase: "VISA AFFÄRSCASE",
    actionBusinessCases: "VISA AFFÄRSCASE",
    actionOpenUrl: "ÖPPNA URL",
    actionFilter: "FILTER",
    
    /** HOME */
    msgHomeTitleOne: "Mäta och öka ditt möbelföretags positiva påverkan",
    msgHomeSubTitle: "FurnCSR Online-verktyg utvärderar dina nuvarande CSR-framsteg baserat på ekonomisk, organisatorisk, samhällelig och miljöpåverkan",
    
    msgHomeSection2: "CSR-kraven expanderar.</br>Är du redo?",
    msgHomeSection2Text: "<ul><li>Svara på frågorna om din dagliga företagsverksamhet enligt de viktigaste normerna för påverkan.</li><li>Få din självbedömningspoäng för mognadsnivå.</li><li>Identifiera goda rutiner för att föra ditt företag till nästa nivå.</li><li>Lär från verkliga exempel inom möbelindustrin för att kontinuerligt öka din positiva påverkan.</li></ul>",
    msgHomeSection3: "Partners",
    msgHomeSection2Btn: "Starta din bedömning",
    msgHomeSection3Btn: "KONTAKT",
    
    /** HEADER */
    msgHeader1: "Goda rutiner",
    msgHeader4: "Logga in",
    
    /** DRAWER */
    msgCompany: "FÖRETAG",
    msgDrawer1: "FurnCSR-bedömning",
    msgDrawer2: "Prestandarapport",
    msgDrawer3: "Förbättringsrapport",
    msgDrawer4: "Uppsättning av goda rutiner",
    msgDrawer5: "Om FurnCSR-nivåer",
    msgDrawer6: "Inställningar",
    msgDrawer7: "Logga ut",
    msgDrawer8: "Utvärdera din erfarenhet",
    
    /** LOGIN */
    msgWelcome: "Välkommen",
    msgWelcomeSubtitle: "Vänligen logga in för att gå vidare",
    
    /** REGISTER */
    msgGetStarted: "Komma igång",
    msgGetStartedSubtitle: "Detta verktyg för påverkanhantering är gratis och konfidentiellt",
    
    msgFirstname: "Förnamn",
    msgLastname: "Efternamn",
    msgEmail: "E-post",
    msgPassword: "Lösenord",
    msgConfirmPassword: "Bekräfta lösenord",
    msgCompanyUser: "Företag eller användarnamn",
    
    msgNotRegister: "Inte registrerad ännu? <b>Skapa ett konto här</b>",
    msgAlreadyRegister: "Redan registrerad? <b>Logga in här</b>",
    
    /** HOME TOOL */
    msgClickImage: "Klicka för att expandera FurnCSR Business CANVAS",
    
    msgArea: "OMRÅDE",
    msgProgress: "VISADE FRÅGOR",
    
    msgAssessmentAreas: "FurnCSR Impact Areas",
    msgAssessmentDesc: "Alla frågor i FurnCSR-verktyget är organiserade efter de viktigaste affärsblocken i ett socialt ansvarsfullt möbelföretag Detta är FurnCSR Business CANVAS. Tillverkning och intern verksamhet finns på canvas vänstra sida, medan utbudet och externa upplevelser skildras till höger. Vi använder en trippelskiktsmetod (ekonomisk/organisatorisk, samhällelig och miljöpåverkan) för att utvärdera CSR-orienterad företagsverksamhet i varje block. ",
    
    msgAssessment: "FurnCSR-bedömning",
    msgNotApply: "Markera de svar som gäller den aktuella utvecklingen av ditt företag. om inga svar gäller, markera inte något alternativ.",
    msgMarkImportant: "Markera som viktigt",
    msgComment: "Kommentar",
    msgCommentHolder: "Här kan du anteckna relaterad information eller egna kommentarer.",
    
    /** PERFORMANCE REPORT */
    msgPerformance: "Prestandarapport",
    msgPerformanceSubtitle: "Kontrollera din självbedömningspoäng för mognadsnivå för varje affärsblock, samt en övergripande poäng.",
    msgCompanyLevel: "Ditt företags nivå",
    msgBlockArea: "BLOCKOMRÅDE",
    
    /** IMPROVEMENT REPORT */
    msgImprovement: "Förbättringsrapport",
    msgImprovementMainSubtitle: "Lär vad du kan göra för ständigt förbättra dig. Identifiera fler goda rutiner och affärscases för att ta ditt företag till nästa nivå.</br></br>Du kan starta med att läsa och inspireras av de som befinner sig i de lägre nivåerna och sedan på ett progressivt sätt försöka implementera de som befinner i de övre nivåerna. Kom ihåg att rutiner i nivå 1 och 2 måste följa lagar och förordningar, så att deras tillfredsställelse är en juridisk skyldighet och därför mycket viktig att uppfylla.",
    msgImprovementSubtitle: "Lär vad du kan göra för ständigt förbättra dig. Identifiera fler goda rutiner och affärscases för att ta ditt företag till nästa nivå.",
    msgLevels: "Nivåer",
    msgActionsToImplement: "Åtgärder att implementera:",
    msgBaselineScore: "Min baslinjepoäng",
    msgBusinessCaseEmpty: "Gratulerar! Du har redan implementerat de viktigaste goda rutinerna som är inkluderade i denna kategori",
    
    msgCat1: "EKONOMISKT OCH ORGANISATORISKT",
    msgCat2: "SAMHÄLLELIGT",
    msgCat3: "MILJÖMÄSSIGT",
    
    /** GOOD PRACTICES COLLECTION */
    msgGoodPracticesSubtitle: "Bläddra igenom och utforska den fullständiga uppsättningen av goda rutiner och affärsfall i FurnCSR Project",
    msgBusinessBlock: "Affärsblock",
    msgLevel: "Nivå",
    msgDimension: "Dimension",
    msgStep: "Step",
    
    /** SETTINGS */
    msgUserInfo: "Användarinformation",
    msgUserCredentials: "Användaruppgifter",
    
    /** MODAL */
    msgAreSure: "Är du säker?",
    
    msgSuccess: "Data uppdaterat",
    msgDeleteSuccess: "Data raderat",
    
    /** ERROR */
    errorOblData: "Fyll i alla obligatoriska fält",
    errorEmailFormat: "Felaktigt format för e-post",
    errorUserIncorrect: "Felaktig e-post/lösenord",
    errorSend: "Kommunikationsfel, vänligen försök igen senare",
    errorLength: "Antalet tillåtna tecken har överskridits",
    errorPassSame: "Lösenorden överensstämmer inte",
    
    /** FOOTER */
    msgFooterContact: "KONTAKT",
    msgFooterLinkedIn: "LINKEDIN",
    msgFooterTwitter: "TWITTER",
    
    msgFooterGr: "Detta projekt har finansierats av den europeiska kommissionens ansökningsomgång: Stöd för social dialog VP/2020/001",
    msgCopyright: "Copyright&copy;2023 AMBIT. Med ensamrätt",
    
    msgFooterLegal: "Juridisk meddelande",
    msgFooterPrivacy: "Sekretess",
    msgFooterCookies: "Cookie-policy",
    
    /** PROGRESS VALUES */
    msgProgress0: "Förbättringsområden",
    msgProgress1: "Regelefterlevnad",
    msgProgress2: "Regelförbättring",
    msgProgress3: "Uppfylla villkor",
    msgProgress4: "Engagemang",
    
    /** COMPANY SIZES */
    msgCompanySize: "Företagsstorlek",
    msgCompanySize1: "Mindre än 10 anställda",
    msgCompanySize2: "10-30 anställda",
    msgCompanySize3: "30-50 anställda",
    msgCompanySize4: "Över 50 anställda",
    
    /** PRODUCT TYPES */
    msgProductType: "Typ av produkter du tillverkar",
    msgProductType1: "Klädsel",
    msgProductType2: "Madresser",
    msgProductType3: "Klädselbottnar, kanapéer, sängbottnar",
    msgProductType4: "Underleverantör (ramar, bearbetning, lackering, etc.)",
    msgProductType5: "Tjänster (specialiserad logistik, etc.)",
    msgProductType6: "Skivmöbler (melamin, faner, lack, etc.)",
    msgProductType7: "Trämöbler",
    msgProductType8: "Stolar",
    msgProductType9: "Kontorsmöbler",
    msgProductType10: "Möbler för allmänna utrymmen och bruk",
    msgProductType11: "Dörrar, fönster och snickeriprodukter",
    msgProductType12: "Skräddarsydda lokaler (butiker, bibliotek, restauranger, professionella företag, etc.)",
    msgProductType13: "Belysning",
    msgProductType14: "Beläggningar och golv",
    msgProductType15: "Textil",
    msgProductType16: "Dekorationer",

    /** ABOUT */
    msgAboutSec1Title: "Introduktion till FurnCSR modell för en framstegsriktlinje",
    msgAboutSec1Text1:"Den framstegsriktlinje som vi presenterar i FurnCSR gör att vi kan sätta fingret på organisationens CSR-rutiner samtidigt som frånvaron av relevanta rutiner i en del rutor får oss att upptäcka eventuella luckor som vi kan ta itu med.</br></br>Framstegsriktlinjen som formulerats utifrån de metodologiska grunderna för CSR visar globalt och på ett strukturerat sätt vid vilken position en organisation konsolideras och vilken väg den måste ta när det gäller hantering av CSR</br></br>Modellen baseras på fem nivåer, som duplicerats till två kolumner, har 10 grader från områden för förbättring till maximal nivå av engagemang. Vi visar den grundläggande modellen på ett förenklat sätt med målet att tillhandahålla andra förslag för goda rutiner som skiljer sig från de tidigare (dvs. kapitel 4) på så sätt att de möjliggör framsteg för den integrerade utvecklingen av en CSR-modell</br></br>Följande figurer visar de olika nivåerna och graderna av mognad när det gäller CSR</br></br>I den första kolumnen (ruta 0) observerar vi de olika graderna mellan (icke-)efterlevnad av lagstiftningen och den maximala nivån av engagemang (ruta 9). I kolumnen till höger som visar högre proaktivitet och grunderna för den unika metodologin för CSR och närvaron av intressenter integreras gradvis och framsteget i logiken för företagsmedborgarskap visas. CSR kräver att man reparerar, säkerställer, förstår, tar ansvar och skapar gemensamma värden utanför vad som motsvarar en mer intern logisk hantering. Det är inte tillräckligt att göra saker med den bästa tänkbara avsikten utan du måste generera effekter med autenticitet och med de berörda parternas förtroende",
    msgAboutSec1Text2: "Detta är en framgångsväg till överlägsenhet och att vara i centrala positioner kan redan det vara av stort värde för möbelföretaget. Det är viktigt att inte lämna några ämnen i de nedre områdena och att ha en vision om hur man flyttar sig framåt genom att integrera inte bara de goda rutiner som visades i föregående kapitel utan även de som visas nedan som är placerade på de högre nivåerna i denna modell. Sedan kan framstegsriktlinjen tillämpas på var och en av de tre dimensionerna för CSR-hantering enligt deras egna specifika egenskaper och behålla samma väg hela tiden vilket är det som gör det möjligt att förstå utvecklingen av graden av CSR-mognad i möbelföretaget",

    msgAboutLevel1Title: "Förbättringsområden (steg 0 och 1)",
    msgAboutLevel1Text: "Denna nivå motsvarar förbättringsområdena och antyder att det finns allvarliga eller diverse olika oöverensstämmelser, både lagstadgade och relaterade åtaganden eller också är den fortfarande i korrigerings- och reparationsfasen.",

    msgAboutLevel2Title: "Regelefterlevnad (steg 2 och 3)",
    msgAboutLevel2Text: "Denna nivå motsvarar tillämpliga bestämmelser och innebär att den uppfyller och garanterar överensstämmelse för skyldigheterna oavsett om de är lagstadgade eller kommer från de frivilliga åtagandena. För en organisation som är engagerad för CSR är efterlevnad av lagen inte enbart relaterad till den bokstavliga texten utan dess avsikt är dessutom att regel- och tillsynsmyndigheten å samhällets vägnar strävar efter att undvika att bryta mot lagen. På nivån överenstämmelse med lagstiftningen specificeras rutiner som är relaterade till de viktigaste rättsliga kraven för branschen eftersom man inte skulle kunna börja ta itu med CSR-hantering utan detta",

    msgAboutLevel3Title: "Regelförbättring (steg 4 och 5)",
    msgAboutLevel3Text: "Denna nivå motsvarar regelförbättring och innebär att den går utanför de lagstadgade skyldigheterna med implementering av bästa praxis. För en organisation som är engagerad för CSR äger samtal med intressenter rum för att förstå själva hållbarhetskontexten",

    msgAboutLevel4Title: "Uppfylla villkor (steg 6 och 7)",
    msgAboutLevel4Text: "Denna nivå motsvarar uppfyllandet av förväntningarna och innebär att man tar itu med intressenternas förväntningar med hjälp av en systematiserad CSR-hantering (planering, åtgärder, integration i processer och procedurer, utvärdering, ständig förbättring ...). För en organisation som är engagerad för CSR åtar sig denna organisation transparent ansvarighet gentemot intressenterna.",

    msgAboutLevel5Title: "Engagemang (steg 8 och 9)",
    msgAboutLevel5Text: "Denna nivå motsvarar ett engagemang och innebär att en engagerad hantering utförs med medvetande och ledarskap, explicit och permanent, omfattande, länkad till strategisk planering och ett högre ändamål med en inriktning mot att skapa gemensamma värden med intressenter",

    /* COUNTRIES */
    msgCountry: "Land",
    msgAfghanistan: "Afghanistan",
    msgAlbania: "Albanien",
    msgAlgeria: "Algeriet",
    msgAmericanSamoa: "Amerikanska Samoa",
    msgAndorra: "Andorra",
    msgAngola: "Angola",
    msgAnguilla: "Anguilla",
    msgAntarctica: "Antarktis",
    msgAntiguaandBarbuda: "Antigua och Barbuda",
    msgArgentina: "Argentina",
    msgArmenia: "Armenien",
    msgAruba: "Aruba",
    msgAustralia: "Australien",
    msgAustria: "Österrike",
    msgAzerbaijan: "Azerbajdzjan",
    msgBahamas: "Bahamas",
    msgBahrain: "Bahrain",
    msgBangladesh: "Bangladesh",
    msgBarbados: "Barbados",
    msgBelarus: "Belarus",
    msgBelgium: "Belgien",
    msgBelize: "Belize",
    msgBenin: "Benin",
    msgBermuda: "Bermuda",
    msgBhutan: "Bhutan",
    msgBolivia: "Bolivia",
    msgBosniaHerzegovina: "Bosnien och Hercegovina",
    msgBotswana: "Botswana",
    msgBouvetIsland: "Bouvetön",
    msgBrazil: "Brasilien",
    msgBritishIndian: "Brittiska territoriet i Indiska oceanen",
    msgBrunei: "Brunei",
    msgBulgaria: "Bulgarien",
    msgBurkinaFaso: "Burkina Faso",
    msgBurundi: "Burundi",
    msgCambodia: "Kambodja",
    msgCameroon: "Kamerun",
    msgCanada: "Kanada",
    msgCapeVerde: "Cap Verde",
    msgCaymanIslands: "Caymanöarna",
    msgCentralAfrican: "Centralafrikanska republiken",
    msgChad: "Tchad",
    msgChile: "Chile",
    msgChina: "Kina",
    msgChristmasIsland: "Julön",
    msgCocosIslands: "Cocos (Keeling) Islands",
    msgColombia: "Colombia",
    msgComoros: "Komorerna",
    msgCongo: "Kongo",
    msgRepublicCongo: "Demokratiska republiken Kongo",
    msgCookIslands: "Cooköarna",
    msgCostaRica: "Costa Rica",
    msgIvoryCoast: "Elfenbenskusten",
    msgCroatia: "Kroatien",
    msgCuba: "Kuba",
    msgCyprus: "Cypern",
    msgCzechRepublic: "Tjeckien",
    msgDenmark: "Danmark",
    msgDjibouti: "Djibouti",
    msgDominica: "Dominica",
    msgDominicanRepublic: "Dominikanska republiken",
    msgEastTimor: "Östtimor",
    msgEcuador: "Ecuador",
    msgEgypt: "Egypten",
    msgEngland: "England",
    msgElSalvador: "El Salvador",
    msgEquatorialGuinea: "Ekvatorialguinea",
    msgEritrea: "Eritrea",
    msgEstonia: "Estland",
    msgEthiopia: "Etiopien",
    msgFalklandIslands: "Falklandsöarna",
    msgFaroeIslands: "Färöarna",
    msgFijiIslands: "Fijiöarna",
    msgFinland: "Finland",
    msgFrance: "Frankrike",
    msgFrenchGuiana: "Franska Guyana",
    msgFrenchPolynesia: "Franska Polynesien",
    msgFrenchSouthern: "franska sydliga territorier",
    msgGabon: "Gabon",
    msgGambia: "Gambia",
    msgGeorgia: "Georgien",
    msgGermany: "Tyskland",
    msgGhana: "Ghana",
    msgGibraltar: "Gibraltar",
    msgGreece: "Grekland",
    msgGreenland: "Grönland",
    msgGrenada: "Grenada",
    msgGuadeloupe: "Guadeloupe",
    msgGuam: "Guam",
    msgGuatemala: "Guatemala",
    msgGuernsey: "Guernsey",
    msgGuinea: "Guinea",
    msgGuineaBissau: "Guinea-Bissau",
    msgGuyana: "Guyana",
    msgHaiti: "Haiti",
    msgHeardMcDonald: "Heard Island och McDonald Islands",
    msgHolySee: "Heliga stolen (Vatikanstaten)",
    msgHonduras: "Honduras",
    msgHongKong: "Hong Kong",
    msgHungary: "Ungern",
    msgIceland: "Island",
    msgIndia: "Indien",
    msgIndonesia: "Indonesien",
    msgIran: "Iran",
    msgIraq: "Irak",
    msgIreland: "Irland",
    msgIsrael: "Israel",
    msgIsleMan: "ö av man",
    msgItaly: "Italien",
    msgJamaica: "Jamaica",
    msgJapan: "Japan",
    msgJersey: "Jersey",
    msgJordan: "Jordanien",
    msgKazakhstan: "Kazakstan",
    msgKenya: "Kenya",
    msgKiribati: "Kiribati",
    msgKuwait: "Kuwait",
    msgKyrgyzstan: "Kirgizistan",
    msgLaos: "Laos",
    msgLatvia: "Lettland",
    msgLebanon: "Libanon",
    msgLesotho: "Lesotho",
    msgLiberia: "Liberia",
    msgLibyanJamahiriya: "Libyens arabiska Jamahiriya",
    msgLiechtenstein: "Liechtenstein",
    msgLithuania: "Litauen",
    msgLuxembourg: "Luxemburg",
    msgMacao: "Macao",
    msgNorthMacedonia: "Nordmakedonien",
    msgMadagascar: "Madagaskar",
    msgMalawi: "Malawi",
    msgMalaysia: "Malaysia",
    msgMaldives: "Maldiverna",
    msgMali: "Mali",
    msgMalta: "Malta",
    msgMarshallIslands: "Marshallöarna",
    msgMartinique: "Martinique",
    msgMauritania: "Mauretanien",
    msgMauritius: "Mauritius",
    msgMayotte: "Mayotte",
    msgMexico: "Mexiko",
    msgMicronesia: "Mikronesien, federerade stater av",
    msgMoldova: "Moldavien",
    msgMonaco: "Monaco",
    msgMongolia: "mongoliet",
    msgMontserrat: "Montserrat",
    msgMontenegro: "Montenegro",
    msgMorocco: "Marocko",
    msgMozambique: "Moçambique",
    msgMyanmar: "Myanmar",
    msgNamibia: "Namibia",
    msgNauru: "Nauru",
    msgNepal: "Nepal",
    msgNetherlands: "Nederländerna",
    msgNetherlandsAntilles: "Nederländska Antillerna",
    msgNewCaledonia: "Nya Kaledonien",
    msgNewZealand: "Nya Zeeland",
    msgNicaragua: "Nicaragua",
    msgNiger: "Niger",
    msgNigeria: "Nigeria",
    msgNiue: "Niue",
    msgNorfolkIsland: "Norfolkön",
    msgNorthKorea: "Nordkorea",
    msgNorthernIreland: "Norra Irland",
    msgNorthernMariana: "Nordmarianerna",
    msgNorway: "Norge",
    msgOman: "oman",
    msgPakistan: "Pakistan",
    msgPalau: "Palau",
    msgPalestine: "Palestina",
    msgPanama: "Panama",
    msgPapua: "Papua Nya Guinea",
    msgParaguay: "Paraguay",
    msgPeru: "Peru",
    msgPhilippines: "Filippinerna",
    msgPitcairn: "Pitcairn",
    msgPoland: "Polen",
    msgPortugal: "Portugal",
    msgPuertoRico: "Puerto Rico",
    msgQatar: "Qatar",
    msgReunion: "Återförening",
    msgRomania: "Rumänien",
    msgRussianFederation: "Ryska Federationen",
    msgRwanda: "Rwanda",
    msgSaintHelena: "Sankt Helena",
    msgSaintKitts: "Saint Kitts och Nevis",
    msgSaintLucia: "Saint Lucia",
    msgSaintPierre: "Saint Pierre och Miquelon",
    msgSaintVincent: "Saint Vincent och Grenadinerna",
    msgSamoa: "Samoa",
    msgSanMarino: "San Marino",
    msgSaoTome: "Sao Tomé och Principe",
    msgSaudiArabia: "Saudiarabien",
    msgScotland: "Skottland",
    msgSenegal: "Senegal",
    msgSerbia: "Serbien",
    msgSeychelles: "Seychellerna",
    msgSierraLeone: "Sierra Leone",
    msgSingapore: "Singapore",
    msgSlovakia: "Slovakien",
    msgSlovenia: "Slovenien",
    msgSolomonIslands: "Salomonöarna",
    msgSomalia: "Somalia",
    msgSouthAfrica: "Sydafrika",
    msgSouthSandwich: "South Georgia och South Sandwich Islands",
    msgSouthKorea: "Sydkorea",
    msgSouthSudan: "södra Sudan",
    msgSpain: "Spanien",
    msgSriLanka: "Sri Lanka",
    msgSudan: "Sudan",
    msgSuriname: "Surinam",
    msgSvalbardJan: "Svalbard och Jan Mayen",
    msgSwaziland: "Swaziland",
    msgSweden: "Sverige",
    msgSwitzerland: "Schweiz",
    msgSyria: "Syrien",
    msgTajikistan: "Tadzjikistan",
    msgTanzania: "Tanzania",
    msgThailand: "Thailand",
    msgTimorLeste: "Östtimor",
    msgTogo: "Togo",
    msgTokelau: "Tokelau",
    msgTonga: "Tonga",
    msgTrinidadTobago: "Trinidad och Tobago",
    msgTunisia: "Tunisien",
    msgTurkey: "Kalkon",
    msgTurkmenistan: "Turkmenistan",
    msgTurksCaicos: "Turks- och Caicosöarna",
    msgTuvalu: "Tuvalu",
    msgUganda: "Uganda",
    msgUkraine: "Ukraina",
    msgUnitedArabEmirates: "Förenade arabemiraten",
    msgUnitedKingdom: "Storbritannien",
    msgUnitedStates: "Förenta staterna",
    msgUnitedStatesMinor: "Förenta staternas mindre avlägsna öar",
    msgUruguay: "Uruguay",
    msgUzbekistan: "Uzbekistan",
    msgVanuatu: "Vanuatu",
    msgVenezuela: "Venezuela",
    msgVietnam: "Vietnam",
    msgVirginIslandsBritish: "Jungfruöarna, brittiska",
    msgVirginIslandsUS: "Jungfruöarna, USA",
    msgWales: "Wales",
    msgWallisFutuna: "Wallis och Futuna",
    msgWesternSahara: "Västra Sahara",
    msgYemen: "Jemen",
    msgZambia: "Zambia",
    msgZimbabwe: "Zimbabwe",
}

export default sv;