const it = {
    actionAlready: "Disponi già di un account? Accedi",
    actionExit: "Logout",
    actionLogin: "ACCEDI",
    actionRegister: "REGISTRATI",
    actionSave: "SALVA",
    actionStart: "INIZIA",
    actionContinue: "CONTINUA",
    actionReset: "RIPRISTINA VALUTAZIONE",
    actionDoDiagnost: "MOSTRA DIAGNOSI",
    actionYes: "Sì",
    actionNo: "No",
    actionNext: "AVANTI",
    actionBusinessCase: "MOSTRA CASO AZIENDALE",
    actionBusinessCases: "MOSTRA CASI AZIENDALI",
    actionOpenUrl: "APRI URL",
    actionFilter: "FILTRA",
    
    /** HOME */
    msgHomeTitleOne: "Misura e aumenta l’impatto positivo della tua azienda del settore legno-arredo",
    msgHomeSubTitle: "Lo strumento online FurnCSR valuta il tuo avanzamento attuale in materia di RSI in base agli impatti economici e organizzativi, sociali e ambientali",
    
    msgHomeSection2: "I requisiti della RSI stanno aumentando.</br>Sei pronto?",
    msgHomeSection2Text: "<ul><li>Rispondi alle domande sulle tue attività quotidiane in base ai principali standard dell’impatto.</li><li>Ottieni il tuo punteggio di autovalutazione del livello di maturità.</li><li>Identifica le buone pratiche per portare la tua azienda al livello successivo.</li><li>Impara da esempi pratici reali del settore legno-arredo per aumentare in modo continuo l’impatto positivo.</li></ul>",
    msgHomeSection3: "Partner",
    msgHomeSection2Btn: "Inizia la valutazione",
    msgHomeSection3Btn: "CONTATTO",
    
    /** HEADER */
    msgHeader1: "Buone pratiche",
    msgHeader4: "Login",
    
    /** DRAWER */
    msgCompany: "AZIENDA",
    msgDrawer1: "Valutazione FurnCSR",
    msgDrawer2: "Report sulle prestazioni",
    msgDrawer3: "Report sul miglioramento",
    msgDrawer4: "Raccolta di buone pratiche",
    msgDrawer5: "Informazioni sui livelli di FurnCSR",
    msgDrawer6: "Impostazioni",
    msgDrawer7: "Logout",
    msgDrawer8: "Valuta la tua esperienza",
    
    /** LOGIN */
    msgWelcome: "Benvenuto",
    msgWelcomeSubtitle: "Accedi per continuare",
    
    /** REGISTER */
    msgGetStarted: "Guida introduttiva",
    msgGetStartedSubtitle: "Questo strumento per la gestione dell’impatto è gratuito e riservato",
    
    msgFirstname: "Nome",
    msgLastname: "Cognome",
    msgEmail: "Email",
    msgPassword: "Password",
    msgConfirmPassword: "Conferma password",
    msgCompanyUser: "Azienda o nome utente",
    
    msgNotRegister: "Non sei ancora registrato? <b>Crea un account da qui</b>",
    msgAlreadyRegister: "Sei già registrato? <b>Accedi da qui</b>",
    
    /** HOME TOOL */
    msgClickImage: "Fai clic per espandere il Business CANVAS di FurnCSR.",
    
    msgArea: "AREA",
    msgProgress: "DOMANDE VISTE",
    
    msgAssessmentAreas: "Aree di impatto di FurnCSR",
    msgAssessmentDesc: "Tutte le domande presenti nello strumento FurnCSR sono organizzate seguendo i principali blocchi aziendali di un’azienda del settore legno-arredo socialmente responsabile. Questo è il Business CANVAS di FurnCSR. La produzione e le operazioni interne si trovano sul lato sinistro del canvas, mentre l’offerta e le esperienze esterne sono riportate sul lato destro. Utilizziamo un approccio a tre livelli (economico/organizzativo, sociale e ambientale) per valutare le attività aziendali orientate alla RSI in ciascun blocco. ",
    
    msgAssessment: "Valutazione FurnCSR",
    msgNotApply: "Seleziona le risposte applicabili allo sviluppo attuale della tua azienda. Qualora non sia applicabile nessuna risposta, non selezionare nessuna opzione.",
    msgMarkImportant: "Seleziona come importante",
    msgComment: "Commento",
    msgCommentHolder: "Qui puoi inserire qualsiasi informazione pertinente o i tuoi commenti personali.",
    
    /** PERFORMANCE REPORT */
    msgPerformance: "Report sulle prestazioni",
    msgPerformanceSubtitle: "Verifica il tuo punteggio di autovalutazione del livello di maturità per ogni blocco aziendale e per quello globale.",
    msgCompanyLevel: "Livello della tua azienda",
    msgBlockArea: "AREA DEL BLOCCO",
    
    /** IMPROVEMENT REPORT */
    msgImprovement: "Report sul miglioramento",
    msgImprovementMainSubtitle: "Scopri cosa puoi fare per migliorare in modo continuo. Identifica altre buone pratiche e casi aziendali per portare la tua azienda al livello successivo.</br></br>Puoi iniziare a leggere e trarre ispirazioni dai livelli inferiori e poi cercare di implementare le informazioni riportate nei livelli superiori in modo progressivo. Ricorda che le pratiche dei livelli 1 e 2 devono essere conformi ai requisiti legali e normativi, pertanto soddisfarle è un obbligo legale molto importante.",
    msgImprovementSubtitle: "Scopri cosa puoi fare per migliorare in modo continuo. Identifica altre buone pratiche e casi aziendali per portare la tua azienda al livello successivo.",
    msgLevels: "Livelli",
    msgActionsToImplement: "Azioni da implementare:",
    msgBaselineScore: "Il mio punteggio di base",
    msgBusinessCaseEmpty: "Congratulazioni! Hai già implementato le buone pratiche principali incluse in questa categoria",
    
    msgCat1: "ECONOMICO E ORGANIZZATIVO",
    msgCat2: "SOCIALE",
    msgCat3: "AMBIENTALE",
    
    /** GOOD PRACTICES COLLECTION */
    msgGoodPracticesSubtitle: "Sfoglia ed esplora la raccolta completa di buone pratiche e casi aziendali del Progetto FurnCSR",
    msgBusinessBlock: "Blocco aziendale",
    msgLevel: "Livello",
    msgDimension: "Dimensioni",
    msgStep: "Step",
    
    /** SETTINGS */
    msgUserInfo: "Informazioni dell'utente",
    msgUserCredentials: "Credenziali dell'utente",
    
    /** MODAL */
    msgAreSure: "Sei sicuro?",
    
    msgSuccess: "Aggiornamento dei dati riuscito",
    msgDeleteSuccess: "Cancellazione dei dati riuscita",
    
    /** ERROR */
    errorOblData: "Compila tutti i campi obbligatori",
    errorEmailFormat: "Errore nel formato dell'email",
    errorUserIncorrect: "Email/password errata",
    errorSend: "Errore di comunicazione, attendere e riprovare più tardi",
    errorLength: "Numero di caratteri superato",
    errorPassSame: "Le password non sono uguali",
    
    /** FOOTER */
    msgFooterContact: "CONTATTO",
    msgFooterLinkedIn: "LINKEDIN",
    msgFooterTwitter: "TWITTER",
    
    msgFooterGr: "Il presente progetto è stato finanziato dall’iniziativa della Commissione europea: Sostegno al dialogo sociale VP/2020/001",
    msgCopyright: "Copyright&copy;2023 AMBIT. Tutti i diritti riservati",
    
    msgFooterLegal: "Nota legale",
    msgFooterPrivacy: "Privacy",
    msgFooterCookies: "Politica sui cookie",
    
    /** PROGRESS VALUES */
    msgProgress0: "Aree di miglioramento",
    msgProgress1: "Conformità normativa",
    msgProgress2: "Miglioramento normativo",
    msgProgress3: "Soddisfazione delle aspettative",
    msgProgress4: "Impegno",
    
    /** COMPANY SIZES */
    msgCompanySize: "Dimensioni dell'azienda",
    msgCompanySize1: "Meno di 10 dipendenti",
    msgCompanySize2: "10-30 dipendenti",
    msgCompanySize3: "30-50 dipendenti",
    msgCompanySize4: "Più di 50 dipendenti",
    
    /** PRODUCT TYPES */
    msgProductType: "Tipo di prodotti fabbricati",
    msgProductType1: "Tappezzeria",
    msgProductType2: "Materassi",
    msgProductType3: "Basi per tappezzeria, canapè, basi letto",
    msgProductType4: "Subappaltatore (telai, lavorazione, verniciatura, ecc.)",
    msgProductType5: "Servizi (logistica specializzata, ecc.)",
    msgProductType6: "Mobili in materiali pannellati (melaminici, impiallacciati, laccati, ecc.)",
    msgProductType7: "Mobili in legno",
    msgProductType8: "Sedie",
    msgProductType9: "Mobili per ufficio",
    msgProductType10: "Mobili per spazi e usi pubblici",
    msgProductType11: "Porte, finestre e prodotti di falegnameria",
    msgProductType12: "Arredamento personalizzato (negozi, biblioteche, ristoranti, attività professionali, ecc.)",
    msgProductType13: "Lampade",
    msgProductType14: "Rivestimenti e pavimentazioni",
    msgProductType15: "Tessuti",
    msgProductType16: "Decorazioni",

    /** ABOUT */
    msgAboutSec1Title: "Introduzione al modello della linea guida sull’avanzamento di FurnCSR",
    msgAboutSec1Text1:"La linea guida sull’avanzamento che presentiamo in FurnCSR ci permette di individuare le pratiche di CSR dell’azienda, mentre l’assenza di pratiche rilevanti in alcuni ambiti ci consentirà di individuare eventuali lacune da colmare.</br></br>Concepita a partire dai fondamenti metodologici della CSR, la linea guida sull’avanzamento mostra, in modo globale e strutturato, la posizione in cui un’azienda è consolidata e il percorso che deve intraprendere nella gestione della CSR.</br></br>Il modello si basa su cinque livelli duplicati in due colonne che offrono 10 gradi, dalle aree di miglioramento al massimo livello di impegno. Mostriamo il modello di base in modo semplificato allo scopo di fornire ulteriori proposte di buone pratiche diverse da quelle riportate in precedenza (nel Capitolo 4), che consentiranno di avanzare nello sviluppo integrato di un modello di CSR.</br></br>Le seguenti figure mostrano i diversi livelli e gradi di maturità riguardanti la CSR.</br></br>Risalendo la prima colonna (riquadro 0) si osservano i diversi gradi tra la (non)conformità legale e il massimo livello di impegno (riquadro 9). Risalendo la colonna di destra, che mostra una maggiore proattività e i fondamenti della metodologia unica della CSR, si integra progressivamente la presenza degli stakeholder e si evidenziano progressi nella logica della cittadinanza d’impresa. La CSR chiede di ripristinare, garantire, comprendere, rendere conto e creare valore condiviso al di là di ciò che corrisponde a una logica di gestione più interna; non basta fare le cose con le migliori intenzioni possibili, è necessario generare impatti, con autenticità e con la fiducia degli stakeholder.",
    msgAboutSec1Text2: "È un percorso di avanzamento verso l’eccellenza e trovarsi nelle posizioni intermedie può già essere di grande valore per le aziende del settore legno-arredo. Sarà importante non abbandonare gli argomenti riportati nelle aree più basse e avere una visione del modo in cui andare avanti integrando non solo le buone pratiche mostrate nel capitolo precedente, ma anche quelle mostrate di seguito, situate nei livelli più alti di questo modello. Successivamente, la linea guida sull’avanzamento può essere applicata a ciascuno dei tre aspetti della gestione della CSR in base alle proprie specificità, mantenendo sempre lo stesso percorso, che è quello che consente di comprendere l’evoluzione del grado di maturità della CSR nelle aziende del settore legno-arredo.",

    msgAboutLevel1Title: "Aree di miglioramento (fasi 0 e 1)",
    msgAboutLevel1Text: "Questo livello corrisponde alle aree di miglioramento e implica la presenza di gravi o diverse non conformità, sia legali che relative agli impegni, o che sono ancora in fase di correzione e riparazione",

    msgAboutLevel2Title: "Conformità normativa (fasi 2 e 3)",
    msgAboutLevel2Text: "Questo livello corrisponde alla conformità normativa e indica che si adempie e si garantisce il rispetto degli obblighi, siano essi legali o derivanti da impegni volontari. Per un’azienda impegnata nella CSR, la conformità alla legge non è solo legata al testo letterale, ma anche all’intenzione che il regolatore cerca di perseguire per conto della società, evitando di infrangere la legge. A livello di conformità normativa, si specificano le pratiche relative al rispetto dei principali requisiti legali per il settore, poiché senza di esse la gestione della CSR non potrebbe iniziare a essere affrontata.",

    msgAboutLevel3Title: "Miglioramento normativo (fasi 4 e 5)",
    msgAboutLevel3Text: "Questo livello corrisponde al miglioramento normativo e significa che si superano gli obblighi di legge con l’implementazione di migliori pratiche. Un’azienda impegnata nella CSR dialoga con gli stakeholder per comprendere il contesto stesso della sostenibilità.",

    msgAboutLevel4Title: "Soddisfazione delle aspettative (fasi 6 e 7)",
    msgAboutLevel4Text: "Questo livello corrisponde alla soddisfazione delle aspettative, riferendosi alle aspettative degli stakeholder che vengono affrontate mediante una gestione sistematica della CSR (pianificazione, azioni, integrazione nei processi e nelle procedure, valutazione, miglioramento continuo, ecc.). Per un’azienda impegnata nella CSR, si implementa una responsabilità trasparente nei confronti degli stakeholder.",

    msgAboutLevel5Title: "Impegno (fasi 8 e 9)",
    msgAboutLevel5Text: "Questo livello corrisponde all’impegno e indica che viene portata avanti una gestione impegnata, con consapevolezza e leadership, esplicita e permanente, completa, collegata alla pianificazione strategica e a uno scopo superiore, con un orientamento verso la creazione di valore condiviso con gli stakeholder.",

    /* COUNTRIES */
    msgCountry: "Paese",
    msgAfghanistan: "Afghanistan",
    msgAlbania: "Albania",
    msgAlgeria: "Algeria",
    msgAmericanSamoa: "Samoa americane",
    msgAndorra: "Andorra",
    msgAngola: "Angola",
    msgAnguilla: "Anguilla",
    msgAntarctica: "Antartide",
    msgAntiguaandBarbuda: "Antigua e Barbuda",
    msgArgentina: "Argentina",
    msgArmenia: "Armenia",
    msgAruba: "Aruba",
    msgAustralia: "Australia",
    msgAustria: "Austria",
    msgAzerbaijan: "Azerbaigian",
    msgBahamas: "Bahamas",
    msgBahrain: "Bahrein",
    msgBangladesh: "Bangladesh",
    msgBarbados: "Barbados",
    msgBelarus: "Bielorussia",
    msgBelgium: "Belgio",
    msgBelize: "Belize",
    msgBenin: "Benin",
    msgBermuda: "Bermude",
    msgBhutan: "Bhutan",
    msgBolivia: "Bolivia",
    msgBosniaHerzegovina: "Bosnia Erzegovina",
    msgBotswana: "Botswana",
    msgBouvetIsland: "Isola Bouvet",
    msgBrazil: "Brasile",
    msgBritishIndian: "Territorio britannico dell'Oceano Indiano",
    msgBrunei: "Brunei",
    msgBulgaria: "Bulgaria",
    msgBurkinaFaso: "Burkina Faso",
    msgBurundi: "Burundi",
    msgCambodia: "Cambogia",
    msgCameroon: "Camerun",
    msgCanada: "Canada",
    msgCapeVerde: "capo Verde",
    msgCaymanIslands: "Isole Cayman",
    msgCentralAfrican: "Repubblica Centrafricana",
    msgChad: "Chad",
    msgChile: "Chile",
    msgChina: "Cina",
    msgChristmasIsland: "Isola di Natale",
    msgCocosIslands: "Isole Cocos (Keeling).",
    msgColombia: "Colombia",
    msgComoros: "Comore",
    msgCongo: "Congo",
    msgRepublicCongo: "La Repubblica Democratica del Congo",
    msgCookIslands: "Isole Cook",
    msgCostaRica: "Costa Rica",
    msgIvoryCoast: "Costa d'Avorio",
    msgCroatia: "Croazia",
    msgCuba: "Cuba",
    msgCyprus: "Cipro",
    msgCzechRepublic: "Repubblica Ceca",
    msgDenmark: "Danimarca",
    msgDjibouti: "Gibuti",
    msgDominica: "Domenico",
    msgDominicanRepublic: "Repubblica Dominicana",
    msgEastTimor: "Timor Est",
    msgEcuador: "Ecuador",
    msgEgypt: "Egitto",
    msgEngland: "Inghilterra",
    msgElSalvador: "El Salvador",
    msgEquatorialGuinea: "Guinea Equatoriale",
    msgEritrea: "Eritrea",
    msgEstonia: "Estonia",
    msgEthiopia: "Etiopia",
    msgFalklandIslands: "Isole Falkland",
    msgFaroeIslands: "Isole Faroe",
    msgFijiIslands: "Isole Figi",
    msgFinland: "Finlandia",
    msgFrance: "Francia",
    msgFrenchGuiana: "Guiana francese",
    msgFrenchPolynesia: "Polinesia francese",
    msgFrenchSouthern: "Territori della Francia del sud",
    msgGabon: "Gabon",
    msgGambia: "Gambia",
    msgGeorgia: "Georgia",
    msgGermany: "Germania",
    msgGhana: "Ghana",
    msgGibraltar: "Gibilterra",
    msgGreece: "Grecia",
    msgGreenland: "Groenlandia",
    msgGrenada: "Granada",
    msgGuadeloupe: "Guadalupa",
    msgGuam: "Guam",
    msgGuatemala: "Guatemala",
    msgGuernsey: "Guernsey",
    msgGuinea: "Guinea",
    msgGuineaBissau: "Guinea Bissau",
    msgGuyana: "Guyana",
    msgHaiti: "Haiti",
    msgHeardMcDonald: "Isole Heard e McDonald",
    msgHolySee: "Santa Sede (Stato della Città del Vaticano)",
    msgHonduras: "Honduras",
    msgHongKong: "Hong Kong",
    msgHungary: "Ungheria",
    msgIceland: "Islanda",
    msgIndia: "India",
    msgIndonesia: "Indonesia",
    msgIran: "Iran",
    msgIraq: "Iraq",
    msgIreland: "Irlanda",
    msgIsrael: "Israele",
    msgIsleMan: "Isola di Man",
    msgItaly: "Italia",
    msgJamaica: "Giamaica",
    msgJapan: "Giappone",
    msgJersey: "Maglia",
    msgJordan: "Giordania",
    msgKazakhstan: "Kazakistan",
    msgKenya: "Kenya",
    msgKiribati: "Kiribati",
    msgKuwait: "Kuwait",
    msgKyrgyzstan: "Kirghizistan",
    msgLaos: "Laos",
    msgLatvia: "Lettonia",
    msgLebanon: "Libano",
    msgLesotho: "Lesoto",
    msgLiberia: "Liberia",
    msgLibyanJamahiriya: "Jamahiriya Araba Libica",
    msgLiechtenstein: "Liechtenstein",
    msgLithuania: "Lituania",
    msgLuxembourg: "Lussemburgo",
    msgMacao: "Macao",
    msgNorthMacedonia: "Macedonia del Nord",
    msgMadagascar: "Madagascar",
    msgMalawi: "Malawi",
    msgMalaysia: "Malaysia",
    msgMaldives: "Maldive",
    msgMali: "Mali",
    msgMalta: "Malta",
    msgMarshallIslands: "Isole Marshall",
    msgMartinique: "Martinica",
    msgMauritania: "Mauritania",
    msgMauritius: "Maurizio",
    msgMayotte: "Mayotta",
    msgMexico: "Messico",
    msgMicronesia: "Micronesia, Stati Federati di",
    msgMoldova: "Moldavia",
    msgMonaco: "Monaco",
    msgMongolia: "Mongolia",
    msgMontserrat: "Montserrat",
    msgMontenegro: "Montenegro",
    msgMorocco: "Marocco",
    msgMozambique: "Mozambico",
    msgMyanmar: "Birmania",
    msgNamibia: "Namibia",
    msgNauru: "Nauru",
    msgNepal: "Nepal",
    msgNetherlands: "Olanda",
    msgNetherlandsAntilles: "Antille Olandesi",
    msgNewCaledonia: "Nuova Caledonia",
    msgNewZealand: "Nuova Zelanda",
    msgNicaragua: "Nicaragua",
    msgNiger: "Niger",
    msgNigeria: "Nigeria",
    msgNiue: "Niue",
    msgNorfolkIsland: "Isola Norfolk",
    msgNorthKorea: "Corea del nord",
    msgNorthernIreland: "Irlanda del Nord",
    msgNorthernMariana: "Isole Marianne settentrionali",
    msgNorway: "Norvegia",
    msgOman: "Oman",
    msgPakistan: "Pakistan",
    msgPalau: "Palau",
    msgPalestine: "Palestina",
    msgPanama: "Panama",
    msgPapua: "Papua Nuova Guinea",
    msgParaguay: "Paraguay",
    msgPeru: "Perù",
    msgPhilippines: "Filippine",
    msgPitcairn: "Pitcairn",
    msgPoland: "Polonia",
    msgPortugal: "Portogallo",
    msgPuertoRico: "Porto Rico",
    msgQatar: "Qatar",
    msgReunion: "Riunione",
    msgRomania: "Romania",
    msgRussianFederation: "Federazione Russa",
    msgRwanda: "Ruanda",
    msgSaintHelena: "Sant'Elena",
    msgSaintKitts: "Saint Kitts e Nevis",
    msgSaintLucia: "Santa Lucia",
    msgSaintPierre: "San Pietro e Miquelon",
    msgSaintVincent: "Saint Vincent e Grenadine",
    msgSamoa: "Samoa",
    msgSanMarino: "San Marino",
    msgSaoTome: "Sao Tomè e Principe",
    msgSaudiArabia: "Arabia Saudita",
    msgScotland: "Scozia",
    msgSenegal: "Senegal",
    msgSerbia: "Serbia",
    msgSeychelles: "Seychelles",
    msgSierraLeone: "Sierra Leone",
    msgSingapore: "Singapore",
    msgSlovakia: "Slovacchia",
    msgSlovenia: "Slovenia",
    msgSolomonIslands: "Isole Salomone",
    msgSomalia: "Somalia",
    msgSouthAfrica: "Sud Africa",
    msgSouthSandwich: "Georgia del Sud e Isole Sandwich Australi",
    msgSouthKorea: "Corea del Sud",
    msgSouthSudan: "Sudan del Sud",
    msgSpain: "Spagna",
    msgSriLanka: "Sri Lanka",
    msgSudan: "Sudan",
    msgSuriname: "Suriname",
    msgSvalbardJan: "Svalbard e Jan Mayen",
    msgSwaziland: "Swaziland",
    msgSweden: "Svezia",
    msgSwitzerland: "Svizzera",
    msgSyria: "Siria",
    msgTajikistan: "Tagikistan",
    msgTanzania: "Tanzania",
    msgThailand: "Tailandia",
    msgTimorLeste: "Timor Est",
    msgTogo: "Andare",
    msgTokelau: "Tokelau",
    msgTonga: "Tonga",
    msgTrinidadTobago: "Trinidad e Tobago",
    msgTunisia: "Tunisia",
    msgTurkey: "Tacchino",
    msgTurkmenistan: "Turkmenistan",
    msgTurksCaicos: "Isole Turks e Caicos",
    msgTuvalu: "Tuvalù",
    msgUganda: "Uganda",
    msgUkraine: "Ucraina",
    msgUnitedArabEmirates: "Emirati Arabi Uniti",
    msgUnitedKingdom: "Regno Unito",
    msgUnitedStates: "stati Uniti",
    msgUnitedStatesMinor: "Isole minori esterne degli Stati Uniti",
    msgUruguay: "Uruguay",
    msgUzbekistan: "Uzbekistan",
    msgVanuatu: "Vanuatu",
    msgVenezuela: "Venezuela",
    msgVietnam: "Vietnam",
    msgVirginIslandsBritish: "Isole Vergini, britanniche",
    msgVirginIslandsUS: "Isole Vergini, Stati Uniti",
    msgWales: "Galles",
    msgWallisFutuna: "Wallis e Futuna",
    msgWesternSahara: "Sahara occidentale",
    msgYemen: "Yemen",
    msgZambia: "Zambia",
    msgZimbabwe: "Zimbabwe",
}

export default it;