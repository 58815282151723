import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTextLang, LanguageContext } from './providers/LanguageProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from './styled/Styled';
import { getLevelImage } from './util/BlockUtil';

const BlockLevel = ({ item }) => {
    const { language } = useContext(LanguageContext);
    let history = useNavigate();

    return <Container width="12" px="4" mb="8">
        <Button onClick={() => history("/improvement/level", { state: { item: item } })} flex shadowOn width="12" align="stretch" color="color-white">
            <Card flex justify="center" px="16" width="150px" radiusbr="0" radiustr="0" minheight="100%" maxheight="141px" color="color-purple">
                <img alt={`block-${item.id_block}`} height="100%" width="100%" src={getLevelImage(item)} />
            </Card>
            <ContainerFlex width="wrap" align="stretch">
                <Card flex width="12" align="center" color={"color-gray-card"} radiustl="0px" radiusbl="0px" radiusbr="0px">
                    <Container width="wrap" px="16" fontSize="18px" fontWeight="bold" fontColor="color-black" children={`${item.id_level} - ${getTextLang(item, 'name', language)}`} />
                    <Container width="12" height="1px" color="color-gray" />
                </Card>

                <ContainerFlex width="wrap" px="16" align="center">
                    <Card mr="8" pv="4"  ph="8" border="solid" fontSize="12px" fontColor="color-white" color="color-black" borderColor="color-black" borderWidth="2px" children={`${getTextLang(item.steps[0],'name', language)}`} />
                    <Card mr="8" mv="8" pv="4" ph="8" border="solid" fontSize="12px" fontColor="color-white" color="color-gray-home" borderColor="color-gray-home" borderWidth="2px" children={`${getTextLang(item.steps[1],'name', language)}`} />
                </ContainerFlex>
                <ContainerFlex align="center" ph="16">
                    <Container pv="4" ph="8" border="solid" fontSize="12px" fontColor="color-black" borderColor={"color-black"} borderWidth="2px" children={<TextLocalized children={"msgBaselineScore"} />} />
                    <Card flex justify="center" align="center" border="solid" borderColor="color-black" borderWidth="3px" radius="50%"
                        fontColor="color-black" fontWeight="bold"
                        height="54px" minwidth="54px" children={`${(item.score * 100).toFixed(0)}%`} />
                </ContainerFlex>
            </ContainerFlex>
        </Button>
    </Container>
};

export default BlockLevel;