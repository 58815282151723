const pl = {
    actionAlready: "Masz już konto? Zaloguj się",
    actionExit: "Wylogowanie",
    actionLogin: "ZALOGUJ SIĘ",
    actionRegister: "ZAREJESTRUJ SIĘ",
    actionSave: "ZAPISZ",
    actionStart: "ROZPOCZNIJ",
    actionContinue: "KONTYNUUJ",
    actionReset: "RESETUJ OCENĘ",
    actionDoDiagnost: "POKAŻ DIAGNOSTYKĘ",
    actionYes: "Tak",
    actionNo: "Nie",
    actionNext: "DALEJ",
    actionBusinessCase: "POKAŻ PRZYPADEK BIZNESOWY",
    actionBusinessCases: "POKAŻ PRZYPADKI BIZNESOWE",
    actionOpenUrl: "OTWÓRZ URL",
    actionFilter: "FILTR",
    
    /** HOME */
    msgHomeTitleOne: "Zmierz i zwiększ pozytywny wpływ swojej firmy meblarskiej",
    msgHomeSubTitle: "Narzędzie online FurnCSR ocenia Twój obecny postęp w zakresie CSR w oparciu o wpływ ekonomiczny i organizacyjny, społeczny i środowiskowy",
    
    msgHomeSection2: "Wymagania dotyczące CSR są coraz większe.</br>Czy jesteś przygotowany(-a)?",
    msgHomeSection2Text: "<ul><li>Odpowiedz na pytania dotyczące Twojej codziennej działalności biznesowej zgodnie z głównymi standardami wpływu.</li><li>Uzyskaj wynik samooceny poziomu dojrzałości.</li><li>Zidentyfikuj dobre praktyki, aby przenieść swoją firmę na kolejny poziom.</li><li>Ucz się na prawdziwych praktycznych przykładach z sektora meblarskiego, aby stale zwiększać swój pozytywny wpływ.</li></ul>",
    msgHomeSection3: "Partnerzy",
    msgHomeSection2Btn: "Rozpocznij ocenę",
    msgHomeSection3Btn: "KONTAKT",
    
    /** HEADER */
    msgHeader1: "Dobre praktyki",
    msgHeader4: "Logowanie",
    
    /** DRAWER */
    msgCompany: "FIRMA",
    msgDrawer1: "Ocena FurnCSR",
    msgDrawer2: "Raport wydajności",
    msgDrawer3: "Raport poprawy",
    msgDrawer4: "Zbiór dobrych praktyk",
    msgDrawer5: "Informacje o poziomach FurnCSR",
    msgDrawer6: "Ustawienia",
    msgDrawer7: "Wylogowanie",
    msgDrawer8: "Oceń swoje doświadczenie",
    
    /** LOGIN */
    msgWelcome: "Witamy",
    msgWelcomeSubtitle: "Zaloguj się, aby kontynuować",
    
    /** REGISTER */
    msgGetStarted: "Rozpocznij",
    msgGetStartedSubtitle: "To narzędzie do zarządzania wpływem jest bezpłatne i poufne",
    
    msgFirstname: "Imię",
    msgLastname: "Nazwisko",
    msgEmail: "E-mail",
    msgPassword: "Hasło",
    msgConfirmPassword: "Potwierdź hasło",
    msgCompanyUser: "Firma lub nazwa użytkownika",
    
    msgNotRegister: "Jeszcze się nie rejestrowałeś(-aś)? <b>Utwórz konto tutaj</b>",
    msgAlreadyRegister: "Już się zarejestrowałeś(-aś)? <b>Zaloguj się tutaj</b>",
    
    /** HOME TOOL */
    msgClickImage: "Kliknij, aby rozwinąć FurnCSR Business CANVAS.",
    
    msgArea: "OBSZAR",
    msgProgress: "WIDZIANE PYTANIA",
    
    msgAssessmentAreas: "Obszary wpływu FurnCSR",
    msgAssessmentDesc: "Wszystkie pytania w narzędziu FurnCSR są uporządkowane według głównych bloków biznesowych społecznie odpowiedzialnej firmy meblarskiej. To jest właśnie FurnCSR Business CANVAS. Produkcja i działania wewnętrzne znajdują się po lewej stronie płótna, natomiast oferta i doświadczenia zewnętrzne są przedstawione po prawej stronie. W każdym z bloków stosujemy trójwarstwowe podejście (ekonomiczne/organizacyjne, społeczne i środowiskowe) do oceny działań biznesowych zorientowanych na CSR. ",
    
    msgAssessment: "Ocena FurnCSR",
    msgNotApply: "Zaznacz odpowiedzi, które dotyczą aktualnego rozwoju Twojej firmy. jeśli żadna z odpowiedzi nie ma zastosowania, nie zaznaczaj żadnej opcji.",
    msgMarkImportant: "Zaznacz jako ważne",
    msgComment: "Komentarz",
    msgCommentHolder: "Możesz tu wpisać wszelkie informacje z tym związane lub własne komentarze.",
    
    /** PERFORMANCE REPORT */
    msgPerformance: "Raport wydajności",
    msgPerformanceSubtitle: "Sprawdź swój wynik samooceny poziomu dojrzałości, dla każdego bloku biznesowego i ogólny.",
    msgCompanyLevel: "Twój poziom firmy",
    msgBlockArea: "OBSZAR BLOKU",
    
    /** IMPROVEMENT REPORT */
    msgImprovement: "Raport poprawy",
    msgImprovementMainSubtitle: "Dowiedz się, co możesz zrobić, aby stale się doskonalić. Zidentyfikuj więcej dobrych praktyk i przypadków biznesowych, aby przenieść swoją firmę na kolejny poziom.</br></br>Możesz zacząć czytać i inspirować się praktykami zawartymi na niższych poziomach, a następnie szukać i próbować stopniowo wdrażać te z wyższych poziomów. Pamiętaj, że praktyki z poziomów 1 i 2 muszą być zgodne z wymogami prawa i przepisów, więc ich spełnienie jest obowiązkiem prawnym – zatem jest bardzo ważne.",
    msgImprovementSubtitle: "Dowiedz się, co możesz zrobić, aby stale się doskonalić. Zidentyfikuj więcej dobrych praktyk i przypadków biznesowych, aby przenieść swoją firmę na kolejny poziom.",
    msgLevels: "Poziomy",
    msgActionsToImplement: "Działania do wdrożenia:",
    msgBaselineScore: "Mój wynik podstawowy",
    msgBusinessCaseEmpty: "Gratulacje! Wdrożono już główne dobre praktyki zawarte w tej kategorii",
    
    msgCat1: "WPŁYW EKONOMICZNY I ORGANIZACYJNY",
    msgCat2: "SPOŁECZNY",
    msgCat3: "ŚRODOWISKOWY",
    
    /** GOOD PRACTICES COLLECTION */
    msgGoodPracticesSubtitle: "Przeglądaj i poznawaj kompletny zbiór dobrych praktyk i przypadków biznesowych projektu FurnCSR",
    msgBusinessBlock: "Blok biznesowy",
    msgLevel: "Poziom",
    msgDimension: "Wymiar",
    msgStep: "Step",
    
    /** SETTINGS */
    msgUserInfo: "Informacje o użytkowniku",
    msgUserCredentials: "Dane uwierzytelniające użytkownika",
    
    /** MODAL */
    msgAreSure: "Na pewno?",
    
    msgSuccess: "Pomyślnie zaktualizowano dane",
    msgDeleteSuccess: "Pomyślnie usunięto dane",
    
    /** ERROR */
    errorOblData: "Wypełnij wszystkie obowiązkowe pola",
    errorEmailFormat: "Błąd formatu adresu e-mail",
    errorUserIncorrect: "Nieprawidłowy adres e-mail/hasło",
    errorSend: "Błąd komunikacji, poczekaj i spróbuj ponownie później",
    errorLength: "Przekroczono liczbę znaków",
    errorPassSame: "Hasła nie są zgodne",
    
    /** FOOTER */
    msgFooterContact: "KONTAKT",
    msgFooterLinkedIn: "LINKEDIN",
    msgFooterTwitter: "TWITTER",
    
    msgFooterGr: "Projekt ten został sfinansowany przez Komisję Europejską w ramach zaproszenia: Wsparcie dialogu społecznego VP/2020/001",
    msgCopyright: "Copyright&copy;2023 AMBIT. Wszelkie prawa zastrzeżone",
    
    msgFooterLegal: "Nota prawna",
    msgFooterPrivacy: "Prywatność",
    msgFooterCookies: "Polityka plików cookie",
    
    /** PROGRESS VALUES */
    msgProgress0: "Obszary poprawy",
    msgProgress1: "Zgodność z przepisami",
    msgProgress2: "Poprawianie przepisów",
    msgProgress3: "Spełnienie oczekiwań",
    msgProgress4: "Zaangażowanie",
    
    /** COMPANY SIZES */
    msgCompanySize: "Rozmiar firmy",
    msgCompanySize1: "Mniej niż 10 pracowników",
    msgCompanySize2: "10-30 pracowników",
    msgCompanySize3: "30-50 pracowników",
    msgCompanySize4: "Ponad 50 pracowników",
    
    /** PRODUCT TYPES */
    msgProductType: "Rodzaj produkowanych produktów",
    msgProductType1: "Tapicerstwo",
    msgProductType2: "Materace",
    msgProductType3: "Podstawy tapicerki, kanapy, podstawy łóżek",
    msgProductType4: "Podwykonawcy (ramy, obróbka, lakierowanie itp.)",
    msgProductType5: "Usługi (specjalistyczna logistyka itp.)",
    msgProductType6: "Płyty meblowe (melaminowane, fornirowane, lakierowane itp.)",
    msgProductType7: "Meble drewniane",
    msgProductType8: "Krzesła",
    msgProductType9: "Meble biurowe",
    msgProductType10: "Meble do przestrzeni publicznych i użytku publicznego",
    msgProductType11: "Drzwi, okna i wyroby stolarskie",
    msgProductType12: "Obiekty na zamówienie (sklepy, biblioteki, restauracje, firmy profesjonalne itp.)",
    msgProductType13: "Oświetlenie",
    msgProductType14: "Powłoki i podłogi",
    msgProductType15: "Tekstylia",
    msgProductType16: "Dekoracje",

    /** ABOUT */
    msgAboutSec1Title: "Wprowadzenie do modelu wytycznych dotyczących poprawy FurnCSR",
    msgAboutSec1Text1:"Model wytycznych dotyczących poprawy, który prezentujemy w FurnCSR, pozwala wskazać praktyki organizacji w zakresie społecznej odpowiedzialności biznesu, natomiast brak odpowiednich praktyk w niektórych polach pozwoli wykryć luki, które firma musi wypełnić</br></br>Wytyczne dotyczące poprawy opracowane na podstawie metodologicznych podstaw społecznej odpowiedzialności biznesu pokazuje w sposób globalny i uporządkowany pozycję, w jakiej znajduje się organizacja oraz drogę, jaką musi pokonać w zarządzaniu CSR</br></br>Model opiera się na pięciu poziomach, z których każdy został ujęty w dwóch kolumnach, co łącznie daje 10 stopni – od obszarów do poprawy do maksymalnego poziomu zaangażowania. Prezentujemy podstawowy model w sposób uproszczony, chcąc przedstawić inne propozycje dobrych praktyk niż te pokazane w rozdziale 4. Pozwolą one poczynić postęp w zintegrowanym rozwoju modelu społecznej odpowiedzialności biznesu.</br></br>Poniższy schemat pokazuje różne poziomy i stopnie dojrzałości w zakresie społecznej odpowiedzialności biznesu.</br></br>Idąc w górę pierwszej kolumny (pole 0) obserwujemy różne stopnie pomiędzy (nie)zgodnością z prawem a maksymalnym poziomem zaangażowania (pole 9). W kolumnie prawej kolejne stopnie symbolizują coraz większą proaktywność i podstawy unikalnej metodologii społecznej odpowiedzialności biznesu. Następuje coraz większa integracja zainteresowanych stron i widać postęp w logice obywatelskiej postawy firmy. Społeczna odpowiedzialność biznesu wymaga naprawy, zapewnienia, zrozumienia, odpowiedzialności i tworzenia wspólnej wartości, a nie tylko większej wewnętrznej logiki zarządzania. Nie wystarczy podejmować działania z jak najlepszym zamiarem, trzeba wywierać wpływ – z autentycznością i zaufaniem zainteresowanych stron.",
    msgAboutSec1Text2: "Jest to ścieżka postępu w kierunku doskonałości, a bycie na środkowych pozycjach może samo w sobie stanowić dużą wartość dla firmy meblarskiej. Ważne, aby nie pozostawać w niższych obszarach i mieć wizję posuwania się do przodu, integrując nie tylko dobre praktyki pokazane w poprzednim rozdziale, ale także te pokazane poniżej – znajdujące się na wyższych poziomach tego modelu. Następnie wytyczne dotyczące poprawy można zastosować do każdego z trzech wymiarów zarządzania społeczną odpowiedzialnością biznesu zgodnie z ich specyfiką, zachowując cały czas tę samą ścieżkę, co pozwala zrozumieć ewolucję w stopniu dojrzałości CSR w firmie meblarskiej.",

    msgAboutLevel1Title: "Obszary do poprawy (kroki 0 i 1)",
    msgAboutLevel1Text: "Poziom ten odpowiada obszarom wymagającym poprawy i oznacza, że istnieją poważne lub rozmaite niezgodności, zarówno prawne, jak i związane ze zobowiązaniami, lub że firma jest nadal w fazie korekty i naprawy.",

    msgAboutLevel2Title: "Zgodność z przepisami (kroki 2 i 3)",
    msgAboutLevel2Text: "Poziom ten odpowiada zgodności z przepisami i oznacza, że firma spełnia i zapewnia zgodność z obowiązkami zarówno prawnymi, jak i wynikającymi z dobrowolnych zobowiązań. Dla organizacji zaangażowanej w społeczną odpowiedzialność biznesu zgodność z prawem dotyczy nie tylko przepisów istniejących na papierze, ale również intencji prawodawcy, które ten wyraża w imieniu społeczeństwa. Na poziomie zgodności z przepisami firma określa praktyki związane z przestrzeganiem głównych wymogów prawnych dla danej branży, ponieważ jest to pierwszy krok w zarządzaniu społeczną odpowiedzialnością biznesu.",

    msgAboutLevel3Title: "Poprawa przepisów (kroki 4 i 5)",
    msgAboutLevel3Text: "Ten poziom odpowiada poprawie przepisów i oznacza, że przy wdrażaniu najlepszych praktyk firma wychodzi poza literę prawa. Organizacje zaangażowane w społeczną odpowiedzialność biznesu prowadzą rozmowy z zainteresowanymi stronami w celu zrozumienia samego kontekstu zrównoważonego rozwoju.",

    msgAboutLevel4Title: "Spełnienie oczekiwań (Kroki 6 i 7)",
    msgAboutLevel4Text: "Poziom ten odpowiada spełnieniu oczekiwań i oznacza, że oczekiwania zainteresowanych stron są uwzględniane poprzez usystematyzowane zarządzanie społeczną odpowiedzialnością biznesu (planowanie, działania, integracja procesów i procedur, ocena, ciągła poprawa...). Organizacja zaangażowana w społeczną odpowiedzialność biznesu podejmuje przejrzystą odpowiedzialność wobec zainteresowanych stron",

    msgAboutLevel5Title: "Zaangażowanie (Kroki 8 i 9)",
    msgAboutLevel5Text: "Poziom ten odpowiada zaangażowaniu i oznacza, że firma realizuje zarządzanie zaangażowane, ze świadomością i przywództwem, wyraźne i stałe, kompleksowe, powiązane z planowaniem strategicznym i wyższym celem, z orientacją na tworzenie wartości wspólnej z zainteresowanymi stronami.",

    /* COUNTRIES */
    msgCountry: "Kraj",
    msgAfghanistan: "Afganistan",
    msgAlbania: "Albania",
    msgAlgeria: "Algieria",
    msgAmericanSamoa: "Samoa Amerykańskie",
    msgAndorra: "Andora",
    msgAngola: "Angola",
    msgAnguilla: "Anguilla",
    msgAntarctica: "Antarktyda",
    msgAntiguaandBarbuda: "Antigua i Barbuda",
    msgArgentina: "Argentyna",
    msgArmenia: "Armenia",
    msgAruba: "Aruba",
    msgAustralia: "Australia",
    msgAustria: "Austria",
    msgAzerbaijan: "Azerbejdżan",
    msgBahamas: "Bahamy",
    msgBahrain: "Bahrajn",
    msgBangladesh: "Bangladesz",
    msgBarbados: "Barbados",
    msgBelarus: "Białoruś",
    msgBelgium: "Belgia",
    msgBelize: "Belize",
    msgBenin: "Benin",
    msgBermuda: "Bermudy",
    msgBhutan: "Bhutan",
    msgBolivia: "Boliwia",
    msgBosniaHerzegovina: "Bośnia i Hercegowina",
    msgBotswana: "Botswana",
    msgBouvetIsland: "Wyspa Bouveta",
    msgBrazil: "Brazylia",
    msgBritishIndian: "Brytyjskie Terytorium Oceanu Indyjskiego",
    msgBrunei: "Brunei",
    msgBulgaria: "Bułgaria",
    msgBurkinaFaso: "Burkina Faso",
    msgBurundi: "Burundi",
    msgCambodia: "Kambodża",
    msgCameroon: "Kamerun",
    msgCanada: "Kanada",
    msgCapeVerde: "Wyspy Zielonego Przylądka",
    msgCaymanIslands: "Kajmany",
    msgCentralAfrican: "Republika Środkowoafrykańska",
    msgChad: "Czad",
    msgChile: "Chile",
    msgChina: "Chiny",
    msgChristmasIsland: "Wyspa Bożego Narodzenia",
    msgCocosIslands: "Wyspy Kokosowe (Keelinga).",
    msgColombia: "Kolumbia",
    msgComoros: "Komory",
    msgCongo: "Kongo",
    msgRepublicCongo: "Demokratyczna Republika Konga",
    msgCookIslands: "Wyspy Cooka",
    msgCostaRica: "Kostaryka",
    msgIvoryCoast: "Wybrzeże Kości Słoniowej",
    msgCroatia: "Chorwacja",
    msgCuba: "Kuba",
    msgCyprus: "Cypr",
    msgCzechRepublic: "Republika Czeska",
    msgDenmark: "Dania",
    msgDjibouti: "Dżibuti",
    msgDominica: "Dominika",
    msgDominicanRepublic: "Republika Dominikany",
    msgEastTimor: "Wschodni Timor",
    msgEcuador: "Ekwador",
    msgEgypt: "Egipt",
    msgEngland: "Anglia",
    msgElSalvador: "Salwador",
    msgEquatorialGuinea: "Gwinea Równikowa",
    msgEritrea: "Erytrea",
    msgEstonia: "Estonia",
    msgEthiopia: "Etiopia",
    msgFalklandIslands: "Falklandy",
    msgFaroeIslands: "Wyspy Owcze",
    msgFijiIslands: "Wyspy Fidżi",
    msgFinland: "Finlandia",
    msgFrance: "Francja",
    msgFrenchGuiana: "Gujana Francuska",
    msgFrenchPolynesia: "Polinezja Francuska",
    msgFrenchSouthern: "Francuskie Terytoria Południowe",
    msgGabon: "Gabon",
    msgGambia: "Gambia",
    msgGeorgia: "Gruzja",
    msgGermany: "Niemcy",
    msgGhana: "Ghana",
    msgGibraltar: "Gibraltar",
    msgGreece: "Grecja",
    msgGreenland: "Grenlandia",
    msgGrenada: "Grenada",
    msgGuadeloupe: "Gwadelupa",
    msgGuam: "Guam",
    msgGuatemala: "Gwatemala",
    msgGuernsey: "Guernsey",
    msgGuinea: "Gwinea",
    msgGuineaBissau: "Gwinea Bissau",
    msgGuyana: "Gujana",
    msgHaiti: "Haiti",
    msgHeardMcDonald: "Wyspy Heard i McDonalda",
    msgHolySee: "Stolica Apostolska (Państwo Watykańskie)",
    msgHonduras: "Honduras",
    msgHongKong: "Hongkong",
    msgHungary: "Węgry",
    msgIceland: "Islandia",
    msgIndia: "Indie",
    msgIndonesia: "Indonezja",
    msgIran: "Iranu",
    msgIraq: "Irak",
    msgIreland: "Irlandia",
    msgIsrael: "Izrael",
    msgIsleMan: "Wyspa Man",
    msgItaly: "Włochy",
    msgJamaica: "Jamajka",
    msgJapan: "Japonia",
    msgJersey: "Golf",
    msgJordan: "Jordania",
    msgKazakhstan: "Kazachstan",
    msgKenya: "Kenia",
    msgKiribati: "Kiribati",
    msgKuwait: "Kuwejt",
    msgKyrgyzstan: "Kirgistan",
    msgLaos: "Laos",
    msgLatvia: "Łotwa",
    msgLebanon: "Liban",
    msgLesotho: "Lesoto",
    msgLiberia: "Liberia",
    msgLibyanJamahiriya: "Libijska Arabska Dżamahirija",
    msgLiechtenstein: "Liechtenstein",
    msgLithuania: "Litwa",
    msgLuxembourg: "Luksemburg",
    msgMacao: "Makao",
    msgNorthMacedonia: "Macedonia Północna",
    msgMadagascar: "Madagaskar",
    msgMalawi: "Malawi",
    msgMalaysia: "Malezja",
    msgMaldives: "Malediwy",
    msgMali: "Mali",
    msgMalta: "Malta",
    msgMarshallIslands: "Wyspy Marshalla",
    msgMartinique: "Martynika",
    msgMauritania: "Mauretania",
    msgMauritius: "Mauritius",
    msgMayotte: "Majotta",
    msgMexico: "Meksyk",
    msgMicronesia: "Mikronezja, Sfederowane Stany",
    msgMoldova: "Moldova",
    msgMonaco: "Monako",
    msgMongolia: "Mongolia",
    msgMontserrat: "Montserrat",
    msgMontenegro: "Czarnogóra",
    msgMorocco: "Maroko",
    msgMozambique: "Mozambik",
    msgMyanmar: "Myanmar",
    msgNamibia: "Namibia",
    msgNauru: "Nauru",
    msgNepal: "Nepal",
    msgNetherlands: "Holandia",
    msgNetherlandsAntilles: "Antyle Holenderskie",
    msgNewCaledonia: "Nowa Kaledonia",
    msgNewZealand: "Nowa Zelandia",
    msgNicaragua: "Nikaragua",
    msgNiger: "Niger",
    msgNigeria: "Nigeria",
    msgNiue: "Niue",
    msgNorfolkIsland: "Wyspa Norfolk",
    msgNorthKorea: "Korea Północna",
    msgNorthernIreland: "Irlandia Północna",
    msgNorthernMariana: "Mariany Północne",
    msgNorway: "Norwegia",
    msgOman: "Oman",
    msgPakistan: "Pakistan",
    msgPalau: "Palau",
    msgPalestine: "Palestyna",
    msgPanama: "Panama",
    msgPapua: "Papua Nowa Gwinea",
    msgParaguay: "Paragwaj",
    msgPeru: "Peru",
    msgPhilippines: "Filipiny",
    msgPitcairn: "Pitcairn",
    msgPoland: "Polska",
    msgPortugal: "Portugalia",
    msgPuertoRico: "Portoryko",
    msgQatar: "Katar",
    msgReunion: "Zjazd",
    msgRomania: "Rumunia",
    msgRussianFederation: "Federacja Rosyjska",
    msgRwanda: "Rwanda",
    msgSaintHelena: "Święta Heleno",
    msgSaintKitts: "Saint Kitts i Nevis",
    msgSaintLucia: "święta Lucia",
    msgSaintPierre: "Saint-Pierre i Miquelon",
    msgSaintVincent: "Saint Vincent i Grenadyny",
    msgSamoa: "Samoa",
    msgSanMarino: "San Marino",
    msgSaoTome: "Wyspy Świętego Tomasza i Książęca",
    msgSaudiArabia: "Arabia Saudyjska",
    msgScotland: "Szkocja",
    msgSenegal: "Senegal",
    msgSerbia: "Serbia",
    msgSeychelles: "Seszele",
    msgSierraLeone: "Sierra Leone",
    msgSingapore: "Singapur",
    msgSlovakia: "Słowacja",
    msgSlovenia: "Słowenia",
    msgSolomonIslands: "Wyspy Salomona",
    msgSomalia: "Somali",
    msgSouthAfrica: "Afryka Południowa",
    msgSouthSandwich: "Georgia Południowa i Sandwich Południowy",
    msgSouthKorea: "Korea Południowa",
    msgSouthSudan: "Południowy Sudan",
    msgSpain: "Hiszpania",
    msgSriLanka: "SriLanka",
    msgSudan: "Sudan",
    msgSuriname: "Surinam",
    msgSvalbardJan: "Svalbard i Jan Mayen",
    msgSwaziland: "Suazi",
    msgSweden: "Szwecja",
    msgSwitzerland: "Szwajcaria",
    msgSyria: "Syria",
    msgTajikistan: "Tadżykistan",
    msgTanzania: "Tanzania",
    msgThailand: "Tajlandia",
    msgTimorLeste: "Timor Wschodni",
    msgTogo: "Iść",
    msgTokelau: "Tokelau",
    msgTonga: "Tonga",
    msgTrinidadTobago: "Trynidad i Tobago",
    msgTunisia: "Tunezja",
    msgTurkey: "Indyk",
    msgTurkmenistan: "Turkmenia",
    msgTurksCaicos: "Wyspy Turks i Caicos",
    msgTuvalu: "Tuvalu",
    msgUganda: "Uganda",
    msgUkraine: "Ukraina",
    msgUnitedArabEmirates: "Zjednoczone Emiraty Arabskie",
    msgUnitedKingdom: "Zjednoczone Królestwo",
    msgUnitedStates: "Stany Zjednoczone",
    msgUnitedStatesMinor: "Stany Zjednoczone Dalekie Wyspy Mniejsze",
    msgUruguay: "Urugwaj",
    msgUzbekistan: "Uzbekistan",
    msgVanuatu: "Vanuatu",
    msgVenezuela: "Wenezuela",
    msgVietnam: "Wietnam",
    msgVirginIslandsBritish: "Wyspy Dziewicze, Brytyjskie",
    msgVirginIslandsUS: "Wyspy Dziewicze, USA",
    msgWales: "Walia",
    msgWallisFutuna: "Wallis i Futuna",
    msgWesternSahara: "Sahara Zachodnia",
    msgYemen: "Jemen",
    msgZambia: "Zambia",
    msgZimbabwe: "Zimbabwe",
}

export default pl;