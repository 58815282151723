import './css/normalize.css';
import './css/bootstrap-grid.css';
import './css/App.css';

import { LanguageProvider } from './components/providers/LanguageProvider';
import { UserContext, UserProvider } from './components/providers/UserProvider';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { Fragment, useContext } from 'react';
import { NotificationView } from './components/util/NotificationUtil';
import ModalView from './components/util/ModalUtil';
import { Container, ContainerFlex, LanguageSelector } from './components/styled/Styled';
import Header from './components/Header';
import { Footer } from './components/Footer';
import HomeView from './views/HomeView';
import LoginView from './views/LoginView';
import RegisterView from './views/RegisterView';
import Drawer from './components/Drawer';
import AssessmentView from './views/AssessmentView';
import ProfileView from './views/ProfileView';
import PerformanceView from './views/PerformanceView';
import ImprovementView from './views/ImprovementView';
import AssessmentSectionView from './views/AssessmentSectionView';
import { CategoryIndexProvider } from './components/providers/CategoryIndexProvider';
import BussinessCasesList from './views/BussinessCasesList';
import LevelDetail from './views/LevelDetail';
import AboutView from './views/AboutView';
import PerformanceSectionView from './views/PerformancetSectionView';

const SwitchView = () => {
  const { state } = useContext(UserContext);

  return <Fragment>
    <Container width="12" height="100vh" overflowY="auto" color="color-white">
      <Router basename="">
        <Fragment>
          {
            state.login ?
              <ContainerFlex width="12" height="100vh" color="color-gray">
                <Drawer />
                <Container width="wrap" maxheight="100%" overflowY="auto">
                  <ContainerFlex width="12" px="16" justify="flex-end" children={<LanguageSelector />} />
                  <Routes>
                    <Route exact={true} path="/" element={<AssessmentView />} />
                    <Route exact={true} path="/section" element={<AssessmentSectionView />} />
                    <Route exact={true} path="/performance" element={<PerformanceView />} />
                    <Route exact={true} path="/performance/section" element={<PerformanceSectionView />} />


                    <Route exact={true} path="/improvement" element={<ImprovementView />} />
                    <Route exact={true} path="/profile" element={<ProfileView />} />
                    <Route exact={true} path="/about" element={<AboutView />} />

                    <Route exact={true} path="/goodpractices" element={<BussinessCasesList />} />
                    <Route exact={true} path="/improvement/level" element={<LevelDetail />} />

                    <Route path="*" element={<Navigate replace to="/" />} />

                  </Routes>
                </Container>
              </ContainerFlex>
              :
              <Fragment>
                <Header />
                <Routes>
                  <Route exact={true} path="/" element={<HomeView />} />
                  <Route exact={true} path="/login" element={<LoginView />} />
                  <Route exact={true} path="/register" element={<RegisterView />} />

                  <Route exact={true} path="/goodpractices" element={<BussinessCasesList />} />

                  <Route path="*" element={<Navigate replace to="/" />} />
                </Routes>
                <Footer />
              </Fragment>
          }
        </Fragment>
      </Router>
      {state.notification.show ? <NotificationView /> : null}
    </Container>
    {state.modal.show ? <ModalView children={state.modal.body} /> : null}
  </Fragment>
}

const App = () => <CategoryIndexProvider children={<LanguageProvider children={<UserProvider children={<SwitchView />} />} />} />

export default App;
