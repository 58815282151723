import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../components/styled/Styled';

import canvas from '../img/furncsr_canvas.jpg';
import Icon from '@mdi/react';
import { mdiArrowExpandAll } from '@mdi/js';
import BlockItem from '../components/BlockItem';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { UserAction, UserContext } from '../components/providers/UserProvider';

const AssessmentView = () => {

    const [quiz, setQuiz] = useState([]);
    const { dispatch } = useContext(UserContext);
    let history = useNavigate();

    useEffect(() => {
        HttpConnection(`${HttpOperations.block}/progress`,
            result => setQuiz(result.item), error => console.log(error), HttpMethod.get);
    }, [])

    const showResults = () => {
        history('/performance')
    }

    const reset = () => {
        HttpConnection(`${HttpOperations.useranswer}/clean`,
            response => {
                HttpConnection(`${HttpOperations.block}/progress`,
                    result => setQuiz(result.item), error => console.log(error), HttpMethod.get);
            }, error => console.log(error), HttpMethod.delete);
    }

    const showCanvas = () => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <img alt="furncsr-canvas" width="90%" src={canvas} /> } })
    }

    return (
        <ContainerFlex width="12" justify="center" px="32" >
            <Container width="12" lg="8">
                <Card width="12" px="8" shadowOn flex mt="16" align="stretch" color="color-white">
                    <ContainerFlex direction="column" width="6" fontColor="color-blue">
                        <img alt="furncsr-canvas" width="100%" src={canvas} />
                        <Button flex mt="8" pv="8" fontSize="14px" align="center" onClick={showCanvas}>
                            <Icon style={{ marginRight: "8px" }} path={mdiArrowExpandAll} size={1} />
                            <TextLocalized children="msgClickImage" />
                        </Button>
                    </ContainerFlex>
                    <Container width="wrap" pv="8" ph="16">
                        <Container width="12" fontSize="18px" fontWeight="300" children={<TextLocalized children="msgAssessmentAreas" />} />
                        <Container width="12" fontSize="14px" mt="16" children={<TextLocalized children="msgAssessmentDesc" />} />
                    </Container>
                </Card>

                <Container width="12" mt="32" fontSize="18px" fontWeight="300" fontColor="color-black" children={<TextLocalized children="msgAssessmentAreas" />} />
                <Card width="12" height="5px" mv="16" color="color-blue" radius="2px" />

                <ContainerFlex width="12" align="stretch" children={quiz.map((item) => <BlockItem key={item.id_block} item={item} />)} />

                <ContainerFlex width="12" mh="-4" justify="center">
                    <Button onClick={reset} shadowOn width="wrap" mv="32" mh="8" pv="16" color="color-red" hoverColor="color-blue"
                        fontColor="color-white" fontWeight="bold" textAlign="center"
                        children={<TextLocalized children="actionReset" />} />

                    <Button onClick={showResults} shadowOn width="wrap" mv="32" mh="8" pv="16" color="color-blue" hoverColor="color-blue-light"
                        fontColor="color-white" fontWeight="bold" textAlign="center"
                        children={<TextLocalized children="actionDoDiagnost" />} />
                </ContainerFlex>

            </Container>
        </ContainerFlex>
    );
};

export default AssessmentView;