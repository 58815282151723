import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTextLang, LanguageContext } from './providers/LanguageProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from './styled/Styled';
import { getLevelImage } from './util/BlockUtil';

const BlockLevelOne = ({ item }) => {
    const { language } = useContext(LanguageContext);
    let history = useNavigate();

    return <Container width="12" px="4" mb="8">
        <Button onClick={() => history("/improvement/level", { state: { item: { id_level: 2 } } })} flex shadowOn width="12" align="stretch" color="color-white">
            <Card flex justify="center" px="16" width="150px" radiusbr="0" radiustr="0" minheight="100%" maxheight="141px" color="color-purple">
                <img alt={`level-${item.id_level}`} height="100%" width="100%" src={getLevelImage(item)} />
            </Card>
            <ContainerFlex width="wrap" align="stretch">
                <Card flex width="12" align="center" color={"color-gray-card"} radiustl="0px" radiusbl="0px" radiusbr="0px">
                    <Container width="wrap" px="16" fontSize="18px" fontWeight="bold" fontColor="color-black" children={`${item.id_level} - ${getTextLang(item, 'name', language)}`} />
                    <Container width="12" height="1px" color="color-gray" />
                </Card>
                <ContainerFlex width="12" align="center" justify="space-between">
                    <ContainerFlex px="16">
                        <Card pv="4" ph="8" border="solid" fontSize="12px" fontColor="color-white" color="color-black" borderColor="color-black" borderWidth="2px" children={`${getTextLang(item.steps[0], 'name', language)}`} />
                        <Card mh="8" pv="4" ph="8" border="solid" fontSize="12px" fontColor="color-white" color="color-gray-home" borderColor="color-gray-home" borderWidth="2px" children={`${getTextLang(item.steps[1], 'name', language)}`} />
                    </ContainerFlex>
                    <ContainerFlex justify="flex-end" align="center" pv="20" ph="16">
                        <Container pv="4" ph="8" border="solid" fontSize="12px" fontColor="color-black" borderColor={"color-black"} borderWidth="2px" children={<TextLocalized children={"msgActionsToImplement"} />} />
                        <Container flex justify="center" align="center" border="solid" borderColor="color-black" borderWidth="3px" radius="50%"
                            fontColor="color-black" fontWeight="bold" children={`${item.score}`} />
                    </ContainerFlex>
                </ContainerFlex>

            </ContainerFlex>
        </Button>
    </Container>
};

export default BlockLevelOne;