const de = {
    actionAlready: "Haben Sie bereits ein Konto? Einloggen",
    actionExit: "Ausloggen",
    actionLogin: "EINLOGGEN",
    actionRegister: "ANMELDEN",
    actionSave: "SPEICHERN",
    actionStart: "STARTEN",
    actionContinue: "FORTFAHREN",
    actionReset: "BEWERTUNG ZURÜCKSETZEN",
    actionDoDiagnost: "DIAGNOSE ANZEIGEN",
    actionYes: "Ja",
    actionNo: "Nein",
    actionNext: "WEITER",
    actionBusinessCase: "BUSINESS CASE ZEIGEN",
    actionBusinessCases: "BUSINESS CASES ZEIGEN",
    actionOpenUrl: "URL ÖFFNEN",
    actionFilter: "FILTERN",
    
    /** HOME */
    msgHomeTitleOne: "Messen und steigern Sie die positiven Auswirkungen Ihres Möbelunternehmens",
    msgHomeSubTitle: "Das FurnCSR-Online-Tool bewertet Ihre aktuellen CSR-Fortschritte auf der Grundlage wirtschaftlicher und organisatorischer, gesellschaftlicher und ökologischer Auswirkungen",
    
    msgHomeSection2: "Die CSR-Anforderungen steigen.</br>Sind Sie darauf vorbereitet?",
    msgHomeSection2Text: "<ul><li>Beantworten Sie die Fragen zu Ihren täglichen Geschäftsaktivitäten gemäß den wichtigsten Wirkungsstandards.</li><li>Erhalten Sie Ihre Reifegrad-Selbsteinschätzung.</li><li>Identifizieren Sie gute Praktiken, um Ihr Unternehmen auf die nächste Stufe zu bringen.</li><li>Lernen Sie von echten Praxisbeispielen aus der Möbelbranche, um Ihre positive Wirkung kontinuierlich zu steigern.</li></ul>",
    msgHomeSection3: "Partner",
    msgHomeSection2Btn: "Beginnen Sie Ihre Bewertung",
    msgHomeSection3Btn: "KONTAKT",
    
    /** HEADER */
    msgHeader1: "Bewährte Praktiken",
    msgHeader4: "Einloggen ",
    
    /** DRAWER */
    msgCompany: "UNTERNEHMEN",
    msgDrawer1: "FurnCSR-Bewertung",
    msgDrawer2: "Leistungsbericht",
    msgDrawer3: "Verbesserungsbericht",
    msgDrawer4: "Sammlung bewährter Praktiken",
    msgDrawer5: "Über FurnCSR-Stufen",
    msgDrawer6: "Einstellungen",
    msgDrawer7: "Ausloggen",
    msgDrawer8: "Bewerten Sie Ihre Erfahrungen",
    
    /** LOGIN */
    msgWelcome: "Willkommen",
    msgWelcomeSubtitle: "Bitte melden Sie sich an, um fortzufahren",
    
    /** REGISTER */
    msgGetStarted: "Los geht's",
    msgGetStartedSubtitle: "Dieses Instrument zur Folgenabschätzung ist kostenlos und vertraulich",
    
    msgFirstname: "Vorname",
    msgLastname: "Nachname",
    msgEmail: "E-Mail",
    msgPassword: "Passwort",
    msgConfirmPassword: "Bestätigen Sie das Passwort",
    msgCompanyUser: "Unternehmen oder Benutzername",
    
    msgNotRegister: "Noch nicht registriert? <b>Hier ein Konto einrichten</b>",
    msgAlreadyRegister: "Bereits registriert? <b>Hier anmelden</b>",
    
    /** HOME TOOL */
    msgClickImage: "Klicken Sie, um das FurnCSR Business CANVAS zu erweitern.",
    
    msgArea: "BEREICH",
    msgProgress: "GESEHENE FRAGEN",
    
    msgAssessmentAreas: "FurnCSR-Einflussbereiche",
    msgAssessmentDesc: "Alle Fragen im FurnCSR-Tool sind nach den Hauptgeschäftsbereichen eines sozial verantwortlichen Möbelunternehmens gegliedert. Dies ist das FurnCSR Business CANVAS. Die Herstellung und die internen Abläufe befinden sich auf der linken Seite von Canvas, während das Angebot und die externen Erfahrungen auf der rechten Seite dargestellt werden. Wir verwenden einen dreistufigen Ansatz (wirtschaftlich/organisatorisch, gesellschaftlich und ökologisch), um CSR-orientierte Geschäftsaktivitäten in jedem Block zu bewerten. ",
    
    msgAssessment: "FurnCSR-Bewertung",
    msgNotApply: "Kreuzen Sie die Antworten an, die auf die aktuelle Entwicklung Ihres Unternehmens zutreffen. Wenn keine Antwort zutrifft, kreuzen Sie keine Option an.",
    msgMarkImportant: "Als wichtig markieren",
    msgComment: "Kommentar",
    msgCommentHolder: "Sie können hier alle damit zusammenhängenden Informationen oder Ihre eigenen Kommentare schreiben.",
    
    /** PERFORMANCE REPORT */
    msgPerformance: "Leistungsbericht",
    msgPerformanceSubtitle: "Prüfen Sie Ihre Reifegrad-Selbsteinschätzung, und zwar für jeden einzelnen Geschäftsblock und als Gesamtbewertung.",
    msgCompanyLevel: "Ihre Unternehmensebene",
    msgBlockArea: "BLOCKBEREICH",
    
    /** IMPROVEMENT REPORT */
    msgImprovement: "Verbesserungsbericht",
    msgImprovementMainSubtitle: "Erfahren Sie, was Sie tun können, um sich kontinuierlich zu verbessern. Identifizieren Sie weitere bewährte Verfahren und Geschäftsfälle, um Ihr Unternehmen auf die nächste Stufe zu bringen.</br></br> Sie können damit beginnen, die in den unteren Stufen enthaltenen Verfahren zu lesen und sich von ihnen inspirieren zu lassen, und dann versuchen, die Verfahren in den oberen Stufen schrittweise umzusetzen. Denken Sie daran, dass Praktiken der Stufen 1 und 2 den Anforderungen von Gesetzen und Verordnungen entsprechen müssen, so dass die Erfüllung dieser Anforderungen eine rechtliche Verpflichtung darstellt und daher sehr wichtig ist.",
    msgImprovementSubtitle: "Erfahren Sie, was Sie tun können, um sich kontinuierlich zu verbessern. Ermitteln Sie weitere bewährte Verfahren und Geschäftsfälle, um Ihr Unternehmen auf die nächste Stufe zu bringen.",
    msgLevels: "Ebenen",
    msgActionsToImplement: "Maßnahmen zur Umsetzung:",
    msgBaselineScore: "Mein Ausgangswert",
    msgBusinessCaseEmpty: "Herzlichen Glückwunsch! Sie haben die wichtigsten bewährten Praktiken aus dieser Kategorie bereits umgesetzt",
    
    msgCat1: "WIRTSCHAFTLICH UND ORGANISATORISCH",
    msgCat2: "SOZIALES",
    msgCat3: "UMWELT",
    
    /** GOOD PRACTICES COLLECTION */
    msgGoodPracticesSubtitle: "Stöbern Sie in der vollständigen Sammlung bewährter Verfahren und Geschäftsfälle des FurnCSR-Projekts",
    msgBusinessBlock: "Business-Block",
    msgLevel: "Ebene",
    msgDimension: "Dimension",
    msgStep: "Step",
    
    /** SETTINGS */
    msgUserInfo: "Informationen für den Benutzer",
    msgUserCredentials: "Benutzeranmeldeinformationen",
    
    /** MODAL */
    msgAreSure: "Sind Sie sicher?",
    
    msgSuccess: "Erfolgreiche Datenaktualisierung",
    msgDeleteSuccess: "Erfolgreiche Datenlöschung",
    
    /** ERROR */
    errorOblData: "Füllen Sie alle Pflichtfelder aus",
    errorEmailFormat: "Fehler im E-Mail-Format",
    errorUserIncorrect: "E-Mail/Passwort falsch",
    errorSend: "Kommunikationsfehler, bitte warten und später erneut versuchen",
    errorLength: "Die Anzahl der Zeichen wurde überschritten",
    errorPassSame: "Passwörter sind nicht gleich",
    
    /** FOOTER */
    msgFooterContact: "KONTAKT",
    msgFooterLinkedIn: "LINKEDIN",
    msgFooterTwitter: "TWITTER",
    
    msgFooterGr: "Dieses Projekt wurde durch die Ausschreibung der Europäischen Kommission finanziert: Unterstützung für den sozialen Dialog VP/2020/001",
    msgCopyright: "Copyright&copy;2023 AMBIT. Alle Rechte vorbehalten",
    
    msgFooterLegal: "Rechtlicher Hinweis",
    msgFooterPrivacy: "Datenschutz",
    msgFooterCookies: "Cookie-Richtlinie",
    
    /** PROGRESS VALUES */
    msgProgress0: "Bereiche der Verbesserung",
    msgProgress1: "Einhaltung von Vorschriften",
    msgProgress2: "Regulatorische Verbesserung",
    msgProgress3: "Erwartungserfüllung",
    msgProgress4: "Selbstverpflichtung",
    
    /** COMPANY SIZES */
    msgCompanySize: "Größe des Unternehmens",
    msgCompanySize1: "Weniger als 10 Mitarbeiter",
    msgCompanySize2: "10-30 Mitarbeiter",
    msgCompanySize3: "30-50 Mitarbeiter",
    msgCompanySize4: "Mehr als 50 Mitarbeiter",
    
    /** PRODUCT TYPES */
    msgProductType: "Art der von Ihnen hergestellten Produkte",
    msgProductType1: "Polsterung",
    msgProductType2: "Matratzen",
    msgProductType3: "Polstermöbel, Kanapees, Bettgestelle",
    msgProductType4: "Unterauftragnehmer (Rahmen, Bearbeitung, Lackierung usw.)",
    msgProductType5: "Dienstleistungen (spezialisierte Logistik, etc.)",
    msgProductType6: "Plattenmöbel (Melamin, Furnier, Lack, usw.)",
    msgProductType7: "Möbel aus Holz",
    msgProductType8: "Stühle",
    msgProductType9: "Büromöbel",
    msgProductType10: "Möbel für öffentliche Bereiche und Nutzungen",
    msgProductType11: "Türen, Fenster und Schreinereiprodukte",
    msgProductType12: "Maßgeschneiderte Einrichtungen (Geschäfte, Bibliotheken, Restaurants, Gewerbebetriebe usw.)",
    msgProductType13: "Beleuchtung",
    msgProductType14: "Beschichtungen und Bodenbeläge",
    msgProductType15: "Textil",
    msgProductType16: "Dekorationen",

    /** ABOUT */
    msgAboutSec1Title: "Einführung in das FurnCSR-Fortschrittsleitlinienmodell",
    msgAboutSec1Text1:"Die Fortschrittsleitlinie, die wir in FurnCSR vorstellen, ermöglicht es uns, die CSR-Praktiken der Organisation zu ermitteln, während das Fehlen relevanter Praktiken in einigen Feldern es uns ermöglicht, etwaige Lücken zu erkennen, die es zu schließen gilt.</br></br>Ausgehend von den methodischen Grundlagen der CSR zeigt der Progress-Leitfaden global und strukturiert auf, in welcher Position sich eine Organisation befindet und welchen Weg sie im CSR-Management einschlagen muss.</br></br>Das Modell basiert auf fünf Stufen, die, in zwei Spalten verdoppelt, 10 Stufen bieten, von den verbesserungswürdigen Bereichen bis zum maximalen Engagement. Wir zeigen das Basismodell in vereinfachter Form mit dem Ziel, weitere Vorschläge für bewährte Praktiken zu unterbreiten, die sich von den bisherigen (d. h. Kapitel 4) in dem Sinne unterscheiden, dass sie Fortschritte bei der integrierten Entwicklung eines CSR-Modells ermöglichen.</br></br>Die folgenden Abbildungen zeigen die verschiedenen Stufen und Grade der Reife von CSR.</br></br>In der ersten Spalte (Kästchen 0) werden die unterschiedlichen Grade der (Nicht-)Einhaltung der Rechtsvorschriften und das maximale Maß an Verpflichtungen (Kästchen 9) dargestellt. In der rechten Spalte, die eine größere Proaktivität und die Grundlagen der einzigartigen CSR-Methodik zeigt, wird die Präsenz der Stakeholder schrittweise integriert und es werden Fortschritte in der Logik des gesellschaftlichen Engagements der Unternehmen deutlich. CSR verlangt, dass man repariert, sicherstellt, versteht, rechenschaftspflichtig ist und einen gemeinsamen Wert schafft, der über das hinausgeht, was einer eher internen Managementlogik entspricht; es reicht nicht aus, Dinge mit der bestmöglichen Absicht zu tun, man muss auch Auswirkungen erzeugen, mit Authentizität und mit dem Vertrauen der Stakeholder.",
    msgAboutSec1Text2: "Dies ist ein Weg des Fortschritts in Richtung Exzellenz, und eine mittlere Position kann für das Möbelunternehmen bereits von großem Wert sein. Es wird wichtig sein, die Themen nicht in den unteren Bereichen zu belassen und eine Vision davon zu haben, wie man vorankommt, indem man nicht nur die im vorherigen Kapitel vorgestellten bewährten Praktiken integriert, sondern auch die unten aufgeführten, die in den höheren Ebenen dieses Modells angesiedelt sind. Anschließend kann die Fortschrittsleitlinie auf jede der drei Dimensionen des CSR-Managements entsprechend ihrer spezifischen Merkmale angewandt werden, wobei derselbe Weg stets beibehalten wird, was es ermöglicht, die Entwicklung des Reifegrads von CSR in einem Möbelunternehmen zu verstehen",

    msgAboutLevel1Title: "Verbesserungsbedürftige Bereiche (Schritte 0 und 1)",
    msgAboutLevel1Text: "Diese Stufe entspricht den verbesserungsbedürftigen Bereichen und bedeutet, dass es schwerwiegende oder verschiedene Verstöße gibt, sowohl in Bezug auf die Rechtsvorschriften als auch auf die Verpflichtungen, oder dass sich das Unternehmen noch in der Korrektur- und Reparaturphase befindet.",

    msgAboutLevel2Title: "Einhaltung der Rechtsvorschriften (Schritte 2 und 3)",
    msgAboutLevel2Text: "Diese Stufe entspricht der Einhaltung von Vorschriften und bedeutet, dass sie die Einhaltung der gesetzlichen oder freiwilligen Verpflichtungen erfüllt und gewährleistet. Für ein Unternehmen, das sich der CSR verpflichtet hat, bezieht sich die Einhaltung der Gesetze nicht nur auf den buchstäblichen Wortlaut, sondern auch auf die Absicht, die die Regulierungsbehörde im Namen der Gesellschaft verfolgt, indem Gesetzesverstöße vermieden werden. Auf der Ebene der Einhaltung von Vorschriften werden Praktiken zur Einhaltung der wichtigsten rechtlichen Anforderungen für die Branche spezifiziert, da ohne diese das CSR-Management nicht in Angriff genommen werden kann.",

    msgAboutLevel3Title: "Regulatorische Verbesserung (Schritte 4 und 5)",
    msgAboutLevel3Text: "Diese Stufe entspricht einer Verbesserung der Rechtsvorschriften und bedeutet, dass sie über die gesetzlichen Verpflichtungen hinausgeht und bewährte Praktiken anwendet. Für eine Organisation, die sich der CSR verpflichtet hat, finden Gespräche mit den Stakeholdern statt, um den Kontext der Nachhaltigkeit selbst zu verstehen.",

    msgAboutLevel4Title: "Erwartungserfüllung (Schritt 6 und 7)",
    msgAboutLevel4Text: "Diese Stufe entspricht der Erfüllung der Erwartungen und bedeutet, dass die Erwartungen der Stakeholder durch ein systematisches CSR-Management (Planung, Maßnahmen, Integration in Prozesse und Verfahren, Bewertung, kontinuierliche Verbesserung usw.) erfüllt werden. Eine Organisation, die sich der CSR verpflichtet hat, legt gegenüber ihren Stakeholdern transparent Rechenschaft ab.",

    msgAboutLevel5Title: "Selbstverpflichtung (Schritt 8 und 9)",
    msgAboutLevel5Text: "Diese Stufe entspricht einer Verpflichtung und bedeutet, dass ein engagiertes Management durchgeführt wird, mit Bewusstsein und Führung, explizit und dauerhaft, umfassend, verbunden mit strategischer Planung und einem höheren Ziel, mit einer Ausrichtung auf die Schaffung gemeinsamer Werte mit den Interessengruppen.",

    /* COUNTRIES */
    msgCountry: "Land",
    msgAfghanistan: "Afghanistan",
    msgAlbania: "Albanien",
    msgAlgeria: "Algerien",
    msgAmericanSamoa: "Amerikanischen Samoa-Inseln",
    msgAndorra: "Andorra",
    msgAngola: "Angola",
    msgAnguilla: "Anguilla",
    msgAntarctica: "Antarktis",
    msgAntiguaandBarbuda: "Antigua und Barbuda",
    msgArgentina: "Argentinien",
    msgArmenia: "Armenien",
    msgAruba: "Aruba",
    msgAustralia: "Australien",
    msgAustria: "Österreich",
    msgAzerbaijan: "Aserbaidschan",
    msgBahamas: "Bahamas",
    msgBahrain: "Bahrein",
    msgBangladesh: "Bangladesch",
    msgBarbados: "Barbados",
    msgBelarus: "Weißrussland",
    msgBelgium: "Belgien",
    msgBelize: "Belize",
    msgBenin: "Benin",
    msgBermuda: "Bermudas",
    msgBhutan: "Bhutan",
    msgBolivia: "Bolivien",
    msgBosniaHerzegovina: "Bosnien und Herzegowina",
    msgBotswana: "Botswana",
    msgBouvetIsland: "Bouvet-Insel",
    msgBrazil: "Brasilien",
    msgBritishIndian: "Britisches Territorium des Indischen Ozeans",
    msgBrunei: "Brunei",
    msgBulgaria: "Bulgarien",
    msgBurkinaFaso: "Burkina Faso",
    msgBurundi: "Burundi",
    msgCambodia: "Kambodscha",
    msgCameroon: "Kamerun",
    msgCanada: "Kanada",
    msgCapeVerde: "Kap Verde",
    msgCaymanIslands: "Cayman Inseln",
    msgCentralAfrican: "Zentralafrikanische Republik",
    msgChad: "Tschad",
    msgChile: "Chile",
    msgChina: "China",
    msgChristmasIsland: "Weihnachtsinsel",
    msgCocosIslands: "Kokosinseln (Keelinginseln).",
    msgColombia: "Kolumbien",
    msgComoros: "Komoren",
    msgCongo: "Kongo",
    msgRepublicCongo: "Die Demokratische Republik Kongo",
    msgCookIslands: "Cookinseln",
    msgCostaRica: "Costa Rica",
    msgIvoryCoast: "Elfenbeinküste",
    msgCroatia: "Kroatien",
    msgCuba: "Kuba",
    msgCyprus: "Zypern",
    msgCzechRepublic: "Tschechien",
    msgDenmark: "Dänemark",
    msgDjibouti: "Dschibuti",
    msgDominica: "Dominika",
    msgDominicanRepublic: "Dominikanische Republik",
    msgEastTimor: "Osttimor",
    msgEcuador: "Ecuador",
    msgEgypt: "Ägypten",
    msgEngland: "England",
    msgElSalvador: "El Salvador",
    msgEquatorialGuinea: "Äquatorialguinea",
    msgEritrea: "Eritrea",
    msgEstonia: "Estland",
    msgEthiopia: "Äthiopien",
    msgFalklandIslands: "Falkland Inseln",
    msgFaroeIslands: "Färöer Inseln",
    msgFijiIslands: "Fidschi-Inseln",
    msgFinland: "Finnland",
    msgFrance: "Frankreich",
    msgFrenchGuiana: "Französisch-Guayana",
    msgFrenchPolynesia: "Französisch Polynesien",
    msgFrenchSouthern: "Südfranzösische Territorien",
    msgGabon: "Gabun",
    msgGambia: "Gambia",
    msgGeorgia: "Georgia",
    msgGermany: "Deutschland",
    msgGhana: "Ghana",
    msgGibraltar: "Gibraltar",
    msgGreece: "Griechenland",
    msgGreenland: "Grönland",
    msgGrenada: "Grenada",
    msgGuadeloupe: "Guadeloupe",
    msgGuam: "Guam",
    msgGuatemala: "Guatemala",
    msgGuernsey: "Guernsey",
    msgGuinea: "Guinea",
    msgGuineaBissau: "Guinea-Bissau",
    msgGuyana: "Guyana",
    msgHaiti: "Haiti",
    msgHeardMcDonald: "Heard-Insel und McDonald-Inseln",
    msgHolySee: "Heiliger Stuhl (Staat Vatikanstadt)",
    msgHonduras: "Honduras",
    msgHongKong: "Hongkong",
    msgHungary: "Ungarn",
    msgIceland: "Island",
    msgIndia: "Indien",
    msgIndonesia: "Indonesien",
    msgIran: "Iran",
    msgIraq: "Irak",
    msgIreland: "Irland",
    msgIsrael: "Israel",
    msgIsleMan: "Isle of Man",
    msgItaly: "Italien",
    msgJamaica: "Jamaika",
    msgJapan: "Japan",
    msgJersey: "Jersey",
    msgJordan: "Jordanien",
    msgKazakhstan: "Kasachstan",
    msgKenya: "Kenia",
    msgKiribati: "Kiribati",
    msgKuwait: "Kuwait",
    msgKyrgyzstan: "Kirgistan",
    msgLaos: "Laos",
    msgLatvia: "Lettland",
    msgLebanon: "Libanon",
    msgLesotho: "Lesotho",
    msgLiberia: "Liberia",
    msgLibyanJamahiriya: "Libysch-arabische Jamahiriya",
    msgLiechtenstein: "Liechtenstein",
    msgLithuania: "Litauen",
    msgLuxembourg: "Luxemburg",
    msgMacao: "Macau",
    msgNorthMacedonia: "Nordmazedonien",
    msgMadagascar: "Madagaskar",
    msgMalawi: "Malawi",
    msgMalaysia: "Malaysia",
    msgMaldives: "Malediven",
    msgMali: "Mali",
    msgMalta: "Malta",
    msgMarshallIslands: "Marshallinseln",
    msgMartinique: "Martinique",
    msgMauritania: "Mauretanien",
    msgMauritius: "Mauritius",
    msgMayotte: "Mayotte",
    msgMexico: "Mexiko",
    msgMicronesia: "Mikronesien, Föderierte Staaten von",
    msgMoldova: "Moldawien",
    msgMonaco: "Monaco",
    msgMongolia: "Mongolei",
    msgMontserrat: "Montserrat",
    msgMontenegro: "Montenegro",
    msgMorocco: "Marokko",
    msgMozambique: "Mosambik",
    msgMyanmar: "Burma",
    msgNamibia: "Namibia",
    msgNauru: "Nauru",
    msgNepal: "Nepal",
    msgNetherlands: "Niederlande",
    msgNetherlandsAntilles: "Niederländische Antillen",
    msgNewCaledonia: "Neu-Kaledonien",
    msgNewZealand: "Neuseeland",
    msgNicaragua: "Nicaragua",
    msgNiger: "Niger",
    msgNigeria: "Nigeria",
    msgNiue: "Niue",
    msgNorfolkIsland: "Norfolkinsel",
    msgNorthKorea: "Nord Korea",
    msgNorthernIreland: "Nordirland",
    msgNorthernMariana: "Nördliche Marianneninseln",
    msgNorway: "Norwegen",
    msgOman: "Oman",
    msgPakistan: "Pakistan",
    msgPalau: "Palau",
    msgPalestine: "Palästina",
    msgPanama: "Panama",
    msgPapua: "Papua Neu-Guinea",
    msgParaguay: "Paraguay",
    msgPeru: "Peru",
    msgPhilippines: "Philippinen",
    msgPitcairn: "Pitcairn",
    msgPoland: "Polen",
    msgPortugal: "Portugal",
    msgPuertoRico: "Puerto Rico",
    msgQatar: "Katar",
    msgReunion: "Wiedervereinigung",
    msgRomania: "Rumänien",
    msgRussianFederation: "Russische Föderation",
    msgRwanda: "Ruanda",
    msgSaintHelena: "Heilige Helena",
    msgSaintKitts: "St. Kitts und Nevis",
    msgSaintLucia: "St. Lucia",
    msgSaintPierre: "St. Pierre und Miquelon",
    msgSaintVincent: "St. Vincent und die Grenadinen",
    msgSamoa: "Samoa",
    msgSanMarino: "San Marino",
    msgSaoTome: "Sao Tome und Principe",
    msgSaudiArabia: "Saudi-Arabien",
    msgScotland: "Schottland",
    msgSenegal: "Senegal",
    msgSerbia: "Serbien",
    msgSeychelles: "Seychellen",
    msgSierraLeone: "Sierra Leone",
    msgSingapore: "Singapur",
    msgSlovakia: "Slowakei",
    msgSlovenia: "Slowenien",
    msgSolomonIslands: "Salomon-Inseln",
    msgSomalia: "Somalia",
    msgSouthAfrica: "Südafrika",
    msgSouthSandwich: "Süd-Georgien und die südlichen Sandwich-Inseln",
    msgSouthKorea: "Südkorea",
    msgSouthSudan: "Südsudan",
    msgSpain: "Spanien",
    msgSriLanka: "Sri Lanka",
    msgSudan: "Sudan",
    msgSuriname: "Surinam",
    msgSvalbardJan: "Spitzbergen und Jan Mayen",
    msgSwaziland: "Swasiland",
    msgSweden: "Schweden",
    msgSwitzerland: "Schweiz",
    msgSyria: "Syrien",
    msgTajikistan: "Tadschikistan",
    msgTanzania: "Tansania",
    msgThailand: "Thailand",
    msgTimorLeste: "Timor-Leste",
    msgTogo: "Gehen",
    msgTokelau: "Tokelau",
    msgTonga: "Tonga",
    msgTrinidadTobago: "Trinidad und Tobago",
    msgTunisia: "Tunesien",
    msgTurkey: "Truthahn",
    msgTurkmenistan: "Turkmenistan",
    msgTurksCaicos: "Turks- und Caicosinseln",
    msgTuvalu: "Tuvalu",
    msgUganda: "Uganda",
    msgUkraine: "Ukraine",
    msgUnitedArabEmirates: "Vereinigte Arabische Emirate",
    msgUnitedKingdom: "Großbritannien",
    msgUnitedStates: "Vereinigte Staaten",
    msgUnitedStatesMinor: "Kleinere abgelegene Inseln der Vereinigten Staaten",
    msgUruguay: "Uruguay",
    msgUzbekistan: "Usbekistan",
    msgVanuatu: "Vanuatu",
    msgVenezuela: "Venezuela",
    msgVietnam: "Vietnam",
    msgVirginIslandsBritish: "Virgin Inseln, Britisch",
    msgVirginIslandsUS: "Jungferninseln, USA",
    msgWales: "Wales",
    msgWallisFutuna: "Wallis und Futuna",
    msgWesternSahara: "Westsahara",
    msgYemen: "Jemen",
    msgZambia: "Sambia",
    msgZimbabwe: "Zimbabwe",
}

export default de;