const en = {
    actionAlready: "Already have an account? Sign In",
    actionExit: "Logout",
    actionLogin: "SIGN IN",
    actionRegister: "SIGN UP",
    actionSave: "SAVE",
    actionStart: "START",
    actionContinue: "CONTINUE",
    actionReset: "RESET ASSESSMENT",
    actionDoDiagnost: "SHOW DIAGNOSTIC",
    actionYes: "Yes",
    actionNo: "No",
    actionNext: "NEXT",
    actionBusinessCase: "SHOW BUSINESS CASE",
    actionBusinessCases: "SHOW BUSINESS CASES",
    actionOpenUrl: "OPEN URL",
    actionFilter: "FILTER",

    /** HOME */
    msgHomeTitleOne: "Measure and increase the positive impact of your furniture company",
    msgHomeSubTitle: "FurnCSR Online Tool evaluates your current CSR progress based on economic and organizational, societal and environmental impacts",

    msgHomeSection2: "CSR requirements are expanding.</br>Are you prepared?",
    msgHomeSection2Text: "<ul><li>Answer the questions about your daily business activities according to main impact standards.</li><li>Get your maturity level self-assessment score.</li><li>Identify good practices to take your company to the next level.</li><li>Learn from real practical examples from the furniture sector to continuously increase your positive impact.</li></ul>",
    msgHomeSection3: "Partners",
    msgHomeSection2Btn: "Start your assessment",
    msgHomeSection3Btn: "CONTACT",

    /** HEADER */
    msgHeader1: "Good practices",
    msgHeader4: "Login",

    /** DRAWER */
    msgCompany: "COMPANY",
    msgDrawer1: "FurnCSR Assessment",
    msgDrawer2: "Performance Report",
    msgDrawer3: "Improvement Report",
    msgDrawer4: "Good Practices Collection",
    msgDrawer5: "About FurnCSR Levels",
    msgDrawer6: "Settings",
    msgDrawer7: "Logout",
    msgDrawer8: "Evaluate your experience",

    /** LOGIN */
    msgWelcome: "Welcome",
    msgWelcomeSubtitle: "Please sign in to continue",

    /** REGISTER */
    msgGetStarted: "Get Started",
    msgGetStartedSubtitle: "This impact management tool is free and confidential",

    msgFirstname: "First Name",
    msgLastname: "Last Name",
    msgEmail: "Email",
    msgPassword: "Password",
    msgConfirmPassword: "Confirm password",
    msgCompanyUser: "Company or username",

    msgNotRegister: "Not registered yet? <b>Create an account here</b>",
    msgAlreadyRegister: "Already registered? <b>Sign in here</b>",

    /** HOME TOOL */
    msgClickImage: "Click to expand the FurnCSR Business CANVAS.",

    msgArea: "AREA",
    msgProgress: "QUESTIONS SEEN",

    msgAssessmentAreas: "FurnCSR Impact Areas",
    msgAssessmentDesc: "All questions in FurnCSR tool are organized following the main business blocks of a socially responsible furniture company. This is the FurnCSR Business CANVAS. Manufacturing and internal operations are on the left side of the canvas, while the offering and external experiences are depicted on the right side. We use a triple layered approach (economic/organizational, societal and environmental) to evaluate CSR-oriented business activities in each block. ",

    msgAssessment: "FurnCSR Assessment",
    msgNotApply: "Mark the answers that apply to the current development of your company. if no answers applies, do not mark any option.",
    msgMarkImportant: "Mark as important",
    msgComment: "Comment",
    msgCommentHolder: "You can write here any related information or your own comments.",

    /** PERFORMANCE REPORT */
    msgPerformance: "Performance Report",
    msgPerformanceSubtitle: "Check out your maturity level self-assessment score, for each business block and an overall one.",
    msgCompanyLevel: "Your company level",
    msgBlockArea: "BLOCK AREA",

    /** IMPROVEMENT REPORT */
    msgImprovement: "Improvement Report",
    msgImprovementMainSubtitle: "Learn what you can do to continuously improving. Identify more good practices and business cases to take your company to the next level.</br></br>You can start to read and being inspired by the ones contained in lower levels and then look and try to implement the ones on the upper levels in a progressive manner. Remember that practices of levels 1 and 2 need to comply with legislation and regulation requirements, so their satisfaction is a legal obligation and thus very important to satisfy.",
    msgImprovementSubtitle: "Learn what you can do to continuously improving. Identify more good practices and business cases to take your company to the next level.", 
    msgLevels: "Levels",
    msgActionsToImplement: "Actions to implement:",
    msgBaselineScore: "My baseline score",
    msgBusinessCaseEmpty: "Congrats! You have already implemented the main good practices included in this category",

    msgCat1: "ECONOMIC AND ORGANISATIONAL",
    msgCat2: "SOCIETAL",
    msgCat3: "ENVIRONMENTAL",

    /** GOOD PRACTICES COLLECTION */
    msgGoodPracticesSubtitle: "Browse and explore the complete collection of good practices and business cases of FurnCSR Project",
    msgBusinessBlock: "Business Block",
    msgLevel: "Level",
    msgDimension: "Dimension",
    msgStep: "Step",

    /** SETTINGS */
    msgUserInfo: "User information",
    msgUserCredentials: "User credentials",

    /** MODAL */
    msgAreSure: "Are you sure?",

    msgSuccess: "Data update succesfully",
    msgDeleteSuccess: "Data delete succesfully",

    /** ERROR */
    errorOblData: "Fill all mandatory fields",
    errorEmailFormat: "Email format error",
    errorUserIncorrect: "Email/password incorrect",
    errorSend: "Communication error please wait and retry later",
    errorLength: "The number of characters has been exceded",
    errorPassSame: "Passwords are not same",

    /** FOOTER */
    msgFooterContact: "CONTACT",
    msgFooterLinkedIn: "LINKEDIN",
    msgFooterTwitter: "TWITTER",

    msgFooterGr: "This project has been funded by the European Commission call: Support for Social Dialogue VP/2020/001",
    msgCopyright: "Copyright&copy;2023 AMBIT. All rights reserved",

    msgFooterLegal: "Legal notice",
    msgFooterPrivacy: "Privacy",
    msgFooterCookies: "Cookie Policy",

    /** PROGRESS VALUES */
    msgProgress0: "Areas of improvement",
    msgProgress1: "Regulatory compliance",
    msgProgress2: "Regulatory improvement",
    msgProgress3: "Expectations fulfilment",
    msgProgress4: "Commitment",

    /** COMPANY SIZES */
    msgCompanySize: "Size of the company",
    msgCompanySize1: "Less than 10 employees",
    msgCompanySize2: "10-30 employees",
    msgCompanySize3: "30-50 employees",
    msgCompanySize4: "More than 50 employees", 

    /** PRODUCT TYPES */
    msgProductType: "Type of products you manufacture",
    msgProductType1: "Upholstery",
    msgProductType2: "Mattresses",
    msgProductType3: "Upholstery bases, canapés, bed bases",
    msgProductType4: "Subcontractor (frames, machining, varnishing, etc.)",
    msgProductType5: "Services (specialized logistics, etc.)",
    msgProductType6: "Board furniture (melamine, veneer, lacquer, etc.)",
    msgProductType7: "Wooden furniture",
    msgProductType8: "Chairs",
    msgProductType9: "Office furniture",
    msgProductType10: "Furniture for public spaces and uses",
    msgProductType11: "Doors, windows and carpentry products",
    msgProductType12: "Customized facilities (shops, libraries, restaurants, professional businesses, etc.)",
    msgProductType13: "Lightning",
    msgProductType14: "Coatings and flooring",
    msgProductType15: "Textile",
    msgProductType16: "Decorations",

    /** ABOUT */
    msgAboutSec1Title: "Introduction to FurnCSR progress guideline model",
    msgAboutSec1Text1:"The Progress Guideline that we present in FurnCSR allows us to pinpoint the organisation's CSR practices, while the absence of relevant practices in some boxes will allow us to detect any gaps to address.</br></br>Conceived from the methodological foundations of CSR, the Progress Guideline shows, globally and in a structured manner, the position in which an organisation is consolidated and the path it must take in CSR management.</br></br>The model is based on five levels which, duplicated in two columns, offer 10 degrees, from the areas for improvement to the maximum level of commitment. We show the basic model in a simplified way with the aim of providing other proposals for good practices different from the previous ones (i.e. Chapter 4) in the sense that they will allow progress in the integrated development of a CSR model.</br></br>The following figures show the different levels and degrees of maturity regarding CSR.</br></br>Going up the first column (box 0) we observe the different degrees between legal (non)compliance and the maximum level of commitment (box 9). Going up the column on the right, which shows greater proactiveness and the fundamentals of the unique methodology of CSR, the presence of stakeholders is gradually integrated and progress in the logic of corporate citizenship is shown. CSR asks to repair, ensure, understand, be accountable and create shared value beyond what corresponds to a more internal logic management; it is not enough to do things with the best possible intention, you have to generate impacts, with authenticity and with the trust of the stakeholders.",
    msgAboutSec1Text2: "This is a path of progress towards excellence and being in the middle positions can already be of great value to the furniture company. It will be important not to leave subjects in the lower areas and to have a vision of how to move forward by integrating not only the good practices shown in the previous chapter, but also those shown below, located in the higher levels of this model. Subsequently, the progress guideline can be applied to each of the three CSR management dimensions according to their own specific features, maintaining the same path at all times, which is what makes it possible to understand the evolution in the degree of maturity of CSR in the furniture company.",

    msgAboutLevel1Title: "Areas of improvement (Steps 0 and 1) ",
    msgAboutLevel1Text: "This level corresponds to the areas of improvement and implies that There are serious or diverse non-compliances, both legal and commitments related, or it is still under correction and repair phase.",

    msgAboutLevel2Title: "Regulatory compliance (Steps 2 and 3)",
    msgAboutLevel2Text: "This level corresponds to regulatory compliance and means that it fulfills and ensures the compliance of the obligations, whether legal or from the voluntary commitments themselves. For an organization committed to CSR, law compliance is not only related to the literal text but also with the intention that the regulator is seeking on behalf of society, avoiding the breaking of the law. On the regulatory compliance level, practices related to complying with the main legal requirements for the industry are specified, since without this, CSR management could not begin to be addressed.",

    msgAboutLevel3Title: "Regulatory improvement (Steps 4 and 5)",
    msgAboutLevel3Text: "This level corresponds to regulatory improvement and means that It goes beyond legal obligations with the implementation of best practices. For an organization committed to CSR, conversations with stakeholders take place to understand the sustainability context itself.",

    msgAboutLevel4Title: "Expectations fulfilment (Step 6 and 7)",
    msgAboutLevel4Text: "This level corresponds to the satisfaction of expectations and means that Stakeholders expectations are addressed through a systematized CSR management (planning, actions, integration in processes and procedures, evaluation, continuous improvement...). For an organization committed to CSR, transparent accountability towards the stakeholders is undertaken.",

    msgAboutLevel5Title: "Commitment (Step 8 and 9)",
    msgAboutLevel5Text: "This level corresponds to a commitment and means that a committed management is carried out, with awareness and leadership, explicit and permanent, comprehensive, linked to strategic planning and a higher purpose, with an orientation towards creating shared value with stakeholders.",

    /* COUNTRIES */
    msgCountry: "Country",
    msgAfghanistan: "Afghanistan",
    msgAlbania: "Albania",
    msgAlgeria: "Algeria",
    msgAmericanSamoa: "American Samoa",
    msgAndorra: "Andorra",
    msgAngola: "Angola",
    msgAnguilla: "Anguilla",
    msgAntarctica: "Antarctica",
    msgAntiguaandBarbuda: "Antigua and Barbuda",
    msgArgentina: "Argentina",
    msgArmenia: "Armenia",
    msgAruba: "Aruba",
    msgAustralia: "Australia",
    msgAustria: "Austria",
    msgAzerbaijan: "Azerbaijan",
    msgBahamas: "Bahamas",
    msgBahrain: "Bahrain",
    msgBangladesh: "Bangladesh",
    msgBarbados: "Barbados",
    msgBelarus: "Belarus",
    msgBelgium: "Belgium",
    msgBelize: "Belize",
    msgBenin: "Benin",
    msgBermuda: "Bermuda",
    msgBhutan: "Bhutan",
    msgBolivia: "Bolivia",
    msgBosniaHerzegovina: "Bosnia and Herzegovina",
    msgBotswana: "Botswana",
    msgBouvetIsland: "Bouvet Island",
    msgBrazil: "Brazil",
    msgBritishIndian: "British Indian Ocean Territory",
    msgBrunei: "Brunei",
    msgBulgaria: "Bulgaria",
    msgBurkinaFaso: "Burkina Faso",
    msgBurundi: "Burundi",
    msgCambodia: "Cambodia",
    msgCameroon: "Cameroon",
    msgCanada: "Canada",
    msgCapeVerde: "Cape Verde",
    msgCaymanIslands: "Cayman Islands",
    msgCentralAfrican: "Central African Republic",
    msgChad: "Chad",
    msgChile: "Chile",
    msgChina: "China",
    msgChristmasIsland: "Christmas Island",
    msgCocosIslands: "Cocos (Keeling) Islands",
    msgColombia: "Colombia",
    msgComoros: "Comoros",
    msgCongo: "Congo",
    msgRepublicCongo: "The Democratic Republic of Congo",
    msgCookIslands: "Cook Islands",
    msgCostaRica: "Costa Rica",
    msgIvoryCoast: "Ivory Coast",
    msgCroatia: "Croatia",
    msgCuba: "Cuba",
    msgCyprus: "Cyprus",
    msgCzechRepublic: "Czech Republic",
    msgDenmark: "Denmark",
    msgDjibouti: "Djibouti",
    msgDominica: "Dominica",
    msgDominicanRepublic: "Dominican Republic",
    msgEastTimor: "East Timor",
    msgEcuador: "Ecuador",
    msgEgypt: "Egypt",
    msgEngland: "England",
    msgElSalvador: "El Salvador",
    msgEquatorialGuinea: "Equatorial Guinea",
    msgEritrea: "Eritrea",
    msgEstonia: "Estonia",
    msgEthiopia: "Ethiopia",
    msgFalklandIslands: "Falkland Islands",
    msgFaroeIslands: "Faroe Islands",
    msgFijiIslands: "Fiji Islands",
    msgFinland: "Finland",
    msgFrance: "France",
    msgFrenchGuiana: "French Guiana",
    msgFrenchPolynesia: "French Polynesia",
    msgFrenchSouthern: "French Southern territories",
    msgGabon: "Gabon",
    msgGambia: "Gambia",
    msgGeorgia: "Georgia",
    msgGermany: "Germany",
    msgGhana: "Ghana",
    msgGibraltar: "Gibraltar",
    msgGreece: "Greece",
    msgGreenland: "Greenland",
    msgGrenada: "Grenada",
    msgGuadeloupe: "Guadeloupe",
    msgGuam: "Guam",
    msgGuatemala: "Guatemala",
    msgGuernsey: "Guernsey",
    msgGuinea: "Guinea",
    msgGuineaBissau: "Guinea-Bissau",
    msgGuyana: "Guyana",
    msgHaiti: "Haiti",
    msgHeardMcDonald: "Heard Island and McDonald Islands",
    msgHolySee: "Holy See (Vatican City State)",
    msgHonduras: "Honduras",
    msgHongKong: "Hong Kong",
    msgHungary: "Hungary",
    msgIceland: "Iceland",
    msgIndia: "India",
    msgIndonesia: "Indonesia",
    msgIran: "Iran",
    msgIraq: "Iraq",
    msgIreland: "Ireland",
    msgIsrael: "Israel",
    msgIsleMan: "Isle of Man",
    msgItaly: "Italy",
    msgJamaica: "Jamaica",
    msgJapan: "Japan",
    msgJersey: "Jersey",
    msgJordan: "Jordan",
    msgKazakhstan: "Kazakhstan",
    msgKenya: "Kenya",
    msgKiribati: "Kiribati",
    msgKuwait: "Kuwait",
    msgKyrgyzstan: "Kyrgyzstan",
    msgLaos: "Laos",
    msgLatvia: "Latvia",
    msgLebanon: "Lebanon",
    msgLesotho: "Lesotho",
    msgLiberia: "Liberia",
    msgLibyanJamahiriya: "Libyan Arab Jamahiriya",
    msgLiechtenstein: "Liechtenstein",
    msgLithuania: "Lithuania",
    msgLuxembourg: "Luxembourg",
    msgMacao: "Macao",
    msgNorthMacedonia: "North Macedonia",
    msgMadagascar: "Madagascar",
    msgMalawi: "Malawi",
    msgMalaysia: "Malaysia",
    msgMaldives: "Maldives",
    msgMali: "Mali",
    msgMalta: "Malta",
    msgMarshallIslands: "Marshall Islands",
    msgMartinique: "Martinique",
    msgMauritania: "Mauritania",
    msgMauritius: "Mauritius",
    msgMayotte: "Mayotte",
    msgMexico: "Mexico",
    msgMicronesia: "Micronesia, Federated States of",
    msgMoldova: "Moldova",
    msgMonaco: "Monaco",
    msgMongolia: "Mongolia",
    msgMontserrat: "Montserrat",
    msgMontenegro: "Montenegro",
    msgMorocco: "Morocco",
    msgMozambique: "Mozambique",
    msgMyanmar: "Myanmar",
    msgNamibia: "Namibia",
    msgNauru: "Nauru",
    msgNepal: "Nepal",
    msgNetherlands: "Netherlands",
    msgNetherlandsAntilles: "Netherlands Antilles",
    msgNewCaledonia: "New Caledonia",
    msgNewZealand: "New Zealand",
    msgNicaragua: "Nicaragua",
    msgNiger: "Niger",
    msgNigeria: "Nigeria",
    msgNiue: "Niue",
    msgNorfolkIsland: "Norfolk Island",
    msgNorthKorea: "North Korea",
    msgNorthernIreland: "Northern Ireland",
    msgNorthernMariana: "Northern Mariana Islands",
    msgNorway: "Norway",
    msgOman: "Oman",
    msgPakistan: "Pakistan",
    msgPalau: "Palau",
    msgPalestine: "Palestine",
    msgPanama: "Panama",
    msgPapua: "Papua New Guinea",
    msgParaguay: "Paraguay",
    msgPeru: "Peru",
    msgPhilippines: "Philippines",
    msgPitcairn: "Pitcairn",
    msgPoland: "Poland",
    msgPortugal: "Portugal",
    msgPuertoRico: "Puerto Rico",
    msgQatar: "Qatar",
    msgReunion: "Reunion",
    msgRomania: "Romania",
    msgRussianFederation: "Russian Federation",
    msgRwanda: "Rwanda",
    msgSaintHelena: "Saint Helena",
    msgSaintKitts: "Saint Kitts and Nevis",
    msgSaintLucia: "Saint Lucia",
    msgSaintPierre: "Saint Pierre and Miquelon",
    msgSaintVincent: "Saint Vincent and the Grenadines",
    msgSamoa: "Samoa",
    msgSanMarino: "San Marino",
    msgSaoTome: "Sao Tome and Principe",
    msgSaudiArabia: "Saudi Arabia",
    msgScotland: "Scotland",
    msgSenegal: "Senegal",
    msgSerbia: "Serbia",
    msgSeychelles: "Seychelles",
    msgSierraLeone: "Sierra Leone",
    msgSingapore: "Singapore",
    msgSlovakia: "Slovakia",
    msgSlovenia: "Slovenia",
    msgSolomonIslands: "Solomon Islands",
    msgSomalia: "Somalia",
    msgSouthAfrica: "South Africa",
    msgSouthSandwich: "South Georgia and the South Sandwich Islands",
    msgSouthKorea: "South Korea",
    msgSouthSudan: "South Sudan",
    msgSpain: "Spain",
    msgSriLanka: "SriLanka",
    msgSudan: "Sudan",
    msgSuriname: "Suriname",
    msgSvalbardJan: "Svalbard and Jan Mayen",
    msgSwaziland: "Swaziland",
    msgSweden: "Sweden",
    msgSwitzerland: "Switzerland",
    msgSyria: "Syria",
    msgTajikistan: "Tajikistan",
    msgTanzania: "Tanzania",
    msgThailand: "Thailand",
    msgTimorLeste: "Timor-Leste",
    msgTogo: "Togo",
    msgTokelau: "Tokelau",
    msgTonga: "Tonga",
    msgTrinidadTobago: "Trinidad and Tobago",
    msgTunisia: "Tunisia",
    msgTurkey: "Turkey",
    msgTurkmenistan: "Turkmenistan",
    msgTurksCaicos: "Turks and Caicos Islands",
    msgTuvalu: "Tuvalu",
    msgUganda: "Uganda",
    msgUkraine: "Ukraine",
    msgUnitedArabEmirates: "United Arab Emirates",
    msgUnitedKingdom: "United Kingdom",
    msgUnitedStates: "United States",
    msgUnitedStatesMinor: "United States Minor Outlying Islands",
    msgUruguay: "Uruguay",
    msgUzbekistan: "Uzbekistan",
    msgVanuatu: "Vanuatu",
    msgVenezuela: "Venezuela",
    msgVietnam: "Vietnam",
    msgVirginIslandsBritish: "Virgin Islands, British",
    msgVirginIslandsUS: "Virgin Islands, U.S.",
    msgWales: "Wales",
    msgWallisFutuna: "Wallis and Futuna",
    msgWesternSahara: "Western Sahara",
    msgYemen: "Yemen",
    msgZambia: "Zambia",
    msgZimbabwe: "Zimbabwe",
}

export default en;